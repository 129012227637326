
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeroComponent } from './home/hero/hero.component';
import { AboutComponent } from './home/about/about.component';
import { HomeComponent } from './home/home/home.component';
import { TargetComponent } from './home/target/target.component';
import { IndicatorsComponent } from './home/indicators/indicators.component';
import { StatisticsComponent } from './home/statistics/statistics.component';
import { ContactusComponent } from './home/contactus/contactus.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MapComponent } from './map/map.component';
import { TopSchoolsComponent } from './home/top-schools/top-schools.component';
import { HttpClientModule } from '@angular/common/http'
import { FormsModule } from '@angular/forms';
import { PointerEntityComponent } from './pointer-entity/pointer-entity.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { SpinnerComponent } from './spinner/spinner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion'; 
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SchoolCardComponent } from './school-card/school-card.component';
import { GuideComponent } from './home/guide/guide.component';
import { DepartmentCardComponent } from './department-card/department-card.component';
import { OfficeCardComponent } from './office-card/office-card.component';
import { CompareCardComponent } from './compare-card/compare-card.component';
import { ProfileComponent } from './profile/profile.component';
import {CookieService} from 'ngx-cookie-service';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HeroComponent,
    AboutComponent,
    HomeComponent,
    TargetComponent,
    IndicatorsComponent,
    StatisticsComponent,
    ContactusComponent,
    DashboardComponent,
    MapComponent,
    TopSchoolsComponent,
    PointerEntityComponent,
    SpinnerComponent,
    SchoolCardComponent,
    GuideComponent,
    DepartmentCardComponent,
    OfficeCardComponent,
    CompareCardComponent,
    ProfileComponent,
    
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // MatSelectModule,
    HttpClientModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    NgHttpLoaderModule.forRoot(),
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
