import { Component, EnvironmentInjector, Input, OnInit } from '@angular/core';
// import { tooltip } from 'leaflet';
// import { Top10List } from 'src/app/model/Top10List';
//import top10 from '../../../assets/json/top10.json';
//import top10 from '../../../assets/json/top10-new.json';
//import ksatop10 from '../../../assets/json/ksa-top10.json';
import _area from '../../../assets/json/area.json';
import { AreaList, Config } from 'src/app/model/Area';
import { environment } from 'src/environments/environment';
import { SchoolList } from 'src/app/model/SchoolList';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/api.service.service';

@Component({
  selector: 'app-top-schools',
  templateUrl: './top-schools.component.html',
  styleUrls: ['./top-schools.component.css']
})
export class TopSchoolsComponent implements OnInit {
  @Input() defaultYear!: string;
  public show: boolean = false;
  public buttonName: any = 'Show';
  //Top10: any[] = top10;
  Top10: any[] = [];
  Male: any = [];
  Female: any = [];
  KsaMale: any = [];
  KsaFemale: any = [];
  Area: AreaList[]= _area;
  area: string = 'ksa';
  examType: string = 'قدرات';
  examSpecial: string = 'علمي';
  showArea : number;
  showOffcanvas = '';
  showOffDeptcanvas = '';
  showOffOfficecanvas = '';
  dynamicClass = '';
  selectedSchool!: SchoolList;
  queryString = '';
  chart: any;
  _defaultYear : number =0;
  constructor(private httpService: HttpClient,private apiService: ApiService) {  this.showArea = environment.areaNo; }

  ngOnInit(): void {

    // this.httpService.get('../../../assets/json/top10-new.json').subscribe(
    //   data => {
    //     this.Top10 = data as SchoolList[];
    //     // FILL THE ARRAY WITH DATA.
       
    //   },
    //   (err: HttpErrorResponse) => {
    //     console.log (err.message);
    //   }
    // );
    this.apiService
    .getConfig()
    .subscribe((data: Config[]) => {
     this._defaultYear = ( data.filter(m => m.IsDefault == true)?.at(0)?.ResultYear)!;
     this.clickExamKsa(this.area);
    });


   
  }

  toggle() {
    this.show = !this.show;
    if (this.show)
      this.buttonName = "Hide";
    else
      this.buttonName = "Show";
  }

  clickArea(area: string) {
    this.area = area;
    this.clickExam(this.examType, this.examSpecial);
  }

  clickAreaSelect(event: any){
    this.area = event.target.value;
    this.clickExam(this.examType, this.examSpecial);
  }

  clickExamKsa(area: string) {
    this.showOffcanvas = '';
    this.area = area;

    // this.Male = ksatop10.filter(x =>
    //   x.ExamType == this.examType &&
    //   x.ExamSpecial == this.examSpecial &&
    //   x.Gender == 'M');

    // this.Female = ksatop10.filter(x => 
    //   x.ExamType == this.examType &&
    //   x.ExamSpecial == this.examSpecial &&
    //   x.Gender == 'F');
    this.apiService.getByParametersTopN('School',this._defaultYear,'M', this.examType, 'كلية', this.examSpecial, 10, this.area=='ksa'?'all': this.area ).subscribe(
      (res: SchoolList[]) => {
        this.Male = res;
      }
    )
    this.apiService.getByParametersTopN('School',this._defaultYear,'F', this.examType, 'كلية', this.examSpecial, 10, this.area=='ksa'?'all': this.area ).subscribe(
      (res: SchoolList[]) => {
        this.Female = res;
      }
    )
  }

  clickExam(examType: string, examSpecial: string) {
    this.showOffcanvas = '';
    this.examSpecial = examSpecial;
    this.examType = examType;

   
    this.apiService.getByParametersTopN('School',this._defaultYear,'M', examType, 'كلية', examSpecial, 10, this.area=='ksa'?'all': this.area ).subscribe(
      (res: SchoolList[]) => {
        this.Male = res;
      }
    )
    this.apiService.getByParametersTopN('School',this._defaultYear,'F', examType, 'كلية', examSpecial, 10, this.area=='ksa'?'all': this.area ).subscribe(
      (res: SchoolList[]) => {
        this.Female = res;
      }
    )

    // if(this.area != 'ksa'){
    //   this.Male = this.Top10.filter(x => x.Area == this.area &&
    //     x.ExamType == examType &&
    //     x.ExamSpecial == examSpecial &&
    //     x.Gender == 'M');
  
    //   this.Female = this.Top10.filter(x => x.Area == this.area &&
    //     x.ExamType == examType &&
    //     x.ExamSpecial == examSpecial &&
    //     x.Gender == 'F');
    // }else{
    //   this.Male = ksatop10.filter(x =>
    //     x.ExamType == this.examType &&
    //     x.ExamSpecial == this.examSpecial &&
    //     x.Gender == 'M');
  
    //   this.Female = ksatop10.filter(x => 
    //     x.ExamType == this.examType &&
    //     x.ExamSpecial == this.examSpecial &&
    //     x.Gender == 'F');
    // }
    
  }

  onClickSchoolMarker(item: SchoolList) {

    if (this.chart) {
      this.chart.destroy();
    }
    this.showOffcanvas = 'show';

    this.selectedSchool = item;

    if (Number(item.Average) >= 85) {
      this.dynamicClass = 'bg-indicator-Darkgreen';
    } else if (Number(item.Average) >= 75 && Number(item.Average) < 85) {
      this.dynamicClass = 'bg-indicator-Midgreen';
    } else if (Number(item.Average) >= 65 && Number(item.Average) < 75) {
      this.dynamicClass = 'bg-indicator-Lightgreen';
    } else if (Number(item.Average) >= 55 && Number(item.Average) < 65) {
      this.dynamicClass = 'bg-indicator-yellow';
    } else if (Number(item.Average) >= 45 && Number(item.Average) < 55) {
      this.dynamicClass = 'bg-indicator-orange';
    } else if (Number(item.Average) >= 0 && Number(item.Average) < 45) {
      this.dynamicClass = 'bg-indicator-red';
    }
    this.queryString = `شاهد ترتيب مدرسة ( ${item.Name} /${item.Department} ) في مؤشر ترتيب تحصيلي على مستوى-مدارس المملكة ( ${item.KsaRank} )-مدارس ادارة التعليم ( ${item.DeptRank} )-مدارس المكتب ( ${item.OffficeRak} )ومتوسط الأداء الكلي ( ${item.Average} ) لعام ${item.Year}`;

    if (item.Year == '1443' || item.Year == '1444') {
      if(item.Avg41 !=='NULL' && item.Avg42 !== 'NULL'){
        if(!Number.isNaN(item.Avg41) && !Number.isNaN(item.Avg42)){
          if (item.Avg41 != undefined && item.Avg42 != undefined && item.Avg41 != '0' && item.Avg42 != '0') {
            this.addTrenChart(item);
          }
        }
      }
    }
  }


  getColor(item: string) {
    let classColor = 'bg-indicator-Darkgreen';
    if (Number(item) >= 85) {
      classColor = 'bg-indicator-Darkgreen';
    } else if (Number(item) >= 75 && Number(item) < 85) {
      classColor = 'bg-indicator-Midgreen';
    } else if (Number(item) >= 65 && Number(item) < 75) {
      classColor = 'bg-indicator-Lightgreen';
    } else if (Number(item) >= 55 && Number(item) < 65) {
      classColor = 'bg-indicator-yellow';
    } else if (Number(item) >= 45 && Number(item) < 55) {
      classColor = 'bg-indicator-orange';
    } else if (Number(item) >= 0 && Number(item) < 45) {
      classColor = 'bg-indicator-red';
    }

    return classColor;
  }

  addTrenChart(item: SchoolList) {
    var options = {
      series: [
        {
          name: item.Name,
          data: [Number(item.Avg41).toFixed(1), Number(item.Avg42).toFixed(1), Number(item.Average).toFixed(1)]
        }
      ],
      chart: {
        height: 150,
        type: 'line',
        dropShadow: {
          enabled: false,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: ['#4C3D8F', '#4C3D8F'],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth'
      },
      grid: {
        show: false,
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: [Number(item.Year)-2, Number(item.Year) -1 , item.Year],
      },
      yaxis: {
        show: false,
        title: {
          text: ''
        },
        min: 5,
        max: 100
      }
    };

    this.chart = new ApexCharts(document.querySelector("#chart"), options);
    this.chart.render();
  }

}
