import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchoolList } from '../model/SchoolList';
import { ApiService } from '../api.service.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  mno = '';
  year = '';
  gender = '';
  type = '';
  special = '';
  selectedSchool!: SchoolList;
  AllSchoolMarks: SchoolList[] = [];
  AllSchoolMarksMain: SchoolList[] = [];
  AllSchoolMarksSub: SchoolList[] = [];
  url: SafeUrl | undefined;
  constructor(
    private actRoute: ActivatedRoute,
    private apiService: ApiService,
    private sanitizer: DomSanitizer
  ) {
    this.actRoute.params.subscribe((params) => {
      this.mno = params['id'] == null ? '' : params['id'];
      this.year = params['year'] == null ? '1443' : params['year'];
      this.gender = params['gender'] == null ? 'M' : params['gender'];
      this.type = params['type'] == null ? 'تحصيلي' : params['type'];
      this.special = params['special'] == null ? 'علمي' : params['special'];

      this.getSchoolInfo();
      this.getSchoolMarkByYear();
    });
  }

  getSchoolInfo() {
    this.apiService
      .getSchoolByMno(
        this.mno,
        'School',
        Number(this.year),
        this.gender,
        this.type,
        'كلية',
        this.special
      )

      .subscribe((data) => {
        this.selectedSchool = data.length > 0 ? data[0] : [];
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://maps.google.com/maps?q=${this.selectedSchool?.Lang},${this.selectedSchool?.Lut}&hl=es;z=14&output=embed`
        );
      });
  }

  getSchoolMarkByYear() {
    this.apiService
      .getByYear('School', Number(this.year), this.mno)
      .subscribe((data) => {
        this.AllSchoolMarks = data;
        this.AllSchoolMarksMain = this.AllSchoolMarks.filter((m:any) => m.MarkType =='كلية');
        this.AllSchoolMarksSub = this.AllSchoolMarks.filter((m:any) => m.MarkType !='كلية');
      });
  }

  getLevelColor(average: string): string {
    if (Number(average) >= 85) {
      return 'Darkgreen';
    } else if (Number(average) >= 75 && Number(average) < 85) {
      return 'Midgreen';
    } else if (Number(average) >= 65 && Number(average) < 75) {
      return 'Lightgreen';
    } else if (Number(average) >= 55 && Number(average) < 65) {
      return 'yellow';
    } else if (Number(average) >= 45 && Number(average) < 55) {
      return 'orange';
    } else if (Number(average) >= 0 && Number(average) < 45) {
      return 'red';
    }

    return '';
  }

  getColor(item: string) {
    let classColor = 'bg-indicator-Darkgreen';
    if (Number(item) >= 85) {
      classColor = 'bg-indicator-Darkgreen';
    } else if (Number(item) >= 75 && Number(item) < 85) {
      classColor = 'bg-indicator-Midgreen';
    } else if (Number(item) >= 65 && Number(item) < 75) {
      classColor = 'bg-indicator-Lightgreen';
    } else if (Number(item) >= 55 && Number(item) < 65) {
      classColor = 'bg-indicator-yellow';
    } else if (Number(item) >= 45 && Number(item) < 55) {
      classColor = 'bg-indicator-orange';
    } else if (Number(item) >= 0 && Number(item) < 45) {
      classColor = 'bg-indicator-red';
    }

    return classColor;
  }
  getInnerMarks(markType: string, markSpecial: string){
    return this.AllSchoolMarksSub.filter(m=>m.ExamType == markType && m.ExamSpecial == markSpecial);
  }

  ngOnInit(): void {}
}
