import { Component, OnInit, AfterViewInit } from '@angular/core';
// import { marker } from 'leaflet';
// import * as L from 'leaflet';
// // import { map, filter, scan, ignoreElements, delay, mapTo } from 'rxjs/operators';
// import 'leaflet-boundary-canvas';
// import ksa from '../../assets/json/ksa.json';
// import ado from '../../assets/json/ado.json';
// // import schools from '../../assets/json/data.json';
// import { SchoolList } from '../model/SchoolList';
// import riyadh from '../../assets/json/area-position/riyadh.json';
// import baha from '../../assets/json/area-position/baha.json';
// import makkah from '../../assets/json/area-position/makkah.json';
// import tabuk from '../../assets/json/area-position/tabuk.json';
// import sharkiah from '../../assets/json/area-position/sharkiah.json';
// import asir from '../../assets/json/area-position/asir.json';
// import hail from '../../assets/json/area-position/hail.json';
// import jawf from '../../assets/json/area-position/jawf.json';
// import jazan from '../../assets/json/area-position/jazan.json';
// import medinah from '../../assets/json/area-position/medinah.json';
// import najran from '../../assets/json/area-position/najran.json';
// import qassim from '../../assets/json/area-position/qassim.json';
// import northborder from '../../assets/json/area-position/northborder.json';
// import _area from '../../assets/json/area.json'
// import position from '../../assets/json/areaposition.json';
// import deptData from '../../assets/json/department.json';
// import officeData from '../../assets/json/office.json';
// import { DepartmentList, OfficeList } from '../model/DepartmentList';
// import { fromEvent, of, pipe } from 'rxjs';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})

export class MapComponent  {

  // private map: any;
  // private geom: any = ksa;
  // SchoolData: SchoolList[] = [];
  // pointerDepartment: string[] = [];
  // pointerOffice: string[] = [];
  // Department: any;
  // Office: any;
  // ksaCheck = false;
  // schoolCheck = true;
  // officeCheck = false;
  // departmentCheck = false;
  // top10Check = false;
  // DepartmentNoRepeat: string[] = [];
  // searchShoolResult: SchoolList[] = [];
  // year: string = "1443";
  // examTypeFull: string = 'تحصيلي,علمي';
  // examType: string = 'تحصيلي';
  // examSpecial: string = 'علمي';
  // scoreLevel: string = '0';
  // examGender: string = 'M';
  // area: string = '0';
  // department: string = '0';
  // office: string = '0';
  // authority: string = '0';
  // marker: any = [];
  // markerDept: any = [];
  // markerOffice: any = [];
  // cities: any = [];
  // showOffcanvas = '';
  // showOffDeptcanvas = '';
  // showOffOfficecanvas = '';
  // dynamicClass = '';
  // selectedSchool!: SchoolList;
  // selectedDepartment!: DepartmentList;
  // selectedOffice!: OfficeList;
  // selectAreaGeom: any;
  // txtSearch = '';
  // queryString = '';
  // chart: any;
  // // #region marker icons  

  // darkGreenIcon = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_Darkgreen.svg',
  //   iconSize: [25, 25], // size of the icon
  // });
  // midGreennIcon = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_Midgreen.svg',
  //   iconSize: [25, 25], // size of the icon
  // });
  // lightGreenIcon = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_Lightgreen.svg',
  //   iconSize: [25, 25], // size of the icon
  // });
  // yellowIcon = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_yellow.svg',
  //   iconSize: [25, 25], // size of the icon
  // });
  // orangeIcon = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_orange.svg',
  //   iconSize: [25, 25], // size of the icon
  // });
  // redIcon = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_red.svg',
  //   iconSize: [25, 25], // size of the icon
  // });
  // darkGreenIconSquare = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_Darkgreen_square.svg',
  //   iconSize: [50, 50], // size of the icon
  // });
  // midGreennIconSquare = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_Midgreen_square.svg',
  //   iconSize: [50, 50], // size of the icon
  // });
  // lightGreenIconSquare = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_Lightgreen_square.svg',
  //   iconSize: [50, 50], // size of the icon
  // });
  // yellowIconSquare = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_yellow_square.svg',
  //   iconSize: [50, 50], // size of the icon
  // });
  // orangeIconSquare = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_orange_square.svg',
  //   iconSize: [50, 50], // size of the icon
  // });
  // redIconSquare = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_red_square.svg',
  //   iconSize: [50, 50], // size of the icon
  // });
  // darkGreenIconTriangle = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_Darkgreen_triangle.svg',
  //   iconSize: [30, 30], // size of the icon
  // });
  // midGreennIconTriangle = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_Midgreen_triangle.svg',
  //   iconSize: [30, 30], // size of the icon
  // });
  // lightGreenIconTriangle = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_Lightgreen_triangle.svg',
  //   iconSize: [30, 30], // size of the icon
  // });
  // yellowIconTriangle = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_yellow_triangle.svg',
  //   iconSize: [30, 30], // size of the icon
  // });
  // orangeIconTriangle = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_orange_triangle.svg',
  //   iconSize: [30, 30], // size of the icon
  // });
  // redIconTriangle = L.icon({
  //   iconUrl: '../../assets/leaflet/marker_red_triangle.svg',
  //   iconSize: [30, 30], // size of the icon
  // });

  // // #endregion  



  constructor() {
    //this.SchoolData = schools as SchoolList[];
    //this.SchoolData.filter(m => m.Area)
  }

  // private initMap(): void {

  //   var southWest = L.latLng(2.77353737225232, 29.55525603227491),
  //     northEast = L.latLng(45.98590990829359, 66.55721003864447),
  //     bounds = L.latLngBounds(southWest, northEast);
  //   var mbUrl = 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png';
  //   this.map = L.map('map', {
  //     maxBounds: bounds,
  //   }).setView([24.774265, 46.738586], 6);

  //   var osm = L.TileLayer.boundaryCanvas(mbUrl, {
  //     boundary: this.geom,
  //     attribution: 'ETEC',
  //     trackAttribution: true,
  //     maxZoom: 14,
  //     minZoom: 6

  //   }).addTo(this.map);

  //   this.cities = L.layerGroup().addTo(this.map);
  //   // control Zoom Position
  //   this.map.zoomControl.remove();
  //   L.control.zoom({
  //     position: 'topright'
  //   }).addTo(this.map);
  // }

  // ngAfterViewInit(): void {
  //   this.initMap();
  //   this.applySearch();
  // }

  // //Cusotm
  // onYearChange(event: any) {
  //   this.year = event.target.value;
  //   this.area = '0';
  //   this.department = '0';
  //   this.office = '0';
  // }

  // onExamTypeChange(event: any) {
  //   let typeSpecial: string = event.target.value;
  //   this.examType = typeSpecial.split(',')[0];
  //   this.examSpecial = typeSpecial.split(',')[1];
  // }

  // onScoreLevelChange(event: any) {
  //   this.scoreLevel = event.target.value;
  // }

  // onGenderChange(event: any) {
  //   this.examGender = event.target.value;
  // }

  // onAreaChange(event: any) {
  //   this.area = event.target.value;

  //   this.Department = ado.filter(f => f.Area == this.area && f.year == this.year);
  //   this.DepartmentNoRepeat = [];
  //   this.Department.forEach((element: any) => {
  //     if (this.DepartmentNoRepeat.indexOf(element.Department) == -1) {
  //       this.DepartmentNoRepeat.push(element.Department);
  //     }
  //   });
  //   this.department = '0';
  //   this.office = '0';
  // }

  // onDepartmentChange(event: any) {
  //   this.department = event.target.value;
  //   this.Office = [];
  //   this.Office = ado.filter(f => f.Area == this.area && f.Department == this.department && f.year == this.year);
  //   this.office = '0';
  // }

  // onOfficeChange(event: any) {
  //   this.office = event.target.value;
  // }

  // onAuthorityChange(event: any) {
  //   this.authority = event.target.value;
  // }

  // onTop10Change() {
  //   console.log(this.top10Check);
  //   if (this.top10Check) {
  //     this.department = '0';
  //     this.office = '0';
  //     this.authority = '0';
  //   }
  // }
  // clearSearchForm() {
  //   this.txtSearch = '';
  //   this.examGender = 'M';
  //   this.examTypeFull = 'تحصيلي,علمي';
  //   this.year = '1443'
  //   this.area = '0';
  //   this.department = '0';
  //   this.office = '0';
  //   this.authority = '0';
  // }

  // clearSearch() {
  //   this.cities = [];
  //   //Clear offcanvas
  //   this.showOffDeptcanvas = '';
  //   this.showOffcanvas = '';
  //   this.showOffOfficecanvas = '';
  //   //Clear schools, departments and offices from map
  //   this.marker.forEach((element: any) => {
  //     this.map.removeLayer(element);
  //   });

  //   this.markerDept.forEach((element: any) => {
  //     this.map.removeLayer(element);
  //   });

  //   this.markerOffice.forEach((element: any) => {
  //     this.map.removeLayer(element);
  //   });

  //   //Clear  schools, departments and offices array
  //   this.marker = [];
  //   this.markerDept = [];
  //   this.markerOffice = [];

  //   //Clear borders 
  //   if (this.selectAreaGeom) {
  //     this.map.removeLayer(this.selectAreaGeom);
  //   }
  // }

  // addSchoolMarker(searchResult: SchoolList[]) {
  //   let dynamicIcon: any;

  //   searchResult.forEach((element, i) => {
  //     if ((element.Lang != undefined) && element.Lut != undefined) {
  //       //Check color level
  //       if (Number(element.Average) >= 85) {
  //         dynamicIcon = this.darkGreenIcon;
  //       } else if (Number(element.Average) >= 75 && Number(element.Average) < 85) {
  //         dynamicIcon = this.midGreennIcon;
  //       } else if (Number(element.Average) >= 65 && Number(element.Average) < 75) {
  //         dynamicIcon = this.lightGreenIcon;
  //       } else if (Number(element.Average) >= 55 && Number(element.Average) < 65) {
  //         dynamicIcon = this.yellowIcon;
  //       } else if (Number(element.Average) >= 45 && Number(element.Average) < 55) {
  //         dynamicIcon = this.orangeIcon;
  //       } else if (Number(element.Average) >= 0 && Number(element.Average) < 45) {
  //         dynamicIcon = this.redIcon;
  //       }

  //       //Add marker
  //       const schoolItem = element;
  //       try {
  //         this.marker[i] = L.marker([Number(element.Lang), Number(element.Lut)], {
  //           icon: dynamicIcon
  //         })
  //           .on('click', (e) => {
  //             this.onClickSchoolMarker(schoolItem);
  //           })
  //           .addTo(this.map);
  //       } catch { }
  //     }
  //   });
  // }

  // addDepartmentMarker(searchResult: DepartmentList[]) {
  //   let dynamicIcon: any;

  //   searchResult.forEach((element, i) => {
  //     if ((element.Lang != undefined) && element.Lut != undefined) {
  //       //Check color level
  //       if (Number(element.Average) >= 85) {
  //         dynamicIcon = this.darkGreenIconSquare;
  //       } else if (Number(element.Average) >= 75 && Number(element.Average) < 85) {
  //         dynamicIcon = this.midGreennIconSquare;
  //       } else if (Number(element.Average) >= 65 && Number(element.Average) < 75) {
  //         dynamicIcon = this.lightGreenIconSquare;
  //       } else if (Number(element.Average) >= 55 && Number(element.Average) < 65) {
  //         dynamicIcon = this.yellowIconSquare;
  //       } else if (Number(element.Average) >= 45 && Number(element.Average) < 55) {
  //         dynamicIcon = this.orangeIconSquare;
  //       } else if (Number(element.Average) >= 0 && Number(element.Average) < 45) {
  //         dynamicIcon = this.redIconSquare;
  //       }

  //       //Add marker
  //       const departmentItem = element;
  //       try {
  //         this.markerDept[i] = L.marker([Number(element.Lang), Number(element.Lut)], {
  //           icon: dynamicIcon
  //         })
  //           .on('click', (e) => {
  //             this.onClickDepartmentMarker(departmentItem);
  //           })
  //           .addTo(this.map);
  //       } catch { }
  //     }
  //   });
  // }

  // addOfficeMarker(searchResult: OfficeList[]) {
  //   let dynamicIcon: any;

  //   searchResult.forEach((element, i) => {
  //     if ((element.Lang != undefined) && element.Lut != undefined) {
  //       //Check color level
  //       if (Number(element.Average) >= 85) {
  //         dynamicIcon = this.darkGreenIconTriangle;
  //       } else if (Number(element.Average) >= 75 && Number(element.Average) < 85) {
  //         dynamicIcon = this.midGreennIconTriangle;
  //       } else if (Number(element.Average) >= 65 && Number(element.Average) < 75) {
  //         dynamicIcon = this.lightGreenIconTriangle;
  //       } else if (Number(element.Average) >= 55 && Number(element.Average) < 65) {
  //         dynamicIcon = this.yellowIconTriangle;
  //       } else if (Number(element.Average) >= 45 && Number(element.Average) < 55) {
  //         dynamicIcon = this.orangeIconTriangle;
  //       } else if (Number(element.Average) >= 0 && Number(element.Average) < 45) {
  //         dynamicIcon = this.redIconTriangle;
  //       }

  //       //Add marker
  //       const officeItem = element;
  //       try {
  //         this.markerOffice[i] = L.marker([Number(element.Lang), Number(element.Lut)], {
  //           icon: dynamicIcon
  //         })
  //           .on('click', (e) => {
  //             this.onClickOfficeMarker(officeItem);
  //           })
  //           .addTo(this.map);
  //       } catch { }
  //     }
  //   });
  // }

  // applySearch() {

  //   //Check if all checkbox is unchecked : then but schools
  //   if (!this.schoolCheck && !this.departmentCheck && !this.officeCheck) {
  //     this.schoolCheck = !this.schoolCheck;
  //   }
  //   //Clear the search properties
  //   this.clearSearch();
  //   //Prepare search 
  //   let typeSpecial: string = this.examTypeFull;
  //   this.examType = typeSpecial.split(',')[0];
  //   this.examSpecial = typeSpecial.split(',')[1];
  //   //School
  //   this.searchShoolResult = this.SchoolData.filter((s: SchoolList) => s.Year == this.year &&
  //     (s.Name.indexOf(this.txtSearch) != -1 || s.MNO.indexOf(this.txtSearch) != -1) &&
  //     s.ExamType == this.examType &&
  //     s.ExamSpecial == this.examSpecial &&
  //     s.Gender == this.examGender &&
  //     (s.Area == this.area || this.area == '0') &&
  //     (s.Department == this.department || this.department == '0') &&
  //     (s.Office == this.office || this.office == '0') &&
  //     (s.Authority == this.authority || this.authority == '0'));

  //   if (this.top10Check) {
  //     this.searchShoolResult = this.searchShoolResult.slice(0, 10);
  //   }

  //   if (this.scoreLevel != '0') {
  //     if (this.scoreLevel == 'A') {
  //       this.searchShoolResult = this.searchShoolResult.filter(m => Number(m.Average) >= 85);
  //     } else if (this.scoreLevel == 'B') {
  //       this.searchShoolResult = this.searchShoolResult.filter(m => Number(m.Average) >= 75 && Number(m.Average) < 85);
  //     } else if (this.scoreLevel == 'C') {
  //       this.searchShoolResult = this.searchShoolResult.filter(m => Number(m.Average) >= 65 && Number(m.Average) < 75);
  //     } else if (this.scoreLevel == 'D') {
  //       this.searchShoolResult = this.searchShoolResult.filter(m => Number(m.Average) >= 55 && Number(m.Average) < 65);
  //     } else if (this.scoreLevel == 'E') {
  //       this.searchShoolResult = this.searchShoolResult.filter(m => Number(m.Average) >= 45 && Number(m.Average) < 55);
  //     } else {
  //       this.searchShoolResult = this.searchShoolResult.filter(m => Number(m.Average) < 45);
  //     }
  //   }
  //   if (this.schoolCheck) {
  //     //Add marker to map  
  //     this.addSchoolMarker(this.searchShoolResult);
  //   }
  //   //Department
  //   if (this.departmentCheck) {
  //     let department: DepartmentList[] = deptData.filter(s => s.Year == this.year &&
  //       s.ExamType == this.examType &&
  //       s.ExamSpecial == this.examSpecial &&
  //       s.ExamGender == this.examGender &&
  //       (s.Area == this.area || this.area == '0') &&
  //       (s.EducationDepartment == this.department || this.department == '0'));

  //     //Add marker to map  
  //     this.addDepartmentMarker(department);
  //   }

  //   //Office
  //   if (this.officeCheck) {
  //     let office: OfficeList[] = officeData.filter(s => s.Year == this.year &&
  //       s.ExamType == this.examType &&
  //       s.ExamSpecial == this.examSpecial &&
  //       s.ExamGender == this.examGender &&
  //       (s.Area == this.area || this.area == '0') &&
  //       (s.EducationDepartment == this.department || this.department == '0') &&
  //       (s.OfficeName == this.office || this.office == '0'));

  //     //Add marker to map  
  //     this.addOfficeMarker(office);
  //   }

  //   //Focus on area
  //   if (this.area != '0') {
  //     //area color 
  //     let color = '#524293';

  //     let areaAvg = this.searchShoolResult[0]?.AreaAvg;
  //     if (Number(areaAvg) >= 85) {
  //       color = '#1F7D18';
  //     } else if (Number(areaAvg) >= 75 && Number(areaAvg) < 85) {
  //       color = '#6FC243';
  //     } else if (Number(areaAvg) >= 65 && Number(areaAvg) < 75) {
  //       color = '#B8D64C';
  //     } else if (Number(areaAvg) >= 55 && Number(areaAvg) < 65) {
  //       color = '#DFCB6D';
  //     } else if (Number(areaAvg) >= 45 && Number(areaAvg) < 55) {
  //       color = '#F89F70';
  //     } else if (Number(areaAvg) >= 0 && Number(areaAvg) < 45) {
  //       color = '#E25152';
  //     }

  //     if (this.top10Check) {
  //       color = '#524293';
  //     }


  //     //
  //     var pos = position.filter(m => m.City == this.area)[0];
  //     let lang = pos?.lang;
  //     let lut = pos?.lut;
  //     this.map.setView([lang, lut]);

  //     let geo: any = _area.filter(m => m.SearchName == this.area)[0]?.Geom;
  //     let geom: any;

  //     if (geo == 'riyadh') { geom = riyadh }
  //     else if (geo == 'baha') { geom = baha }
  //     else if (geo == 'makkah') { geom = makkah }
  //     else if (geo == 'tabuk') { geom = tabuk }
  //     else if (geo == 'sharkiah') { geom = sharkiah }
  //     else if (geo == 'asir') { geom = asir }
  //     else if (geo == 'hail') { geom = hail }
  //     else if (geo == 'jawf') { geom = jawf }
  //     else if (geo == 'jazan') { geom = jazan }
  //     else if (geo == 'medinah') { geom = medinah }
  //     else if (geo == 'najran') { geom = najran }
  //     else if (geo == 'northborder') { geom = northborder }
  //     else if (geo == 'qassim') { geom = qassim }

  //     this.selectAreaGeom = L.geoJSON(geom, { style: this.polystyle(color) }).addTo(this.map);
  //     let bounds = this.selectAreaGeom.getBounds();
  //     // Fit the map to the polygon bounds
  //     this.map.fitBounds(bounds)
  //     // Or center on the polygon
  //     var center = bounds.getCenter();
  //     this.map.panTo(center);
  //   } else {
  //     this.map.setView([24.774265, 46.738586]);
  //     this.selectAreaGeom = L.geoJSON(this.geom, { style: this.polystyleKsa }).addTo(this.map);
  //   }
  // }

  // polystyle(color: string) {
  //   return {
  //     fillColor: color,
  //     weight: 2,
  //     opacity: 1,
  //     color: color,  //Outline color
  //     fillOpacity: 0.09
  //   };
  // }

  // polystyleKsa() {
  //   return {
  //     fillColor: '#524293',
  //     weight: 2,
  //     opacity: 0.0,
  //     color: '#524293',  //Outline color
  //     fillOpacity: 0
  //   };
  // }

  // onClickSchoolMarker(item: SchoolList) {

  //   if (this.chart) {
  //     this.chart.destroy();
  //   }
  //   this.showOffcanvas = 'show';
  //   this.showOffDeptcanvas = '';
  //   this.showOffOfficecanvas = '';
  //   this.selectedSchool = item;

  //   if (Number(item.Average) >= 85) {
  //     this.dynamicClass = 'bg-indicator-Darkgreen';
  //   } else if (Number(item.Average) >= 75 && Number(item.Average) < 85) {
  //     this.dynamicClass = 'bg-indicator-Midgreen';
  //   } else if (Number(item.Average) >= 65 && Number(item.Average) < 75) {
  //     this.dynamicClass = 'bg-indicator-Lightgreen';
  //   } else if (Number(item.Average) >= 55 && Number(item.Average) < 65) {
  //     this.dynamicClass = 'bg-indicator-yellow';
  //   } else if (Number(item.Average) >= 45 && Number(item.Average) < 55) {
  //     this.dynamicClass = 'bg-indicator-orange';
  //   } else if (Number(item.Average) >= 0 && Number(item.Average) < 45) {
  //     this.dynamicClass = 'bg-indicator-red';
  //   }
  //   this.queryString = `شاهد ترتيب مدرسة ( ${item.Name} /${item.Department} ) في مؤشر ترتيب تحصيلي على مستوى-مدارس المملكة ( ${item.KsaRank} )-مدارس ادارة التعليم ( ${item.DeptRank} )-مدارس المكتب ( ${item.OffficeRak} )ومتوسط الأداء الكلي ( ${item.Average} ) لعام ${this.year}`;

  //   if (this.year == '1443') {
  //     if (item.Avg41 != '0' && item.Avg42 != '0') {
  //       this.addTrenChart(item);
  //     }
  //   }
  // }

  // addTrenChart(item: SchoolList) {
  //   var options = {
  //     series: [
  //       {
  //         name: item.Name,
  //         data: [Number(item.Avg41).toFixed(1), Number(item.Avg42).toFixed(1), Number(item.Average).toFixed(1)]
  //       }
  //     ],
  //     chart: {
  //       height: 150,
  //       type: 'line',
  //       dropShadow: {
  //         enabled: false,
  //         color: '#000',
  //         top: 18,
  //         left: 7,
  //         blur: 10,
  //         opacity: 0.2
  //       },
  //       toolbar: {
  //         show: false
  //       }
  //     },
  //     colors: ['#4C3D8F', '#4C3D8F'],
  //     dataLabels: {
  //       enabled: true,
  //     },
  //     stroke: {
  //       curve: 'smooth'
  //     },
  //     grid: {
  //       show: false,
  //       borderColor: '#e7e7e7',
  //       row: {
  //         colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
  //         opacity: 0.5
  //       },
  //     },
  //     markers: {
  //       size: 1
  //     },
  //     xaxis: {
  //       categories: ['1441', '1442', '1443'],
  //     },
  //     yaxis: {
  //       show: false,
  //       title: {
  //         text: ''
  //       },
  //       min: 5,
  //       max: 100
  //     }
  //   };

  //   this.chart = new ApexCharts(document.querySelector("#chart"), options);
  //   this.chart.render();
  // }

  // onClickDepartmentMarker(item: DepartmentList) {
  //   this.showOffDeptcanvas = 'show';
  //   this.showOffcanvas = '';
  //   this.showOffOfficecanvas = '';
  //   this.selectedDepartment = item;

  //   if (Number(item.Average) >= 85) {
  //     this.dynamicClass = 'bg-indicator-Darkgreen';
  //   } else if (Number(item.Average) >= 75 && Number(item.Average) < 85) {
  //     this.dynamicClass = 'bg-indicator-Midgreen';
  //   } else if (Number(item.Average) >= 65 && Number(item.Average) < 75) {
  //     this.dynamicClass = 'bg-indicator-Lightgreen';
  //   } else if (Number(item.Average) >= 55 && Number(item.Average) < 65) {
  //     this.dynamicClass = 'bg-indicator-yellow';
  //   } else if (Number(item.Average) >= 45 && Number(item.Average) < 55) {
  //     this.dynamicClass = 'bg-indicator-orange';
  //   } else if (Number(item.Average) >= 0 && Number(item.Average) < 45) {
  //     this.dynamicClass = 'bg-indicator-red';
  //   }
  // }

  // onClickOfficeMarker(item: OfficeList) {
  //   this.showOffDeptcanvas = '';
  //   this.showOffcanvas = '';
  //   this.showOffOfficecanvas = 'show';

  //   this.selectedOffice = item;

  //   if (Number(item.Average) >= 85) {
  //     this.dynamicClass = 'bg-indicator-Darkgreen';
  //   } else if (Number(item.Average) >= 75 && Number(item.Average) < 85) {
  //     this.dynamicClass = 'bg-indicator-Midgreen';
  //   } else if (Number(item.Average) >= 65 && Number(item.Average) < 75) {
  //     this.dynamicClass = 'bg-indicator-Lightgreen';
  //   } else if (Number(item.Average) >= 55 && Number(item.Average) < 65) {
  //     this.dynamicClass = 'bg-indicator-yellow';
  //   } else if (Number(item.Average) >= 45 && Number(item.Average) < 55) {
  //     this.dynamicClass = 'bg-indicator-orange';
  //   } else if (Number(item.Average) >= 0 && Number(item.Average) < 45) {
  //     this.dynamicClass = 'bg-indicator-red';
  //   }
  // }
}
