import { Component } from '@angular/core';
import { SpinnerComponent } from './spinner/spinner.component';
import { Spinkit } from 'ng-http-loader';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Tarteeb';
  public spinnerComponent = SpinnerComponent;
  
}
