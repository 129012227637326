<!-- Offcanvas -->
<div class="offcanvas offcanvas-bottom offcanvas-compare {{minimizeLocal}} {{showCompareLocal}}" id="offcanvasBottom"
    data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" style="visibility: visible;">
    <div class="offcanvas-header bg-primary border-bottom">
        <h4 class="offcanvas-title text-white fs-lg">قارن بين ({{comparedSchoolLocal.length}}) مدارس</h4>
        <div class="d-flex justify-content-center align-items-center ">
            <div>
                <div class="btn-group me-2" role="group" aria-label="up settings">
                    <button class="btn btn-outline-warning btn-icon border-0" type="button"
                        (click)="minimizeLocal = minimizeLocal==''?'minimize':''">
                        <i class="bx bxs-chevrons-up" style="color: #F6F0FF !important;"
                            *ngIf="minimizeLocal=='minimize'"></i>
                        <i class="bx bxs-chevrons-down" style="color: #F6F0FF !important;"
                            *ngIf="minimizeLocal==''"></i></button>
                </div>
            </div>
            <button type="button" class="btn btn-outline-warning btn-icon text-white border-0 adv-search-close"
                data-bs-dismiss="offcanvas" aria-label="Close" (click)="hideChart()"></button>

        </div>

    </div>
    <div class="offcanvas-body px-2 py-3">
        <div class="d-flex flex-xl-row flex-column" *ngIf="showComparedSchoolRowLocal">
            <!-- School Row -->
            <div class="row row-cols-xl-4 row-cols-2 align-items-center g-3 g-xl-3 w-100">
                <!-- school -->
                <div class="col mb-xl-0 mb-0" *ngFor="let sc of comparedSchoolLocal">
                    <div class="card card-body bg-light border-0 position-relative">
                        <div class="position-absolute top-0 start-100 translate-middle">
                            <button class="btn btn-danger btn-icon rounded-circle" style="width: 1.5rem;height: 1.5rem;"
                                type="button" (click)="addToCmpare(sc,0)">
                                <i class="bx bx-x"></i>
                            </button>
                        </div>
                        <p class="mb-0 text-primary">{{sc.Name}}</p>
                    </div>
                </div>

            </div>
            <!-- btns row -->
            <div class="row g-0 align-items-center w-xl-25 w-100">
                <div class="col">
                    <div class="card card-body py-2 bg-transparent d-flex flex-row border-0">
                        <button type="button" class="btn btn-primary fs-lg w-100 me-2" (click)="showChart()"
                        *ngIf="comparedSchoolLocal.length > 1" >مقارنة
                            المدارس</button>
                        <button type="button" class="btn btn-outline-primary fs-lg w-100" (click)="hideChart()">إعادة
                            تعيين</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Start Chart row -->
        <div class="row justify-content-center align-items-center w-100">
            <div class="col-xl-8 col-12 d-flex justify-content-center align-items-center">

                <div class="m-0 w-100" dir="ltr" style="letter-spacing: inherit !important;">
                    <div class="m-0 d-flex justify-content-center align-items-center" id="chartCompare"
                        style="max-width: initial !important;" #graphDiv>
                    </div>
                </div>


            </div>
        </div>
        <!-- End Chart row -->

    </div>
</div>