import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

   /** utilities */
   handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    //console.log(errorMessage);
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors

      if (error.status === 401) {
        errorMessage = 'Error 401';
      }
      errorMessage = `Error Code: ${error.status}\\nMessage: ${error.message}`;
    }

    //return throwError(errorMessage);
    return errorMessage;
  }

  getConfig(): Observable<any> {
    const url = `${this.baseUrl}/Config`;

    return this.http.get<any>(url).pipe(retry(3), catchError(this.handleError));
  }

  getADOAll(listName: string): Observable<any> {
    const url = `${this.baseUrl}/${listName}`;

    return this.http.get<any>(url).pipe(retry(3), catchError(this.handleError));
  }

  getADO(listName: string, year: string): Observable<any> {
    const url = `${this.baseUrl}/${listName}/${year}`;

    return this.http.get<any>(url).pipe(retry(3), catchError(this.handleError));
  }

  getByYear(listName: string, year: number, mno: string): Observable<any> {
    const url = `${this.baseUrl}/${listName}/${year}/${mno}`;

    return this.http.get<any>(url).pipe(retry(3), catchError(this.handleError));
  }

  getByParameters(listName: string, year: number, examGender: string, examType:string, markType: string, examSpecial: string): Observable<any> {
    const url = `${this.baseUrl}/${listName}/${year}/${examGender}/${examType}/${markType}/${examSpecial}`;

    return this.http.get<any>(url).pipe(retry(3), catchError(this.handleError));
  }

  getSchoolByMno(mno: string,listName: string, year: number, examGender: string, examType:string, markType: string, examSpecial: string): Observable<any> {
    const url = `${this.baseUrl}/${listName}/Mno/${mno}/${year}/${examGender}/${examType}/${markType}/${examSpecial}`;

    return this.http.get<any>(url).pipe(retry(3), catchError(this.handleError));
  }

  getSchoolMarkByMno(mno: string,listName: string, year: number, examGender: string, examType:string, examSpecial: string): Observable<any> {
    const url = `${this.baseUrl}/${listName}/Mno/${mno}/${year}/${examGender}/${examType}/${examSpecial}`;

    return this.http.get<any>(url).pipe(retry(3), catchError(this.handleError));
  }

  getByParametersTopN(listName: string, year: number, examGender: string, examType:string, markType: string, examSpecial: string, topN:number, area: string): Observable<any> {
    const url = `${this.baseUrl}/${listName}/${year}/${examGender}/${examType}/${markType}/${examSpecial}/${topN}/${area}`;

    return this.http.get<any>(url).pipe(retry(3), catchError(this.handleError));
  }

  getByObjection(listName: string, year: number, examGender: string, examType:string, markType: string, examSpecial: string): Observable<any> {
    const url = `${this.baseUrl}/${listName}/Obj/${year}/${examGender}/${examType}/${markType}/${examSpecial}`;

    return this.http.get<any>(url).pipe(retry(3), catchError(this.handleError));
  }
}
