<section id="contactus"
    class="d-flex align-items-xl-center position-relative py-5 overflow-hidden px-3 px-lg-0" style="background-color: #F9F9F9;">
    <div class="container">
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-xl-4 col-12 g-0">
                <div class="d-flex flex-xl-row flex-column justify-content-center align-items-center p-0 mb-sm-5 mb-4">
                    <div class="text-center me-xl-4 mb-xl-0 mb-3">
                        <img src="assets/media/icon/contactus-icon.svg" alt="statistics-icon" class="img-fluid"
                            width="60">
                    </div>
                    <div class="text-xl-start text-center">
                        <h4 class="fw-semibold mb-0 mt-xl-3">اتصل بنا</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12 g-xl-4 g-0 mt-0">
                <iframe class="d-block h-100 shadow-primary"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1811.9614459369507!2d46.59609973224652!3d24.72952734952678!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1b6ba6fc41f7%3A0xb065eeeb470df366!2z2YfZitim2Kkg2KrZgtmI2YrZhSDYp9mE2KrYudmE2YrZhSDZiNin2YTYqtiv2LHZitioIEVkdWNhdGlvbiAmVHJhaW5pbmcgRXZhbHVhdGlvbiBjb21taXNzaW9u!5e0!3m2!1sen!2sua!4v1659437270435!5m2!1sen!2sua"
                    style="border: 0; min-height: 350px;border-radius: 1.375rem;" allowfullscreen=""
                    loading="lazy"></iframe>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-xl-2 g-4 text-center d-flex align-items-center my-4 py-3">
            <div class="col d-flex flex-column justify-content-start align-items-start" data-wow-duration="2.5s">
                <div class="d-flex text-start mb-3" style="width: 350px;">
                    <i class="bx bx-stopwatch text-primary fs-2"></i>
                    <span class="ps-3 fs-lg fw-normal text-primary mb-0 d-flex align-items-center">
                        مواعيد العمل: من الأحد إلى الخميس- 8.00 ص إلى 4.00 م</span>
                </div>
                <div class="d-flex text-start" style="width: 350px;">
                    <i class="bx bx-map text-primary fs-2"></i>
                    <a href="https://goo.gl/maps/22s6CgxfVu44e2Kc7"
                        class="ps-3 fs-lg fw-normal text-primary mb-0 mb-n1 text-decoration-none d-flex align-items-center">2694
                        طريق
                        الملك خالد - حي النخيل الرياض 12395 - 6592</a>
                </div>
            </div>
            <div class="col d-flex flex-column justify-content-start align-items-start">
                <div class="d-flex mb-3">
                    <i class="bx bx-phone text-primary fs-2"></i>
                    <a class="ps-3 fs-lg fw-normal text-primary mb-0 text-decoration-none d-flex align-items-center"
                        href="tel:920033555">920033555</a>
                </div>
                <div class="d-flex mb-2">
                    <i class="bx bx-envelope text-primary fs-2"></i>
                    <a class="ps-3 fs-lg fw-normal text-primary mb-0 text-decoration-none d-flex align-items-center"
                        href="mailto:tarteeb@etec.gov.sa">tarteeb@etec.gov.sa</a>
                </div>
                <div class="d-flex justify-content-lg-end justify-content-center align-items-center mt-3">
                    <a href="https://www.facebook.com/ETECKSA"
                        class="btn btn-outline-primary btn-icon me-1 border-2 rounded-3" type="button"
                        style="width: 2rem;height: 2rem;" target="_blank"><i class="bx bxl-facebook"></i></a>
                    <a href="https://www.twitter.com/EtecKsa"
                        class="btn btn-outline-primary btn-icon me-1 border-2 rounded-3" type="button"
                        style="width: 2rem;height: 2rem;" target="_blank"><i class="bx bxl-twitter"></i></a>
                    <a href="https://www.youtube.com/etecksa"
                        class="btn btn-outline-primary btn-icon me-1 border-2 rounded-3" type="button"
                        style="width: 2rem;height: 2rem;" target="_blank"><i class="bx bxl-youtube"></i></a>
                    <a href="https://www.instagram.com/etecksa/"
                        class="btn btn-outline-primary btn-icon me-1 border-2 rounded-3" type="button"
                        style="width: 2rem;height: 2rem;" target="_blank"><i class="bx bxl-instagram-alt"></i></a>
                    <a href="https://ae.linkedin.com/company/etec-ksa"
                        class="btn btn-outline-primary btn-icon border-2 rounded-3" type="button"
                        style="width: 2rem;height: 2rem;" target="_blank"><i class="bx bxl-linkedin"></i></a>
                </div>
            </div>
        </div>

    </div>
</section>