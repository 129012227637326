<!--begin::footer-->
<footer class="footer bg-light border-0 py-4" style="background-color: #F6F0FF !important">
    <div class="container pt-lg-0">

        <div class="row">
            <div class="col-12 d-flex justify-content-center align-items-center">
                <p class="fs-md text-center text-primary m-0 "> جميع الحقوق محفوظة هيئة تقويم التعليم والتدريب
                    2023 ©
                </p>
            </div>
        </div>

    </div>
</footer>