<!-- school data offcanvas -->
<div
  class="offcanvas offcanvas-end offcanvas-filter shadow-primary border-0 {{
    showCanvasLocal
  }}"
  id="schoolData"
  tabindex="-1"
  data-bs-scroll="true"
  data-bs-backdrop="false"
  [ngStyle]="{
    visibility: this.showCanvasLocal == 'show' ? 'visible' : 'hidden'
  }"
>
  <div
    class="offcanvas-header bg-secondary py-4"
    style="background-color: var(--secondaryColor) !important"
  >
    <h5 class="offcanvas-title text-white m-0" id="MapSearchOffcanvas">
      {{ selectedSchool?.Name }}
    </h5>
    <button
      class="btn-close text-white adv-search-close"
      type="button"
      (click)="hideCard()"
    ></button>
  </div>
  <div class="offcanvas-body p-0">
    <!-- <div class="d-flex justify-content-around p-3" style="background-color: #f6f0ff !important;">
            <img style="display: block;-webkit-user-select: none;margin: auto;background-color: hsl(0, 0%, 90%);transition: background-color 300ms;" src="https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-l-embassy+f74e4e({{selectedSchool?.Lut}},{{selectedSchool?.Lang}},12)/{{selectedSchool?.Lut}},{{selectedSchool?.Lang}},14,0/300x200@2x?access_token=pk.eyJ1IjoiaGlsYWwyMDMwIiwiYSI6ImNsZmV6dWhsMzN6bnczdnIwZzNnc2FjbnQifQ.D0Fxqerok8ZHyk_3TH17gg&logo=false">
        </div> -->
    <div
      class="d-flex justify-content-around p-3"
      style="background-color: #f6f0ff !important"
    >
      <p class="text-primary fs-lg m-0">{{ selectedSchool?.ExamSpecial }}</p>
      <p class="text-primary fs-lg m-0">{{ selectedSchool?.ExamType }}</p>
      <p
        *ngIf="selectedSchool?.MarkType != 'كلية'"
        class="text-primary fs-lg m-0"
      >
        {{ selectedSchool?.MarkType }}
      </p>
      <p class="text-primary fs-lg m-0">
        {{ selectedSchool?.Gender == "M" ? "بنين" : "بنات" }}
      </p>
      <p class="text-primary fs-lg m-0">{{ selectedSchool?.Authority }}</p>
    </div>
    <div
      class="d-flex flex-column justify-content-center align-items-center p-4 pb-2"
    >
      <!-- <h4 class="text-center mb-0 fs-lg fw-semibold text-primary">{{selectedSchool?.Name}}</h4> -->
      <p class="text-center mb-0 mt-2 fs-lg text-primary">
        {{ selectedSchool?.Department }}
      </p>
      <p class="text-center mb-0 mt-0 fs-lg text-primary">
        {{ selectedSchool?.Office }}
      </p>
    </div>
    <div class="px-4 pt-0 pb-3">
      <div
        class="{{
          dynamicClass
        }} w-100 rounded-3 p-2 text-center d-flex justify-content-center align-items-center"
      >
        <div>
          <p class="m-0 text-white fw-semibold fs-lg">
            {{ selectedSchool?.Average }}
          </p>
          <p class="m-0 text-white fs-lg">{{ selectedSchool?.Year }}</p>
        </div>
      </div>
    </div>
    <div class="px-0 pt-0 pb-2">
      <div class="card bg-light border-0 mb-2">
        <div
          class="card-body p-2 px-4 d-flex align-items-center justify-content-between"
        >
          <div class="flex-shrink-0 p-2 text-start">
            <img src="assets/media/icon/saudi-icon.svg" alt="icon" width="35" />
          </div>
          <div class="px-0 w-50">
            <p class="m-0 fs-lg text-primary">
              الترتيب على مستوى المملكة
              <span *ngIf="selectedSchool?.MarkType != 'كلية'"
                >- {{ selectedSchool?.MarkType }}</span
              >
            </p>
          </div>
          <div class="flex-shrink-0 p-2 text-end w-25">
            <p class="m-0 fs-lg text-primary fw-semibold">
              {{ selectedSchool?.KsaRank }}
            </p>
          </div>
        </div>
      </div>
      <div class="card bg-light border-0 mb-2">
        <div
          class="card-body p-2 px-4 d-flex align-items-center justify-content-between"
        >
          <div class="flex-shrink-0 p-2 text-start">
            <img
              src="assets/media/icon/admin-building.svg"
              alt="icon"
              width="32"
            />
          </div>
          <div class="px-0 w-50">
            <p class="m-0 fs-lg text-primary">
              الترتيب على مستوى إدارات التعليم
              <span *ngIf="selectedSchool?.MarkType != 'كلية'"
                >- {{ selectedSchool?.MarkType }}</span
              >
            </p>
          </div>
          <div class="flex-shrink-0 p-2 text-end w-25">
            <p class="m-0 fs-lg text-primary fw-semibold">
              {{ selectedSchool?.DeptRank }}
            </p>
          </div>
        </div>
      </div>
      <div class="card bg-light border-0">
        <div
          class="card-body p-2 px-4 d-flex align-items-center justify-content-between"
        >
          <div class="flex-shrink-0 p-2 text-start">
            <img
              src="assets/media/icon/office-building.svg"
              alt="icon"
              width="31"
            />
          </div>
          <div class="px-0 w-50">
            <p class="m-0 fs-lg text-primary">
              الترتيب على مستوى مكتب التعليم
              <span *ngIf="selectedSchool?.MarkType != 'كلية'"
                >- {{ selectedSchool?.MarkType }}</span
              >
            </p>
          </div>
          <div class="flex-shrink-0 p-2 text-end w-25">
            <p class="m-0 fs-lg text-primary fw-semibold">
              {{ selectedSchool?.OffficeRak }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between px-0 pt-0 pb-2">
      <div class="badge bg-light w-100 text-primary fs-lg fw-normal py-2 me-2">
        <p class="text-primary mb-2 fs-lg">متوسط المكتب</p>
        <p class="text-primary fs-lg fw-semibold mb-0">
          {{ selectedSchool?.OfficeAvg }}
        </p>
      </div>
      <div class="badge bg-light w-100 text-primary fs-lg fw-normal py-2">
        <p class="text-primary mb-2 fs-lg">متوسط الإدارة</p>
        <p class="text-primary fs-lg fw-semibold mb-0">
          {{ selectedSchool?.DeptAvg }}
        </p>
      </div>
    </div>
    <div class="card bg-light border-0 mb-0" *ngIf="hasDetails">
      <div class="card-header pb-0 border-0">
        <p class="text-primary m-0 w-100">
          متوسط المجالات لعام {{ selectedSchool?.Year }}
        </p>
      </div>
      <div class="card-body p-2 px-4">
        <div class="row row-cols-2 m-0 w-100 g-2">
          <div class="col" *ngFor="let item of marks">
            <div
              class="{{
                getLevelColor(item.Average)
              }} w-100 rounded-3 p-2 text-center d-flex justify-content-center align-items-center"
            >
              <div>
                <p class="m-0 text-white fw-semibold fs-lg">
                  {{ item?.MarkType }}
                </p>
                <p class="m-0 text-white fs-lg">{{ item.Average }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center m-0 mt-n3 px-3">
      <div id="chart" #graphDiv></div>
    </div>

    <!-- <div class="d-flex flex-row justify-content-between bg-light px-0 pt-0 pb-2">
            <p class="text-center">الترتيب على مستوي المملكة في مجال:</p>
        </div> -->
  </div>
  <!-- <div class="offcanvas-footer d-flex bg-white p-3 py-2 border-0">
    <a
      href="profile/{{ selectedSchool?.MNO }}/{{ selectedSchool?.Year }}/{{ selectedSchool?.Gender}}/{{ selectedSchool?.ExamType }}/{{ selectedSchool?.ExamSpecial }}"
      target="_blank"
      class="btn btn-primary fs-lg w-100 me-2"
    >
      ملف المدرسة
    </a>
    <a
      href="https://maps.google.com/maps?daddr={{ selectedSchool?.Lang }},{{
        selectedSchool?.Lut
      }}&ll="
      target="_blank"
      class="btn btn-success fs-lg me-2"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title="التوجه للموقع"
    >
      <i class="bx bxs-map fs-lg"></i>
    </a>
    <a
      href="https://twitter.com/intent/tweet?text={{
        queryString
      }}&hashtags=مؤشر_ترتيب"
      target="_blank"
      class="btn btn-info fs-lg"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title="شارك على"
      style="background-color: #00acee !important"
    >
      <i class="bx bxl-twitter fs-lg"></i>
    </a>
  </div> -->

  <div
    _ngcontent-dtw-c22=""
    class="offcanvas-footer d-flex bg-white p-3 py-2 border-0"
  >
    <a
      target="_blank"
      class="btn btn-primary fs-lg w-100 me-2"
      href="https://maps.google.com/maps?daddr={{ selectedSchool?.Lang }},{{
        selectedSchool?.Lut
      }}&ll="
      ><i  class="bx bxs-map fs-lg me-2"></i> التوجه للموقع </a
    ><a
     
      target="_blank"
      class="btn btn-info fs-lg w-100"
      style="background-color: #00acee !important"
      href="https://twitter.com/intent/tweet?text={{
        queryString
      }}&hashtags=مؤشر_ترتيب"
      ><i  class="bx bxl-twitter fs-lg me-2"></i> شارك على
    </a>
  </div>
</div>
