<div class="offcanvas offcanvas-end offcanvas-filter shadow-primary border-0 {{showCanvasLocal}}" id="officeData"
    tabindex="-1" data-bs-scroll="true" data-bs-backdrop="true"  [ngStyle]="{'visibility':  this.showCanvasLocal=='show'?'visible':'hidden'}">
    <div class="offcanvas-header bg-secondary py-4" style="background-color: #6379C7 !important">
        <h5 class="offcanvas-title text-white m-0" id="MapSearchOffcanvas">
            بيانات مكتب التعليم
        </h5>
        <button class="btn-close text-white adv-search-close" type="button" (click)="hideCard()"></button>
    </div>
    <div class="offcanvas-body p-0">
        <div class="d-flex justify-content-around p-3" style="background-color: #F6F0FF !important;">
            <p class="text-primary fs-lg m-0">{{selectedOffice?.ExamSpecial}}</p>
            <p class="text-primary fs-lg m-0">{{selectedOffice?.ExamType}}</p>
            <p class="text-primary fs-lg m-0">{{selectedOffice?.ExamGender =='M'?'بنين':'بنات'}}</p>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center p-4 pb-2">
            <h6 class="fs-lg mb-0 fw-semibold text-center text-primary">{{selectedOffice?.OfficeName}}</h6>
            <p class="fs-lg mb-0 mt-2 text-primary text-center ">{{selectedOffice?.EducationDepartment}}</p>
        </div>
        <div class="px-4 pt-0 pb-3">
            <div
                class="{{dynamicClass}} w-100 rounded-3 p-2 text-center d-flex justify-content-center align-items-center">
                <div class="">
                    <p class="m-0 text-white fw-normal fs-lg">{{selectedOffice?.Average}}</p>
                    <p class="m-0 text-white fs-lg">{{selectedOffice?.Year}}</p>
                </div>
            </div>
        </div>
        <div class="px-0 pt-0 pb-3">
            <div class="card bg-light border-0 mb-2">
                <div class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                    <div class="flex-shrink-0 p-2 text-start">
                        <img src="assets/media/icon/saudi-icon.svg" alt="icon" width="35">
                    </div>
                    <div class="px-0 w-50">
                        <p class="m-0 fs-lg text-primary">الترتيب على مستوى المملكة</p>
                    </div>
                    <div class="flex-shrink-0 p-2 text-end w-25">
                        <p class="m-0 fs-lg text-primary fw-semibold">{{selectedOffice?.KsaRank}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-footer d-flex bg-white p-3 py-2 border-0">
        <a href="https://maps.google.com/maps?daddr={{selectedOffice?.Lang}},{{selectedOffice?.Lut}}&ll="
            target="_blank" class="btn btn-primary fs-lg w-100 me-2">
            <i class="bx bxs-map fs-5 me-2"></i>
            التوجه للموقع
        </a>
    </div>
</div>