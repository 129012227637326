import { Component, Input, OnInit } from '@angular/core';
import resources from '../../../assets/json/resources.json';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {
  @Input() defaultYear!: string;
  
  year:number;
  schools:string;
  noOfYears:number;
  qudratStudents:string;
  tahsiliStudent:string;

  aPTStudentTotalStr = '';
  aCTStudentTotalStr= '';
  schoolsTotalStr = '';
  @Input() schoolsTotal:number = 0;
  @Input() yearsOfScale:number = 0;
  @Input() aPTStudentTotal:number = 0;
  @Input() aCTStudentTotal:number = 0;

  constructor() {
    this.year = resources.pointerYear;
    this.schools = resources.pointerSchools;
    this.noOfYears = resources.pointerNoOfYears;
    this.qudratStudents = resources.pointerQudratStudents;
    this.tahsiliStudent = resources.pointerTahsiliStudents;
   }

  ngOnInit(): void {
  }

  ngOnChanges(){
    this.aPTStudentTotalStr = this.aPTStudentTotal?.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.aCTStudentTotalStr = this.aCTStudentTotal?.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.schoolsTotalStr = this.schoolsTotal?.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}
