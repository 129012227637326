

<main class="page-wrapper">
  <app-header></app-header>
  <router-outlet></router-outlet>
  <!-- <mat-spinner></mat-spinner> -->
      <ng-http-loader [entryComponent]="spinnerComponent"></ng-http-loader>
    <!-- <ng-http-loader [backdrop]="false" [backgroundColor]="'#ff0000'" [debounceDelay]="100" [extraDuration]="3000"
      [minDuration]="30000" opacity=".6" [backdropBackgroundColor]="'#777777'" [spinner]="spinkit.skWave">
    </ng-http-loader> -->
    <!-- <div id="preloader">
      <img src="assets/media/logo/ETEC-OI.svg" width="65" />
      <div id="loader"></div>
    </div> -->
   
</main>
<app-footer></app-footer>

