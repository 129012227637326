
<!-- <section id="about" class="overflow-hidden mt-n5 pt-xl-0 pt-5">
    <div class="container py-5 py-md-3 py-lg-4 py-xl-5 pt-0 mt-n5"> -->
<section id="about" class="overflow-hidden pt-xl-0 pt-5">
    <div class="container py-5 py-md-3 py-lg-4 py-xl-5 pt-0">
        <div class="row row-cols-1 row-cols-lg-2 gx-xl-5 gx-lg-4 gx-3">
            <div class="col mb-3 mb-lg-0">
                <div class="card bg-white shadow-primary h-100 p-4">
                    <div class="card-body d-flex flex-xl-row flex-column justify-content-center align-items-center p-0">
                        <div class="text-center me-xl-5 mb-xl-0 mb-3">
                            <img src="assets/media/icon/target-icon.svg" alt="target-icon" width="55" class="mb-2">
                            <h4 class="mb-0 fw-semibold">الهدف</h4>
                        </div>
                        <div class="text-xl-start text-center">
                            <p class="text-primary mb-0 fs-lg">يهدف إلى تحفيز التنافس بين إدارات ومكاتــــــب التعليم
                                والمدارس لتجويد المخرجــــات
                                وتحسيــــــن الأداء في هذه
                                الاختبــــــارات.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card bg-white shadow-primary h-100 p-4">
                    <div class="card-body d-flex flex-xl-row flex-column justify-content-center align-items-center p-0">
                        <div class="text-center me-xl-5 mb-xl-0 mb-3">
                            <img src="assets/media/icon/play-icon.svg" alt="target-icon" width="55" class="mb-2">
                            <h4 class="mb-0 fw-semibold">آلية الاحتساب</h4>
                        </div>
                        <div class="text-xl-start text-center">
                            <p class="text-primary mb-0 fs-lg" style="text-align: justify !important;">
                                يتم احتساب متوســــط الدرجة لكل إدارة تعليــــــــم ومكتـــــب تعلــــــــيـــــم
                                ومدرسة، بناء على متوسط درجات الطلاب في
                                آخــــر ثلاثة أعوام دراسية.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row my-5" id="indicatorAbout">
            <div class="col">
                <div class="card bg-white border-0 h-100 p-0">
                    <div class="card-body d-flex flex-xl-row flex-column justify-content-center align-items-center p-0">
                        <div class="text-center me-xl-5 mb-xl-0 mb-3">
                            <img src="assets/media/icon/aboutScale-icon.svg" alt="target-icon"
                                class="img-fluid img-respon-about mb-2">
                        </div>
                        <div class="text-xl-start text-center">
                            <h2 class="fw-semibold">مؤشر القدرات والتحصيلي</h2>
                            <p class="text-primary fs-lg mb-0">
                                يقدم الموقع الخاص بالمؤشر مؤشرات متعددة مع إمكانية البحث المفصل على مستوى مكتب التعليم
                                وإدارة التعليم وعلى المستوى
                                الوطني لمدارس البنين والبنات، بالإضافة للبحث على خارطة المملكة. ويتم حالياً تغطية
                                اختبارات القبول الجامعي (القدرات
                                العامة، والتحصيلي) للمدارس الثانوية
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-lg-3 gx-xl-5 gx-lg-4 gx-3 mb-5">
            <div class="col mb-3 mb-lg-0">
                <div class="card p-3 bg-primary shadow-primary h-100 p-xl-4">
                    <div class="card-body p-0 d-flex flex-xl-row flex-column justify-content-center align-items-center">
                        <div class="text-center me-xl-4 mb-xl-0 mb-3">
                            <img src="assets/media/icon/about1-icon.svg" alt="about-icon" class="img-fluid" width="55">
                        </div>
                        <div class="text-xl-start text-center">
                            <p class="text-white fs-md mb-0">
                                يقدّم بالتعاون بين هيئة تقويم التعليم والتدريب ووزارة التعليم
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col mb-3 mb-lg-0">
                <div class="card p-3 bg-primary shadow-primary h-100 p-xl-4">
                    <div class="card-body p-0 d-flex flex-xl-row flex-column justify-content-center align-items-center">
                        <div class="text-center me-xl-4 mb-xl-0 mb-3">
                            <img src="assets/media/icon/about2-icon.svg" alt="about-icon" class="img-fluid" width="55">
                        </div>
                        <div class="text-xl-start text-center">
                            <p class="text-white fs-md mb-0">
                                يبيّـــــــــن ترتيـــــب إدارات ومـــــكاتب التعليم حسب نظام مُعيّن
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card p-3 bg-primary shadow-primary h-100 p-xl-4">
                    <div class="card-body p-0 d-flex flex-xl-row flex-column justify-content-center align-items-center">
                        <div class="text-center me-xl-4 mb-xl-0 mb-3">
                            <img src="assets/media/icon/about3-icon.svg" alt="about-icon" class="img-fluid" width="55">
                        </div>
                        <div class="text-xl-start text-center">
                            <p class="text-white fs-md mb-0">
                                اعتماد النتائج مبني على اختبارات الهيئة المعياريّة
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</section>