import { Component, OnInit, AfterViewInit } from '@angular/core';
import { marker } from 'leaflet';
import * as L from 'leaflet';
import 'leaflet-boundary-canvas';
//import ksa from '../../assets/json/ksa.json';
import ksa from '../../assets/json/ksa-old.json';
//import ado from '../../assets/json/ado.json';
// import schools from '../../assets/json/data.json';
import { SchoolList } from '../model/SchoolList';
// import riyadh from '../../assets/json/area-position/riyadh.json';
// import baha from '../../assets/json/area-position/baha.json';
// import makkah from '../../assets/json/area-position/makkah.json';
// import tabuk from '../../assets/json/area-position/tabuk.json';
// import sharkiah from '../../assets/json/area-position/sharkiah.json';
// import asir from '../../assets/json/area-position/asir.json';
// import hail from '../../assets/json/area-position/hail.json';
// import jawf from '../../assets/json/area-position/jawf.json';
// import jazan from '../../assets/json/area-position/jazan.json';
// import medinah from '../../assets/json/area-position/medinah.json';
// import najran from '../../assets/json/area-position/najran.json';
// import qassim from '../../assets/json/area-position/qassim.json';
//import northborder from '../../assets/json/area-position/northborder.json';
import _area from '../../assets/json/area.json';
import position from '../../assets/json/areaposition.json';
//import deptData from '../../assets/json/department.json';
//import officeData from '../../assets/json/office.json';
import { ADO, DepartmentList, OfficeList } from '../model/DepartmentList';
import ksaAverage from '../../assets/json/ksa-average.json';
import { fromEvent, windowWhen } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ApiService } from '../api.service.service';
import { CookieService } from 'ngx-cookie-service';
import { Config } from '../model/Area';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements AfterViewInit {
  private map: any;
  private geom: any = ksa;
  SchoolData: SchoolList[] = [];
  officeData: OfficeList[] = [];
  deptData: DepartmentList[] = [];
  pointerDepartment: string[] = [];
  pointerOffice: string[] = [];
  ado : ADO[]=[];
  Department: any;
  Office: any;
  ksaCheck = false;
  schoolCheck = true;
  officeCheck = false;
  departmentCheck = false;
  top10Check = false;
  DepartmentNoRepeat: string[] = [];
  searchShoolResult: SchoolList[] = [];
  searchShoolShowResult: SchoolList[] = [];
  searchOfficeResult: OfficeList[] = [];
  searchDepartmentResult: DepartmentList[] = [];
  comparedSchool: SchoolList[] = [];
  year: string = '0';
  _year: string = '0';
  examTypeFull: string = 'تحصيلي,علمي';
  _examTypeFull: string = 'تحصيلي,علمي';
  examSubType: string = 'كلية';
  _examSubType: string = 'كلية';
  examType: string = 'تحصيلي';
  examSpecial: string = 'علمي';
  scoreLevel: string = '0';
  examGender: string = 'M';
  _examGender: string = 'M';
  area: string = '0';
  _area: string = '0';
  department: string = '0';
  office: string = '0';
  authority: string = '0';
  marker: any = [];
  markerDept: any = [];
  markerOffice: any = [];
  cities: any = [];
  config: Config[] = [];
  showOffcanvas = '';
  showOffDeptcanvas = '';
  showOffOfficecanvas = '';
  dynamicClass = '';
  selectedSchool!: SchoolList;
  selectedDepartment!: DepartmentList;
  selectedOffice!: OfficeList;
  selectAreaGeom: any;
  txtSearch = '';
  recordSeed = 0;
  recordDeptSeed = 0;
  recordOddiceSeed = 0;
  recordShow = 15;
  maxComparedNo = 4;
  showCompare = '';
  showComparedSchoolRow = true;
  minimize = '';
  txtSearchTable = '';
  tabName: string = '';
  queryString = '';
  chart: any;
  tabListFixed = '';
  searchFixed = '';
  riyadh: any;
  baha: any;
  makkah: any;
  tabuk: any;
  sharkiah: any;
  asir: any;
  hail: any;
  jawf: any;
  jazan: any;
  medinah: any;
  najran: any;
  qassim: any;
  northborder: any;
  isAgree = false;
  hasDetailes =  false;
  enableDetails = false;
  TE: string[] = ['كلية', 'رياضيات', 'أحياء', 'فيزياء', 'كيمياء'];
  TN: string[] = [
    'كلية',
    'تاريخ',
    'أدب',
    'فقه',
    'حديث',
    'توحيد',
    'نحو',
    'جغرافيا',
  ];
  QE: string[] = ['كلية', 'كمي', 'لفظي'];
  QN: string[] = ['كلية', 'كمي', 'لفظي'];
  ExamSubType: string[] = [];
  // #region marker icons

  darkGreenIcon = L.icon({
    iconUrl: '../../assets/leaflet/marker_Darkgreen.svg',
    iconSize: [15, 15], // size of the icon
  });
  midGreennIcon = L.icon({
    iconUrl: '../../assets/leaflet/marker_Midgreen.svg',
    iconSize: [15, 15], // size of the icon
  });
  lightGreenIcon = L.icon({
    iconUrl: '../../assets/leaflet/marker_Lightgreen.svg',
    iconSize: [15, 15], // size of the icon
  });
  yellowIcon = L.icon({
    iconUrl: '../../assets/leaflet/marker_yellow.svg',
    iconSize: [15, 15], // size of the icon
  });
  orangeIcon = L.icon({
    iconUrl: '../../assets/leaflet/marker_orange.svg',
    iconSize: [15, 15], // size of the icon
  });
  redIcon = L.icon({
    iconUrl: '../../assets/leaflet/marker_red.svg',
    iconSize: [15, 15], // size of the icon
  });
  darkGreenIconSquare = L.icon({
    iconUrl: '../../assets/leaflet/marker_Darkgreen_square.svg',
    iconSize: [40, 40], // size of the icon
  });
  midGreennIconSquare = L.icon({
    iconUrl: '../../assets/leaflet/marker_Midgreen_square.svg',
    iconSize: [40, 40], // size of the icon
  });
  lightGreenIconSquare = L.icon({
    iconUrl: '../../assets/leaflet/marker_Lightgreen_square.svg',
    iconSize: [40, 40], // size of the icon
  });
  yellowIconSquare = L.icon({
    iconUrl: '../../assets/leaflet/marker_yellow_square.svg',
    iconSize: [40, 40], // size of the icon
  });
  orangeIconSquare = L.icon({
    iconUrl: '../../assets/leaflet/marker_orange_square.svg',
    iconSize: [40, 40], // size of the icon
  });
  redIconSquare = L.icon({
    iconUrl: '../../assets/leaflet/marker_red_square.svg',
    iconSize: [40, 40], // size of the icon
  });
  darkGreenIconTriangle = L.icon({
    iconUrl: '../../assets/leaflet/marker_Darkgreen_triangle.svg',
    iconSize: [25, 25], // size of the icon
  });
  midGreennIconTriangle = L.icon({
    iconUrl: '../../assets/leaflet/marker_Midgreen_triangle.svg',
    iconSize: [25, 25], // size of the icon
  });
  lightGreenIconTriangle = L.icon({
    iconUrl: '../../assets/leaflet/marker_Lightgreen_triangle.svg',
    iconSize: [25, 25], // size of the icon
  });
  yellowIconTriangle = L.icon({
    iconUrl: '../../assets/leaflet/marker_yellow_triangle.svg',
    iconSize: [25, 25], // size of the icon
  });
  orangeIconTriangle = L.icon({
    iconUrl: '../../assets/leaflet/marker_orange_triangle.svg',
    iconSize: [25, 25], // size of the icon
  });
  redIconTriangle = L.icon({
    iconUrl: '../../assets/leaflet/marker_red_triangle.svg',
    iconSize: [25, 25], // size of the icon
  });

  // #endregion

   constructor(
    private httpService: HttpClient,
    private title: Title,
    private apiService: ApiService,
    private cookieService: CookieService
  ) {
    //this.httpService.get('../../assets/json/office.json')

    this.title.setTitle('مؤشر ترتيب | الخريطة التفاعلية');
    this.isAgree = this.getCookiefooter();


    //scroll events
    fromEvent(window, 'scroll')
      .pipe()
      .subscribe(async (e: Event) => {
        if (window.scrollY >= 120) {
          this.tabListFixed = 'tablist-fixed';
        }
        if (window.scrollY >= 40) {
          this.searchFixed = 'search-fixed';
        }
        if (window.scrollY < 120) {
          this.tabListFixed = '';
        }
        if (window.scrollY < 40) {
          this.searchFixed = 'search-fix';
        }
      });

    if (this.examTypeFull == 'تحصيلي,علمي') {
      this.ExamSubType = this.TE.slice();
    } else if (this.examTypeFull == 'تحصيلي,نظري') {
      this.ExamSubType = this.TN.slice();
    } else if (this.examTypeFull == 'قدرات,علمي') {
      this.ExamSubType = this.QE.slice();
    } else if (this.examTypeFull == 'قدرات,نظري') {
      this.ExamSubType = this.QE.slice();
    }
    this.getADO();
  }

  getADO(){
    this.apiService
    .getADOAll('ADO')
    .subscribe((data: ADO[]) => {
      this.ado = data;
    });
  }

  private  initMap(): void {

    var southWest = L.latLng(2.77353737225232, 29.55525603227491),
      northEast = L.latLng(45.98590990829359, 66.55721003864447),
      bounds = L.latLngBounds(southWest, northEast);
    var mbUrl = 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png';
    this.map = L.map('map', {
      maxBounds: bounds,
    }).setView([24.779265, 46.738586], 6);

    var osm = L.TileLayer.boundaryCanvas(mbUrl, {
      boundary: this.geom,
      attribution: 'ETEC',
      trackAttribution: true,
      maxZoom: 14,
      minZoom: 5,
    }).addTo(this.map);

    this.cities = L.layerGroup().addTo(this.map);
    // control Zoom Position
    this.map.zoomControl.remove();
    L.control
      .zoom({
        position: 'topright',
      })
      .addTo(this.map);
  }

  ngAfterViewInit(): void {
    this.apiService
    .getConfig()
    .subscribe((data: Config[]) => {
      this.config = data.sort((a,b) => b.YearOrder - a.YearOrder).filter(m => m.IsPublished);
      this.year = ( data.filter(m => m.IsDefault == true)?.at(0)?.ResultYear.toString())!;
      this.hasDetailes = ( data.filter(m => m.IsDefault == true)?.at(0)?.EnableDetailedResults)!;

      this.apiService
      .getByParameters(
        'School',
        Number(this.year),
        this.examGender,
        this.examType,
        this.examSubType,
        this.examSpecial
      )
      .subscribe( (data) => {
        this.SchoolData = data as SchoolList[];
        //console.log(data);
        // FILL THE ARRAY WITH DATA.
        
        this.apiService
          .getByYear('Office', Number(this.year), this.examSubType)
          .subscribe((data) => {
            this.officeData = data as OfficeList[];
            this.apiService
              .getByYear('Directorate', Number(this.year), this.examSubType)
              .subscribe((data) => {
                this.deptData = data as DepartmentList[];
                this.initMap();
                this.applySearch();
              });
          });
      });
    });

 
  }

  displayStyle = 'none';

  openPopup() {
    this.displayStyle = 'block';
  }
  closePopup() {
    this.displayStyle = 'none';
  }

  //Cusotm
  searchBtn() {
    if (
      this.examSpecial == 'نظري' &&
      this.examType == 'تحصيلي' &&
      this.examGender == 'M'
    ) {
      this.openPopup();
      return;
    }

    this.apiService
      .getByParameters(
        'School',
        Number(this.year),
        this.examGender,
        this.examType,
        this.examSubType,
        this.examSpecial
      )
      .subscribe((data) => {
        this.SchoolData = [];
        this.SchoolData = data as SchoolList[];

        this.apiService
        .getByYear('Office', Number(this.year), this.examSubType)
        .subscribe((data) => {
          this.officeData = data as OfficeList[];
          this.apiService
            .getByYear('Directorate', Number(this.year), this.examSubType)
            .subscribe((data) => {
              this.deptData = data as DepartmentList[];
             // this.initMap();
              this.applySearch();
            });
        });

        //this.applySearch();
      });
  }

  async getConfig(){
    this.apiService
    .getConfig()
    .subscribe((data: Config[]) => {
      this.config = data.sort((a,b) => b.YearOrder - a.YearOrder);
      this.year = ( data.filter(m => m.IsDefault == true)?.at(0)?.ResultYear.toString())!;
      this.hasDetailes = ( data.filter(m => m.IsDefault == true)?.at(0)?.EnableDetailedResults)!;
    });

  }

  addToCmpare(item: SchoolList, addRemove: number) {
    this.showComparedSchoolRow = true;

    if (addRemove == 1) {
      if (this.comparedSchool.length < this.maxComparedNo) {
        if (this.comparedSchool.indexOf(item) == -1)
          this.comparedSchool.push(item);
      }
    } else if (addRemove == 0) {
      const index = this.comparedSchool.indexOf(item, 0);
      if (index > -1) {
        this.comparedSchool.splice(index, 1);
      }
    }
    this.showCompare = '';
    this.showCompare = this.comparedSchool.length > 0 ? 'show' : '';
  }

  search() {
    this.searchShoolShowResult = this.searchShoolResult;
    this.searchShoolShowResult = this.searchShoolShowResult.filter(
      (x) =>
        x.Name?.toUpperCase().includes(this.txtSearchTable.toUpperCase()) ||
        x.Department?.toUpperCase().includes(
          this.txtSearchTable.toUpperCase()
        ) ||
        x.Office?.toUpperCase().includes(this.txtSearchTable.toUpperCase())
    );
  }

  onYearChangeDynamic(_year: string) {
    this.year = _year;
    this.area = '0';
    this.department = '0';
    this.office = '0';
    this.examSubType = 'كلية';
    this.hasDetailes =  ( this.config.filter(m => m.ResultYear == Number(_year))?.at(0)?.EnableDetailedResults)!;
  }
  onYearChange(event: any) {
    this.year = event.target.value;
    //this.year = _year;
    this.area = '0';
    this.department = '0';
    this.office = '0';
  }

  onExamTypeChange(event: any) {
    let typeSpecial: string = event.target.value;
    this.examType = typeSpecial.split(',')[0];
    this.examSpecial = typeSpecial.split(',')[1];

    if (typeSpecial == 'تحصيلي,علمي') {
      this.ExamSubType = this.TE.slice();
      this.examSubType = 'كلية';
    } else if (typeSpecial == 'تحصيلي,نظري') {
      this.examGender = 'F';
      this.ExamSubType = this.TN.slice();
      this.examSubType = 'كلية';
    } else if (typeSpecial == 'قدرات,علمي') {
      this.ExamSubType = this.QE.slice();
      this.examSubType = 'كلية';
    } else if (typeSpecial == 'قدرات,نظري') {
      this.ExamSubType = this.QN.slice();
      this.examSubType = 'كلية';
    }

    this.examSubType = this.ExamSubType[0];
  }

  onScoreLevelChange(event: any) {
    this.scoreLevel = event.target.value;
  }

  onGenderChange(event: any) {
    this.examGender = event.target.value;
  }

  onAreaChange(event: any) {
    this.area = event.target.value;

    this.Department = this.ado.filter(
      (f) => f.Area == this.area && f.year == this.year
    );

    this.DepartmentNoRepeat = [];
    this.Department.forEach((element: any) => {
      if (this.DepartmentNoRepeat.indexOf(element.Department) == -1) {
        this.DepartmentNoRepeat.push(element.Department);
      }
    });
    this.department = '0';
    this.office = '0';
  }

  onDepartmentChange(event: any) {
    this.department = event.target.value;
    this.Office = [];
    this.Office = this.ado.filter(
      (f) =>
        f.Area == this.area &&
        f.Department == this.department &&
        f.year == this.year
    );
    this.office = '0';
  }

  onOfficeChange(event: any) {
    this.office = event.target.value;
  }

  onAuthorityChange(event: any) {
    this.authority = event.target.value;
  }

  onTop10Change() {
    if (this.top10Check) {
      this.department = '0';
      this.office = '0';
      this.authority = '0';
    }
  }

  clearSearchForm() {
    this.txtSearch = '';
    this.examGender = 'M';
    this.examTypeFull = 'تحصيلي,علمي';
    this.examSubType = 'كلية';
    this.year = '1443';
    this.area = '0';
    this.department = '0';
    this.office = '0';
    this.scoreLevel = '0';
    this.authority = '0';
  }

  clearSearch() {
    this.cities = [];
    //Clear offcanvas
    this.showOffDeptcanvas = '';
    this.showOffcanvas = '';
    this.showOffOfficecanvas = '';
    //Clear schools, departments and offices from map
    this.marker.forEach((element: any) => {
      this.map.removeLayer(element);
    });

    this.markerDept.forEach((element: any) => {
      this.map.removeLayer(element);
    });

    this.markerOffice.forEach((element: any) => {
      this.map.removeLayer(element);
    });

    //Clear  schools, departments and offices array
    this.marker = [];
    this.markerDept = [];
    this.markerOffice = [];

    //Clear borders
    if (this.selectAreaGeom) {
      this.map.removeLayer(this.selectAreaGeom);
    }
  }

  addSchoolMarker(searchResult: SchoolList[]) {
    let dynamicIcon: any;
    //var markersCluster =  new L.MarkerClusterGroup();
    searchResult.forEach((element, i) => {
      if (element.Lang != undefined && element.Lut != undefined) {
        //Check color level
        if (Number(element.Average) >= 85) {
          dynamicIcon = this.darkGreenIcon;
        } else if (
          Number(element.Average) >= 75 &&
          Number(element.Average) < 85
        ) {
          dynamicIcon = this.midGreennIcon;
        } else if (
          Number(element.Average) >= 65 &&
          Number(element.Average) < 75
        ) {
          dynamicIcon = this.lightGreenIcon;
        } else if (
          Number(element.Average) >= 55 &&
          Number(element.Average) < 65
        ) {
          dynamicIcon = this.yellowIcon;
        } else if (
          Number(element.Average) >= 45 &&
          Number(element.Average) < 55
        ) {
          dynamicIcon = this.orangeIcon;
        } else if (
          Number(element.Average) >= 0 &&
          Number(element.Average) < 45
        ) {
          dynamicIcon = this.redIcon;
        }

        //Add marker
        const schoolItem = element;

        try {
          this.marker[i] = L.marker(
            [Number(element.Lang), Number(element.Lut)],
            {
              icon: dynamicIcon,
            }
          )
            .on('mouseover', function (ev) {
              ev.target.openPopup();
            })
            .on('mouseout', function (ev) {
              ev.target.closePopup();
            })
            .on('zoomed', (ev) => {
              ev.target.openPopup();
            })
            .on('click', (e) => {
              this.onClickSchoolMarker(schoolItem);
            })
            .addTo(this.map)
            .bindPopup('<h6>' + element.Name + '</h6>');
          //.openPopup();
          //markersCluster .addLayer(this.marker[i]);
        } catch { }
      }
    });

    //this.map.addLayer(markersCluster);
  }

  addDepartmentMarker(searchResult: DepartmentList[]) {
    let dynamicIcon: any;

    searchResult.forEach((element, i) => {
      if (element.Lang != undefined && element.Lut != undefined) {
        //Check color level
        if (Number(element.Average) >= 85) {
          dynamicIcon = this.darkGreenIconSquare;
        } else if (
          Number(element.Average) >= 75 &&
          Number(element.Average) < 85
        ) {
          dynamicIcon = this.midGreennIconSquare;
        } else if (
          Number(element.Average) >= 65 &&
          Number(element.Average) < 75
        ) {
          dynamicIcon = this.lightGreenIconSquare;
        } else if (
          Number(element.Average) >= 55 &&
          Number(element.Average) < 65
        ) {
          dynamicIcon = this.yellowIconSquare;
        } else if (
          Number(element.Average) >= 45 &&
          Number(element.Average) < 55
        ) {
          dynamicIcon = this.orangeIconSquare;
        } else if (
          Number(element.Average) >= 0 &&
          Number(element.Average) < 45
        ) {
          dynamicIcon = this.redIconSquare;
        }

        //Add marker
        const departmentItem = element;
        try {
          this.markerDept[i] = L.marker(
            [Number(element.Lang), Number(element.Lut)],
            {
              icon: dynamicIcon,
            }
          )
            .on('click', (e) => {
              this.onClickDepartmentMarker(departmentItem);
            })
            .addTo(this.map);
        } catch { }
      }
    });
  }

  addOfficeMarker(searchResult: OfficeList[]) {
    let dynamicIcon: any;

    searchResult.forEach((element, i) => {
      if (element.Lang != undefined && element.Lut != undefined) {
        //Check color level
        if (Number(element.Average) >= 85) {
          dynamicIcon = this.darkGreenIconTriangle;
        } else if (
          Number(element.Average) >= 75 &&
          Number(element.Average) < 85
        ) {
          dynamicIcon = this.midGreennIconTriangle;
        } else if (
          Number(element.Average) >= 65 &&
          Number(element.Average) < 75
        ) {
          dynamicIcon = this.lightGreenIconTriangle;
        } else if (
          Number(element.Average) >= 55 &&
          Number(element.Average) < 65
        ) {
          dynamicIcon = this.yellowIconTriangle;
        } else if (
          Number(element.Average) >= 45 &&
          Number(element.Average) < 55
        ) {
          dynamicIcon = this.orangeIconTriangle;
        } else if (
          Number(element.Average) >= 0 &&
          Number(element.Average) < 45
        ) {
          dynamicIcon = this.redIconTriangle;
        }

        //Add marker
        const officeItem = element;
        try {
          this.markerOffice[i] = L.marker(
            [Number(element.Lang), Number(element.Lut)],
            {
              icon: dynamicIcon,
            }
          )
            .on('click', (e) => {
              this.onClickOfficeMarker(officeItem);
            })
            .addTo(this.map);
        } catch { }
      }
    });
  }

  applySearch() {

    this._year = this.year;
    this._examTypeFull = this.examTypeFull;
    this._examSubType = this.examSubType;
    this._examGender = this.examGender;
    this._area = this.area;

    this.enableDetails = this.hasDetailes;
    //Check if all checkbox is unchecked : then but schools
    if (!this.schoolCheck && !this.departmentCheck && !this.officeCheck) {
      this.schoolCheck = !this.schoolCheck;
    }
    //Clear the search properties
    this.clearSearch();

    //Prepare search
    let typeSpecial: string = this.examTypeFull;
    this.examType = typeSpecial.split(',')[0];
    this.examSpecial = typeSpecial.split(',')[1];

    //School
    this.searchShoolResult = this.SchoolData.filter(
      (s: SchoolList) =>
        s.Year == this.year &&
        (s.Name.indexOf(this.txtSearch) != -1 ||
          s.MNO.indexOf(this.txtSearch) != -1) &&
        s.ExamType == this.examType &&
        s.ExamSpecial == this.examSpecial &&
        s.Gender == this.examGender &&
        (s.Area == this.area || this.area == '0') &&
        (s.Department == this.department || this.department == '0') &&
        (s.Office == this.office || this.office == '0') &&
        (s.Authority == this.authority || this.authority == '0')
    );

    if (this.top10Check) {
      this.searchShoolResult = this.searchShoolResult.sort((a, b) => {
        return Number(a.KsaRank) - Number(b.KsaRank);
      }).slice(0, 10);
    }

    if (this.scoreLevel != '0') {
      if (this.scoreLevel == 'A') {
        this.searchShoolResult = this.searchShoolResult.filter(
          (m) => Number(m.Average) >= 85
        );
      } else if (this.scoreLevel == 'B') {
        this.searchShoolResult = this.searchShoolResult.filter(
          (m) => Number(m.Average) >= 75 && Number(m.Average) < 85
        );
      } else if (this.scoreLevel == 'C') {
        this.searchShoolResult = this.searchShoolResult.filter(
          (m) => Number(m.Average) >= 65 && Number(m.Average) < 75
        );
      } else if (this.scoreLevel == 'D') {
        this.searchShoolResult = this.searchShoolResult.filter(
          (m) => Number(m.Average) >= 55 && Number(m.Average) < 65
        );
      } else if (this.scoreLevel == 'E') {
        this.searchShoolResult = this.searchShoolResult.filter(
          (m) => Number(m.Average) >= 45 && Number(m.Average) < 55
        );
      } else {
        this.searchShoolResult = this.searchShoolResult.filter(
          (m) => Number(m.Average) < 45
        );
      }
    }
    if (this.schoolCheck) {
      //Add marker to map
      this.addSchoolMarker(this.searchShoolResult);
    }
    this.searchShoolShowResult = this.searchShoolResult;
    //Order list
    this.searchShoolShowResult.sort((a, b) => {
      return Number(a.KsaRank) - Number(b.KsaRank);
    });

    //Department
    console.log(this.deptData);
    let department: DepartmentList[] = this.deptData.filter(
      (s) =>
        s.Year == this.year &&
        s.ExamType == this.examType &&
        s.ExamSpecial == this.examSpecial &&
        s.ExamGender == this.examGender &&
        (s.Area == this.area || this.area == '0') &&
        (s.EducationDepartment == this.department || this.department == '0')
    );

    console.log(department);
    if (this.scoreLevel != '0') {
      if (this.scoreLevel == 'A') {
        department = department.filter((m) => Number(m.Average) >= 85);
      } else if (this.scoreLevel == 'B') {
        department = department.filter(
          (m) => Number(m.Average) >= 75 && Number(m.Average) < 85
        );
      } else if (this.scoreLevel == 'C') {
        department = department.filter(
          (m) => Number(m.Average) >= 65 && Number(m.Average) < 75
        );
      } else if (this.scoreLevel == 'D') {
        department = department.filter(
          (m) => Number(m.Average) >= 55 && Number(m.Average) < 65
        );
      } else if (this.scoreLevel == 'E') {
        department = department.filter(
          (m) => Number(m.Average) >= 45 && Number(m.Average) < 55
        );
      } else {
        department = department.filter((m) => Number(m.Average) < 45);
      }
    }

    this.searchDepartmentResult = department;
    if (this.departmentCheck) {
      //Add marker to map
      this.addDepartmentMarker(department);
    }

    //Order list
    this.searchDepartmentResult.sort((a, b) => {
      return Number(a.Rank) - Number(b.Rank);
    });

    //Office
    let office: OfficeList[] = this.officeData.filter(
      (s) =>
        s.Year == this.year &&
        s.ExamType == this.examType &&
        s.ExamSpecial == this.examSpecial &&
        s.ExamGender == this.examGender &&
        (s.Area == this.area || this.area == '0') &&
        (s.EducationDepartment == this.department || this.department == '0') &&
        (s.OfficeName == this.office || this.office == '0')
    );

    if (this.scoreLevel != '0') {
      if (this.scoreLevel == 'A') {
        office = office.filter((m) => Number(m.Average) >= 85);
      } else if (this.scoreLevel == 'B') {
        office = office.filter(
          (m) => Number(m.Average) >= 75 && Number(m.Average) < 85
        );
      } else if (this.scoreLevel == 'C') {
        office = office.filter(
          (m) => Number(m.Average) >= 65 && Number(m.Average) < 75
        );
      } else if (this.scoreLevel == 'D') {
        office = office.filter(
          (m) => Number(m.Average) >= 55 && Number(m.Average) < 65
        );
      } else if (this.scoreLevel == 'E') {
        office = office.filter(
          (m) => Number(m.Average) >= 45 && Number(m.Average) < 55
        );
      } else {
        office = office.filter((m) => Number(m.Average) < 45);
      }
    }

    this.searchOfficeResult = office;
    if (this.officeCheck) {
      //Add marker to map
      this.addOfficeMarker(office);
    }

    this.searchOfficeResult.sort((a, b) => {
      return Number(a.KsaRank) - Number(b.KsaRank);
    });
    //Focus on area
    if (this.area != '0') {
      //area color
      let color = '#524293';

      let areaAvg = this.searchShoolResult[0]?.AreaAvg;
      if (Number(areaAvg) >= 85) {
        color = '#004c00';
      } else if (Number(areaAvg) >= 75 && Number(areaAvg) < 85) {
        color = '#53af23';
      } else if (Number(areaAvg) >= 65 && Number(areaAvg) < 75) {
        color = '#aed800';
      } else if (Number(areaAvg) >= 55 && Number(areaAvg) < 65) {
        color = '#ffe44d';
      } else if (Number(areaAvg) >= 45 && Number(areaAvg) < 55) {
        color = '#f68a4c';
      } else if (Number(areaAvg) >= 0 && Number(areaAvg) < 45) {
        color = '#960200';
      }

      if (this.top10Check) {
        color = '#524293';
      }

      //
      var pos = position.filter((m) => m.City == this.area)[0];
      let lang = pos?.lang;
      let lut = pos?.lut;
      this.map.setView([lang, lut]);

      let geo: any = _area.filter((m) => m.SearchName == this.area)[0]?.Geom;
      let geom: any;

      if (geo == 'riyadh') {
        this.httpService
          .get('../../assets/json/area-position/riyadh.json')
          .subscribe(
            (data) => {
              this.riyadh = data;
              geom = this.riyadh;
              this.selectAreaGeom = L.geoJSON(geom, {
                style: this.polystyle(color),
              }).addTo(this.map);
              let bounds = this.selectAreaGeom.getBounds();
              // Fit the map to the polygon bounds
              this.map.fitBounds(bounds);
              // Or center on the polygon
              var center = bounds.getCenter();
              this.map.panTo(center);
            },
            (err: HttpErrorResponse) => { }
          );
      } else if (geo == 'baha') {
        this.httpService
          .get('../../assets/json/area-position/baha.json')
          .subscribe(
            (data) => {
              this.baha = data;
              geom = this.baha;
              this.selectAreaGeom = L.geoJSON(geom, {
                style: this.polystyle(color),
              }).addTo(this.map);
              let bounds = this.selectAreaGeom.getBounds();
              // Fit the map to the polygon bounds
              this.map.fitBounds(bounds);
              // Or center on the polygon
              var center = bounds.getCenter();
              this.map.panTo(center);
            },
            (err: HttpErrorResponse) => {
              console.log(err.message);
            }
          );
      } else if (geo == 'makkah') {
        this.httpService
          .get('../../assets/json/area-position/makkah.json')
          .subscribe(
            (data) => {
              this.makkah = data;
              geom = this.makkah;
              this.selectAreaGeom = L.geoJSON(geom, {
                style: this.polystyle(color),
              }).addTo(this.map);
              let bounds = this.selectAreaGeom.getBounds();
              // Fit the map to the polygon bounds
              this.map.fitBounds(bounds);
              // Or center on the polygon
              var center = bounds.getCenter();
              this.map.panTo(center);
            },
            (err: HttpErrorResponse) => {
              console.log(err.message);
            }
          );
      } else if (geo == 'tabuk') {
        this.httpService
          .get('../../assets/json/area-position/tabuk.json')
          .subscribe(
            (data) => {
              this.tabuk = data;
              geom = this.tabuk;
              this.selectAreaGeom = L.geoJSON(geom, {
                style: this.polystyle(color),
              }).addTo(this.map);
              let bounds = this.selectAreaGeom.getBounds();
              // Fit the map to the polygon bounds
              this.map.fitBounds(bounds);
              // Or center on the polygon
              var center = bounds.getCenter();
              this.map.panTo(center);
            },
            (err: HttpErrorResponse) => {
              console.log(err.message);
            }
          );
      } else if (geo == 'sharkiah') {
        this.httpService
          .get('../../assets/json/area-position/sharkiah.json')
          .subscribe(
            (data) => {
              this.sharkiah = data;
              geom = this.sharkiah;
              this.selectAreaGeom = L.geoJSON(geom, {
                style: this.polystyle(color),
              }).addTo(this.map);
              let bounds = this.selectAreaGeom.getBounds();
              // Fit the map to the polygon bounds
              this.map.fitBounds(bounds);
              // Or center on the polygon
              var center = bounds.getCenter();
              this.map.panTo(center);
            },
            (err: HttpErrorResponse) => {
              console.log(err.message);
            }
          );
      } else if (geo == 'asir') {
        this.httpService
          .get('../../assets/json/area-position/asir.json')
          .subscribe(
            (data) => {
              this.asir = data;
              geom = this.asir;
              this.selectAreaGeom = L.geoJSON(geom, {
                style: this.polystyle(color),
              }).addTo(this.map);
              let bounds = this.selectAreaGeom.getBounds();
              // Fit the map to the polygon bounds
              this.map.fitBounds(bounds);
              // Or center on the polygon
              var center = bounds.getCenter();
              this.map.panTo(center);
            },
            (err: HttpErrorResponse) => {
              console.log(err.message);
            }
          );
      } else if (geo == 'hail') {
        this.httpService
          .get('../../assets/json/area-position/hail.json')
          .subscribe(
            (data) => {
              this.hail = data;
              geom = this.hail;
              this.selectAreaGeom = L.geoJSON(geom, {
                style: this.polystyle(color),
              }).addTo(this.map);
              let bounds = this.selectAreaGeom.getBounds();
              // Fit the map to the polygon bounds
              this.map.fitBounds(bounds);
              // Or center on the polygon
              var center = bounds.getCenter();
              this.map.panTo(center);
            },
            (err: HttpErrorResponse) => {
              console.log(err.message);
            }
          );
      } else if (geo == 'jawf') {
        this.httpService
          .get('../../assets/json/area-position/jawf.json')
          .subscribe(
            (data) => {
              this.jawf = data;
              geom = this.jawf;
              this.selectAreaGeom = L.geoJSON(geom, {
                style: this.polystyle(color),
              }).addTo(this.map);
              let bounds = this.selectAreaGeom.getBounds();
              // Fit the map to the polygon bounds
              this.map.fitBounds(bounds);
              // Or center on the polygon
              var center = bounds.getCenter();
              this.map.panTo(center);
            },
            (err: HttpErrorResponse) => {
              console.log(err.message);
            }
          );
      } else if (geo == 'jazan') {
        this.httpService
          .get('../../assets/json/area-position/jazan.json')
          .subscribe(
            (data) => {
              this.jazan = data;
              geom = this.jazan;
              this.selectAreaGeom = L.geoJSON(geom, {
                style: this.polystyle(color),
              }).addTo(this.map);
              let bounds = this.selectAreaGeom.getBounds();
              // Fit the map to the polygon bounds
              this.map.fitBounds(bounds);
              // Or center on the polygon
              var center = bounds.getCenter();
              this.map.panTo(center);
            },
            (err: HttpErrorResponse) => {
              console.log(err.message);
            }
          );
      } else if (geo == 'medinah') {
        this.httpService
          .get('../../assets/json/area-position/medinah.json')
          .subscribe(
            (data) => {
              this.medinah = data;
              geom = this.medinah;
              this.selectAreaGeom = L.geoJSON(geom, {
                style: this.polystyle(color),
              }).addTo(this.map);
              let bounds = this.selectAreaGeom.getBounds();
              // Fit the map to the polygon bounds
              this.map.fitBounds(bounds);
              // Or center on the polygon
              var center = bounds.getCenter();
              this.map.panTo(center);
            },
            (err: HttpErrorResponse) => {
              console.log(err.message);
            }
          );
      } else if (geo == 'najran') {
        this.httpService
          .get('../../assets/json/area-position/najran.json')
          .subscribe(
            (data) => {
              this.najran = data;
              geom = this.najran;
              this.selectAreaGeom = L.geoJSON(geom, {
                style: this.polystyle(color),
              }).addTo(this.map);
              let bounds = this.selectAreaGeom.getBounds();
              // Fit the map to the polygon bounds
              this.map.fitBounds(bounds);
              // Or center on the polygon
              var center = bounds.getCenter();
              this.map.panTo(center);
            },
            (err: HttpErrorResponse) => {
              console.log(err.message);
            }
          );
      } else if (geo == 'qassim') {
        this.httpService
          .get('../../assets/json/area-position/qassim.json')
          .subscribe(
            (data) => {
              this.qassim = data;
              geom = this.qassim;
              this.selectAreaGeom = L.geoJSON(geom, {
                style: this.polystyle(color),
              }).addTo(this.map);
              let bounds = this.selectAreaGeom.getBounds();
              // Fit the map to the polygon bounds
              this.map.fitBounds(bounds);
              // Or center on the polygon
              var center = bounds.getCenter();
              this.map.panTo(center);
            },
            (err: HttpErrorResponse) => {
              console.log(err.message);
            }
          );
      } else if (geo == 'northborder') {
        this.httpService
          .get('../../assets/json/area-position/northborder.json')
          .subscribe(
            (data) => {
              this.northborder = data;
              geom = this.northborder;
              this.selectAreaGeom = L.geoJSON(geom, {
                style: this.polystyle(color),
              }).addTo(this.map);
              let bounds = this.selectAreaGeom.getBounds();
              // Fit the map to the polygon bounds
              this.map.fitBounds(bounds);
              // Or center on the polygon
              var center = bounds.getCenter();
              this.map.panTo(center);
            },
            (err: HttpErrorResponse) => {
              console.log(err.message);
            }
          );
      }
    } else {
      this.map.setView([24.774265, 46.738586]);
      this.selectAreaGeom = L.geoJSON(this.geom, {
        style: this.polystyleKsa,
      }).addTo(this.map);
      // let bounds = this.selectAreaGeom.getBounds();
      // this.map.fitBounds(bounds);
    }
  }

  polystyle(color: string) {
    return {
      fillColor: color,
      weight: 2,
      opacity: 1,
      color: color, //Outline color
      fillOpacity: 0.09,
    };
  }

  polystyleKsa() {
    return {
      fillColor: '#524293',
      weight: 2,
      opacity: 0.0,
      color: '#524293', //Outline color
      fillOpacity: 0,
    };
  }

  onClickSchoolMarker(item: SchoolList) {
    this.showOffcanvas = '';
    if (this.chart) {
      this.chart.destroy();
    }
    this.showOffcanvas = 'show';
    this.showOffDeptcanvas = '';
    this.showOffOfficecanvas = '';
    this.selectedSchool = item;

    if (Number(item.Average) >= 85) {
      this.dynamicClass = 'bg-indicator-Darkgreen';
    } else if (Number(item.Average) >= 75 && Number(item.Average) < 85) {
      this.dynamicClass = 'bg-indicator-Midgreen';
    } else if (Number(item.Average) >= 65 && Number(item.Average) < 75) {
      this.dynamicClass = 'bg-indicator-Lightgreen';
    } else if (Number(item.Average) >= 55 && Number(item.Average) < 65) {
      this.dynamicClass = 'bg-indicator-yellow';
    } else if (Number(item.Average) >= 45 && Number(item.Average) < 55) {
      this.dynamicClass = 'bg-indicator-orange';
    } else if (Number(item.Average) >= 0 && Number(item.Average) < 45) {
      this.dynamicClass = 'bg-indicator-red';
    }
    this.queryString = `شاهد ترتيب مدرسة ( ${item.Name} /${item.Department} ) في مؤشر ترتيب ${this.examType} على مستوى-مدارس المملكة ( ${item.KsaRank} )-مدارس ادارة التعليم ( ${item.DeptRank} )-مدارس المكتب ( ${item.OffficeRak} )ومتوسط الأداء الكلي ( ${item.Average} ) لعام ${this.year} https://tarteeb.edu.sa/`;

    // if (this.year == '1443') {
    //   if (item.Avg41 != '0' && item.Avg42 != '0') {
    //     this.addTrenChart(item);
    //   }
    // }
    if (item.Avg41 !== 'NULL' && item.Avg42 !== 'NULL') {
      if (!Number.isNaN(item.Avg41) && !Number.isNaN(item.Avg42)) {
        if (
          item.Avg41 != undefined &&
          item.Avg42 != undefined &&
          item.Avg41 != '0' &&
          item.Avg42 != '0'
        ) {
          this.addTrenChart(item);
        }
      }
    }
  }

  getColor(item: string) {
    let classColor = 'bg-indicator-Darkgreen';
    if (Number(item) >= 85) {
      classColor = 'bg-indicator-Darkgreen';
    } else if (Number(item) >= 75 && Number(item) < 85) {
      classColor = 'bg-indicator-Midgreen';
    } else if (Number(item) >= 65 && Number(item) < 75) {
      classColor = 'bg-indicator-Lightgreen';
    } else if (Number(item) >= 55 && Number(item) < 65) {
      classColor = 'bg-indicator-yellow';
    } else if (Number(item) >= 45 && Number(item) < 55) {
      classColor = 'bg-indicator-orange';
    } else if (Number(item) >= 0 && Number(item) < 45) {
      classColor = 'bg-indicator-red';
    }

    return classColor;
  }

  getColorCode(average: string) {
    let color: any;
    if (Number(average) >= 85) {
      color = '#004c00';
    } else if (Number(average) >= 75 && Number(average) < 85) {
      color = '#53af23';
    } else if (Number(average) >= 65 && Number(average) < 75) {
      color = '#aed800';
    } else if (Number(average) >= 55 && Number(average) < 65) {
      color = '#ffe44d';
    } else if (Number(average) >= 45 && Number(average) < 55) {
      color = '#f68a4c';
    } else if (Number(average) >= 0 && Number(average) < 45) {
      color = '#960200';
    } else {
      color = '#524293';
    }

    return color;
  }

  addTrenChart(item: SchoolList) {
    var options = {
      series: [
        {
          name: item.Name,
          data: [
            Number(item.Avg41).toFixed(1),
            Number(item.Avg42).toFixed(1),
            Number(item.Average).toFixed(1),
          ],
        },
      ],
      chart: {
        height: 150,
        type: 'line',
        dropShadow: {
          enabled: false,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ['#4C3D8F', '#4C3D8F'],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      grid: {
        show: false,
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: [Number(item.Year) - 2, Number(item.Year) - 1, item.Year],
      },
      yaxis: {
        show: false,
        title: {
          text: '',
        },
        min: 5,
        max: 100,
      },
    };

    this.chart = new ApexCharts(document.querySelector('#chart'), options);
    this.chart.render();
  }

  onClickDepartmentMarker(item: DepartmentList) {
    this.showOffDeptcanvas = 'show';
    this.showOffcanvas = '';
    this.showOffOfficecanvas = '';
    this.selectedDepartment = item;

    if (Number(item.Average) >= 85) {
      this.dynamicClass = 'bg-indicator-Darkgreen';
    } else if (Number(item.Average) >= 75 && Number(item.Average) < 85) {
      this.dynamicClass = 'bg-indicator-Midgreen';
    } else if (Number(item.Average) >= 65 && Number(item.Average) < 75) {
      this.dynamicClass = 'bg-indicator-Lightgreen';
    } else if (Number(item.Average) >= 55 && Number(item.Average) < 65) {
      this.dynamicClass = 'bg-indicator-yellow';
    } else if (Number(item.Average) >= 45 && Number(item.Average) < 55) {
      this.dynamicClass = 'bg-indicator-orange';
    } else if (Number(item.Average) >= 0 && Number(item.Average) < 45) {
      this.dynamicClass = 'bg-indicator-red';
    }
  }

  onClickOfficeMarker(item: OfficeList) {
    this.showOffDeptcanvas = '';
    this.showOffcanvas = '';
    this.showOffOfficecanvas = 'show';

    this.selectedOffice = item;

    if (Number(item.Average) >= 85) {
      this.dynamicClass = 'bg-indicator-Darkgreen';
    } else if (Number(item.Average) >= 75 && Number(item.Average) < 85) {
      this.dynamicClass = 'bg-indicator-Midgreen';
    } else if (Number(item.Average) >= 65 && Number(item.Average) < 75) {
      this.dynamicClass = 'bg-indicator-Lightgreen';
    } else if (Number(item.Average) >= 55 && Number(item.Average) < 65) {
      this.dynamicClass = 'bg-indicator-yellow';
    } else if (Number(item.Average) >= 45 && Number(item.Average) < 55) {
      this.dynamicClass = 'bg-indicator-orange';
    } else if (Number(item.Average) >= 0 && Number(item.Average) < 45) {
      this.dynamicClass = 'bg-indicator-red';
    }
  }

  showChart() {
    if (this.chart) {
      this.chart.destroy();
    }

    this.showComparedSchoolRow = false;
    var avg: string[] = [];
    var names: string[] = [];
    this.comparedSchool.forEach((element) => {
      avg.push(element.Average);
      names.push(element.Name);
    });

    var ksaAvg = ksaAverage.filter(
      (m) =>
        m['العام الدراسي'] == this.year &&
        m['تخصص الاختبار'] == this.examSpecial &&
        m['جنس المدرسة'] == this.examGender &&
        m['نوع الاختبار'] == this.examType
    );

    let av: string | undefined = '0';
    if (ksaAvg.length > 0) {
      av = ksaAvg[0].ksaAvg;
    }

    var options = this.showChartImage(avg, names, av);
    this.chart = new ApexCharts(
      document.querySelector('#chartCompare'),
      options
    );
    this.chart.render();
  }

  showChartImage(avg: string[], names: string[], av: string | undefined) {
    var options = {
      series: [
        {
          name: 'درجة المدرسة',
          //data: [83, 76, 86, 79]
          data: avg,
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      colors: ['#4B3D8F'],
      annotations: {
        xaxis: [
          {
            x: av,
            borderColor: '#00E396',
            label: {
              borderColor: '#00E396',
              style: {
                color: '#000',
                background: '#00E396',
              },
              text: 'متوسط الدرجة على مستوى المملكة : ' + av,
            },
          },
        ],
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: names,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        reversed: true,
        axisTicks: {
          show: true,
        },
      },
    };

    return options;
  }

  hideChart() {
    this.comparedSchool = [];
    this.showCompare = '';
    this.showComparedSchoolRow = true;

    this.chart.destroy();
  }

  setCookiefooter() {
    this.cookieService.set('AgreeFotter', 'true', 365);

  }

  getCookiefooter() {
    return this.cookieService.get('AgreeFotter') ? true : false;
  }

  agree() {
    this.setCookiefooter();
    this.isAgree = true;
  }
}
