<section id="statistics" class="py-0" style="background-color: #F9F9F9;">
    <div class="container py-5">
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-xl-6 col-12">
                <div class="d-flex flex-xl-row flex-column justify-content-center align-items-center p-0 mb-sm-5 mb-4 mt-3">
                    <div class="text-center me-xl-4 mb-xl-0 mb-3">
                        <img src="assets/media/icon/statistics-icon.svg" alt="statistics-icon" class="img-fluid"
                            width="60">
                    </div>
                    <div class="text-xl-start text-center">
                        <h4 class="fw-semibold mb-2">إحصائيات المؤشر</h4>
                        <p class="text-primary fs-lg mb-0">
                            تم اعتماد إحصائيات المؤشر استنادًا على نتائج عام {{defaultYear}}هـ
                        </p>
                    </div>
                </div>

            </div>
        </div>
        <div class="row row-cols-xl-4 row-cols-2 g-3 g-xl-4 mt-0 mb-5">
            <div class="col">
                <div class="card flex-column align-items-center border-0 bg-primary h-100">
                    <div class="card-body px-2 py-5 d-flex flex-column justify-content-center">
                        <h2 class="fw-semibold mb-1 text-center text-white">{{schoolsTotalStr}}</h2>
                        <p class="fs-lg mb-0 text-white text-center">
                            عدد مدارس المرحلة الثانوية
                        </p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card flex-column align-items-center border-0 bg-info h-100" style="background-color: #3FA0D9!important;">
                    <div class="card-body px-2 py-5 d-flex flex-column justify-content-center">
                        <h2 class="fw-semibold mb-1 text-center text-white">{{yearsOfScale}} سنوات</h2>
                        <p class="fs-lg mb-0 text-white text-center">
                            لعمل المؤشر
                        </p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card flex-column align-items-center border-0 bg-warning h-100" style="background-color: #F7B375!important;">
                    <div class="card-body px-2 py-5 d-flex flex-column justify-content-center">
                        <h2 class="fw-semibold mb-1 text-center text-white" >{{aPTStudentTotalStr}}</h2>
                        <p class="fs-lg mb-0 text-white text-center">
                            طالبًا وطالبة في اختبار القدرات
                        </p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card flex-column align-items-center border-0 bg-primary h-100" style="background-color: #6C5DA6!important;">
                    <div class="card-body px-2 py-5 d-flex flex-column justify-content-center">
                        <h2 class="fw-semibold mb-1 text-center text-white">{{aCTStudentTotalStr}}</h2>
                        <p class="fs-lg mb-0 text-white text-center">
                        طالبًا وطالبة في اختبار التحصيل الدراسي
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>