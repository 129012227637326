import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
// import ado from '../../assets/json/ado.json';
// //import schools from '../../assets/json/data.json';
// import { SchoolList } from '../model/SchoolList';
// import _area from '../../assets/json/area.json'
// import deptData from '../../assets/json/department.json';
// import officeData from '../../assets/json/office.json';
// import { DepartmentList, OfficeList } from '../model/DepartmentList';
import ApexCharts from 'apexcharts'
// import ksaAverage from '../../assets/json/ksa-average.json';

@Component({
  selector: 'app-pointer-entity',
  templateUrl: './pointer-entity.component.html',
  styleUrls: ['./pointer-entity.component.css']
})
export class PointerEntityComponent  {
  // SchoolData: SchoolList[] = [];
  // pointerDepartment: string[] = [];
  // pointerOffice: string[] = [];
  // Department: any;
  // Office: any;
  // ksaCheck = false;
  // schoolCheck = true;
  // officeCheck = true;
  // departmentCheck = true;
  // DepartmentNoRepeat: string[] = [];
  // searchShoolResult: SchoolList[] = [];
  // searchShoolShowResult: SchoolList[] = [];
  // searchOfficeResult: OfficeList[] = [];
  // searchDepartmentResult: DepartmentList[] = [];
  // year: string = "1443";
  // examType: string = 'تحصيلي';
  // examTypeFull: string = 'تحصيلي,علمي';
  // examSpecial: string = 'علمي';
  // examGender: string = 'M';
  // scoreLevel: string = '0';
  // area: string = '0';
  // department: string = '0';
  // office: string = '0';
  // authority: string = '0';
  // marker: any = [];
  // markerDept: any = [];
  // markerOffice: any = [];
  // cities: any = [];
  // showOffcanvas = '';
  // showOffDeptcanvas = '';
  // showOffOfficecanvas = '';
  // dynamicClass = '';
  // selectedSchool!: SchoolList;
  // comparedSchool: SchoolList[] = [];
  // selectedDepartment!: DepartmentList;
  // selectedOffice!: OfficeList;
  // selectAreaGeom: any;
  // txtSearch = '';
  // recordSeed = 0;
  // recordDeptSeed = 0;
  // recordOddiceSeed = 0;
  // recordShow = 15;
  // maxComparedNo = 4;
  // showCompare = '';
  // showComparedSchoolRow = true;
  // minimize = '';
  // txtSearchTable = '';
  // chart: any;
  // tabName: string ='s';
  // queryString = '';
  // chartCard: any;
  // @ViewChild("graphDiv")

  // graphDiv!: ElementRef;
  constructor(private renderer: Renderer2) {
    //this.SchoolData = schools as SchoolList[];
   // this.applySearch();
  }

  // ngOnInit(): void {
  // }


  // //Cusotm
  // addToCmpare(item: SchoolList, addRemove: number) {
  //   this.showComparedSchoolRow = true;

  //   if (addRemove == 1) {
  //     if (this.comparedSchool.length < this.maxComparedNo) {
  //       if (this.comparedSchool.indexOf(item) == -1)
  //         this.comparedSchool.push(item);
  //     }
  //   } else if (addRemove == 0) {
  //     const index = this.comparedSchool.indexOf(item, 0);
  //     if (index > -1) {
  //       this.comparedSchool.splice(index, 1);
  //     }
  //   }

  //   this.showCompare = this.comparedSchool.length > 0 ? 'show' : '';
  // }

  // search() {
  //   this.searchShoolShowResult = this.searchShoolResult;
  //   this.searchShoolShowResult = this.searchShoolShowResult.filter(x =>
  //     x.Name?.toUpperCase().includes(this.txtSearchTable.toUpperCase()) ||
  //     x.Department?.toUpperCase().includes(this.txtSearchTable.toUpperCase()) ||
  //     x.Office?.toUpperCase().includes(this.txtSearchTable.toUpperCase())
  //   );
  // }

  // showChart() {
  //   if (this.chart) {
  //     this.chart.destroy();
  //   }

  //   this.showComparedSchoolRow = false;
  //   var avg: string[] = [];
  //   var names: string[] = [];
  //   this.comparedSchool.forEach(element => {
  //     avg.push(element.Average);
  //     names.push(element.Name);
  //   });

  //   var ksaAvg = ksaAverage.filter(m => m['العام الدراسي'] == this.year
  //     && m['تخصص الاختبار'] == this.examSpecial
  //     && m['جنس المدرسة'] == this.examGender
  //     && m['نوع الاختبار'] == this.examType);

  //   let av: string | undefined = '0';
  //   if (ksaAvg.length > 0) {
  //     av = ksaAvg[0].ksaAvg;
  //   }
  //   console.log(av);
  //   var options = this.showChartImage(avg, names, av);
  //   this.chart = new ApexCharts(document.querySelector("#chart"), options);
  //   this.chart.render();
  // }

  // showChartImage(avg: string[], names: string[], av: string | undefined) {
  //   var options = {
  //     series: [{
  //       name: 'درجة المدرسة',
  //       //data: [83, 76, 86, 79]
  //       data: avg
  //     }],
  //     chart: {
  //       type: 'bar',
  //       height: 350,
  //       toolbar: {
  //         show: false
  //       }
  //     },
  //     colors: ['#4B3D8F'],
  //     annotations: {
  //       xaxis: [{
  //         x: av,
  //         borderColor: '#00E396',
  //         label: {
  //           borderColor: '#00E396',
  //           style: {
  //             color: '#000',
  //             background: '#00E396',
  //           },
  //           text: 'متوسط الدرجة على مستوى المملكة : ' + av,
  //         }
  //       }],
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: true,
  //       }
  //     },
  //     dataLabels: {
  //       enabled: true
  //     },
  //     xaxis: {
  //       categories: names
  //     },
  //     grid: {
  //       xaxis: {
  //         lines: {
  //           show: true
  //         }
  //       }
  //     },
  //     yaxis: {
  //       reversed: true,
  //       axisTicks: {
  //         show: true
  //       }
  //     }
  //   };

  //   return options;
  // }

  // hideChart() {
  //   this.comparedSchool = [];
  //   this.showCompare = '';
  //   this.showComparedSchoolRow = true;

  //   this.chart.destroy();
  // }

  // onYearChange(event: any) {
  //   this.year = event.target.value;
  //   this.area = '0';
  //   this.department = '0';
  //   this.office = '0';
  // }

  // onExamTypeChange(event: any) {
  //   let typeSpecial: string = event.target.value;
  //   this.examType = typeSpecial.split(',')[0];
  //   this.examSpecial = typeSpecial.split(',')[1];
  // }

  // onScoreLevelChange(event: any) {
  //   this.scoreLevel = event.target.value;
  // }

  // onGenderChange(event: any) {
  //   this.examGender = event.target.value;
  // }

  // onAreaChange(event: any) {
  //   this.area = event.target.value;

  //   this.Department = ado.filter(f => f.Area == this.area && f.year == this.year);
  //   this.DepartmentNoRepeat = [];
  //   this.Department.forEach((element: any) => {
  //     if (this.DepartmentNoRepeat.indexOf(element.Department) == -1) {
  //       this.DepartmentNoRepeat.push(element.Department);
  //     }
  //   });
  //   this.department = '0';
  //   this.office = '0';
  // }

  // onDepartmentChange(event: any) {
  //   this.department = event.target.value;
  //   this.Office = [];
  //   this.Office = ado.filter(f => f.Area == this.area && f.Department == this.department && f.year == this.year);
  //   this.office = '0';
  // }

  // onOfficeChange(event: any) {
  //   this.office = event.target.value;
  // }

  // onAuthorityChange(event: any) {
  //   this.authority = event.target.value;
  // }

  // clearSearchForm() {
  //   this.txtSearch = '';
  //   this.examGender = 'M';
  //   this.examTypeFull = 'تحصيلي,علمي';
  //   this.year = '1443'
  //   this.area = '0';
  //   this.department = '0';
  //   this.office = '0';
  //   this.authority = '0';
  // }

  // show(item: SchoolList) {
  //   this.showOffcanvas = '';

  //   if (this.chartCard) {
  //     this.chartCard.destroy();
  //   }
  //   this.showOffcanvas = 'show';
  //   this.selectedSchool = item;

  //   if (Number(item.Average) >= 85) {
  //     this.dynamicClass = 'bg-indicator-Darkgreen';
  //   } else if (Number(item.Average) >= 75 && Number(item.Average) < 85) {
  //     this.dynamicClass = 'bg-indicator-Midgreen';
  //   } else if (Number(item.Average) >= 65 && Number(item.Average) < 75) {
  //     this.dynamicClass = 'bg-indicator-Lightgreen';
  //   } else if (Number(item.Average) >= 55 && Number(item.Average) < 65) {
  //     this.dynamicClass = 'bg-indicator-yellow';
  //   } else if (Number(item.Average) >= 45 && Number(item.Average) < 55) {
  //     this.dynamicClass = 'bg-indicator-orange';
  //   } else if (Number(item.Average) >= 0 && Number(item.Average) < 45) {
  //     this.dynamicClass = 'bg-indicator-red';
  //   }
  //   this.queryString = `شاهد ترتيب مدرسة ( ${item.Name} /${item.Department} ) في مؤشر ترتيب تحصيلي على مستوى-مدارس المملكة ( ${item.KsaRank} )-مدارس ادارة التعليم ( ${item.DeptRank} )-مدارس المكتب ( ${item.OffficeRak} )ومتوسط الأداء الكلي ( ${item.Average} ) لعام ${this.year}`;

  //   if (this.year == '1443') {
  //     if (item.Avg41 != '0' && item.Avg42 != '0') {
  //       this.addTrenChart(item);
  //     }
  //   }
  // }

  // addTrenChart(item: SchoolList) {
  //   var options = {
  //     series: [
  //       {
  //         name: item.Name,
  //         data: [Number(item.Avg41).toFixed(1), Number(item.Avg42).toFixed(1), Number(item.Average).toFixed(1)]
  //       }
  //     ],
  //     chart: {
  //       height: 150,
  //       type: 'line',
  //       dropShadow: {
  //         enabled: false,
  //         color: '#000',
  //         top: 18,
  //         left: 7,
  //         blur: 10,
  //         opacity: 0.2
  //       },
  //       toolbar: {
  //         show: false
  //       }
  //     },
  //     colors: ['#4C3D8F', '#4C3D8F'],
  //     dataLabels: {
  //       enabled: true,
  //     },
  //     stroke: {
  //       curve: 'smooth'
  //     },
  //     grid: {
  //       show: false,
  //       borderColor: '#e7e7e7',
  //       row: {
  //         colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
  //         opacity: 0.5
  //       },
  //     },
  //     markers: {
  //       size: 1
  //     },
  //     xaxis: {
  //       categories: ['1441', '1442', '1443'],
  //     },
  //     yaxis: {
  //       show: false,
  //       title: {
  //         text: ''
  //       },
  //       min: 5,
  //       max: 100
  //     }
  //   };

  //   this.chart = new ApexCharts(document.querySelector("#chartCard"), options);
  //   this.chart.render();
  // }


  // applySearch() {
  //   this.recordSeed = 0;
  //   this.searchShoolResult = [];
  //   this.searchDepartmentResult = [];
  //   this.searchOfficeResult = [];
  //   if (this.schoolCheck) {
  //     this.searchShoolResult = this.SchoolData
  //       .filter((s: SchoolList) => s.Year == this.year &&
  //         (s.Name.indexOf(this.txtSearch) != -1 || s.MNO.indexOf(this.txtSearch) != -1) &&
  //         s.ExamType == this.examType &&
  //         s.ExamSpecial == this.examSpecial &&
  //         s.Gender == this.examGender &&
  //         (s.Area == this.area || this.area == '0') &&
  //         (s.Department == this.department || this.department == '0') &&
  //         (s.Office == this.office || this.office == '0') &&
  //         (s.Authority == this.authority || this.authority == '0'));

  //     if (this.scoreLevel != '0') {
  //       if (this.scoreLevel == 'A') {
  //         this.searchShoolResult = this.searchShoolResult.filter(m => Number(m.Average) >= 85);
  //       } else if (this.scoreLevel == 'B') {
  //         this.searchShoolResult = this.searchShoolResult.filter(m => Number(m.Average) >= 75 && Number(m.Average) < 85);
  //       } else if (this.scoreLevel == 'C') {
  //         this.searchShoolResult = this.searchShoolResult.filter(m => Number(m.Average) >= 65 && Number(m.Average) < 75);
  //       } else if (this.scoreLevel == 'D') {
  //         this.searchShoolResult = this.searchShoolResult.filter(m => Number(m.Average) >= 55 && Number(m.Average) < 65);
  //       } else if (this.scoreLevel == 'E') {
  //         this.searchShoolResult = this.searchShoolResult.filter(m => Number(m.Average) >= 45 && Number(m.Average) < 55);
  //       } else {
  //         this.searchShoolResult = this.searchShoolResult.filter(m => Number(m.Average) < 45);
  //       }
  //     }
  //     this.searchShoolShowResult = this.searchShoolResult;
  //   }

  //   //Department
  //   if (this.departmentCheck) {
  //     this.searchDepartmentResult = deptData.filter(s => s.Year == this.year &&
  //       s.ExamType == this.examType &&
  //       s.ExamSpecial == this.examSpecial &&
  //       s.ExamGender == this.examGender &&
  //       (s.Area == this.area || this.area == '0') &&
  //       (s.EducationDepartment == this.department || this.department == '0'));

  //       if (this.scoreLevel != '0') {
  //         if (this.scoreLevel == 'A') {
  //           this.searchDepartmentResult = this.searchDepartmentResult.filter(m => Number(m.Average) >= 85);
  //         } else if (this.scoreLevel == 'B') {
  //           this.searchDepartmentResult = this.searchDepartmentResult.filter(m => Number(m.Average) >= 75 && Number(m.Average) < 85);
  //         } else if (this.scoreLevel == 'C') {
  //           this.searchDepartmentResult = this.searchDepartmentResult.filter(m => Number(m.Average) >= 65 && Number(m.Average) < 75);
  //         } else if (this.scoreLevel == 'D') {
  //           this.searchDepartmentResult = this.searchDepartmentResult.filter(m => Number(m.Average) >= 55 && Number(m.Average) < 65);
  //         } else if (this.scoreLevel == 'E') {
  //           this.searchDepartmentResult = this.searchDepartmentResult.filter(m => Number(m.Average) >= 45 && Number(m.Average) < 55);
  //         } else {
  //           this.searchDepartmentResult = this.searchDepartmentResult.filter(m => Number(m.Average) < 45);
  //         }
  //       }
  //   }
  //   //Office
  //   if (this.officeCheck) {
  //     this.searchOfficeResult = officeData.filter(s => s.Year == this.year &&
  //       s.ExamType == this.examType &&
  //       s.ExamSpecial == this.examSpecial &&
  //       s.ExamGender == this.examGender &&
  //       (s.Area == this.area || this.area == '0') &&
  //       (s.EducationDepartment == this.department || this.department == '0') &&
  //       (s.OfficeName == this.office || this.office == '0'));

  //       if (this.scoreLevel != '0') {
  //         if (this.scoreLevel == 'A') {
  //           this.searchOfficeResult = this.searchOfficeResult.filter(m => Number(m.Average) >= 85);
  //         } else if (this.scoreLevel == 'B') {
  //           this.searchOfficeResult = this.searchOfficeResult.filter(m => Number(m.Average) >= 75 && Number(m.Average) < 85);
  //         } else if (this.scoreLevel == 'C') {
  //           this.searchOfficeResult = this.searchOfficeResult.filter(m => Number(m.Average) >= 65 && Number(m.Average) < 75);
  //         } else if (this.scoreLevel == 'D') {
  //           this.searchOfficeResult = this.searchOfficeResult.filter(m => Number(m.Average) >= 55 && Number(m.Average) < 65);
  //         } else if (this.scoreLevel == 'E') {
  //           this.searchOfficeResult = this.searchOfficeResult.filter(m => Number(m.Average) >= 45 && Number(m.Average) < 55);
  //         } else {
  //           this.searchOfficeResult = this.searchOfficeResult.filter(m => Number(m.Average) < 45);
  //         }
  //       }
  //   }

  // }

  // getColor(item: string) {
  //   let classColor = 'bg-indicator-Darkgreen';
  //   if (Number(item) >= 85) {
  //     classColor = 'bg-indicator-Darkgreen';
  //   } else if (Number(item) >= 75 && Number(item) < 85) {
  //     classColor = 'bg-indicator-Midgreen';
  //   } else if (Number(item) >= 65 && Number(item) < 75) {
  //     classColor = 'bg-indicator-Lightgreen';
  //   } else if (Number(item) >= 55 && Number(item) < 65) {
  //     classColor = 'bg-indicator-yellow';
  //   } else if (Number(item) >= 45 && Number(item) < 55) {
  //     classColor = 'bg-indicator-orange';
  //   } else if (Number(item) >= 0 && Number(item) < 45) {
  //     classColor = 'bg-indicator-red';
  //   }

  //   return classColor;
  // }
}
