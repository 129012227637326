<section id="guide" class="py-0 bg-white">
    <div class="container py-5 mb-4">
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-xl-6 col-12 g-0">
                <div class="d-flex flex-xl-row flex-column justify-content-center align-items-xl-end align-items-center p-0 mb-sm-5 mb-4">
                    <div class="text-center me-xl-4 mb-xl-0 mb-3">
                        <img src="assets/media/icon/guide-head-icon.svg" alt="statistics-icon" class="img-fluid"
                            width="75">
                    </div>
                    <div class="text-xl-start text-center">
                        <h4 class="fw-semibold mb-0">أدلة المؤشر</h4>
                        <p  class="text-primary fs-lg mb-0">
                            دليل المدارس الأعلى أداءً  في مؤشر ترتيب على المستوى  الوطني
                        </p>
                    </div>
                </div>
              
            </div>
        </div>
        <div
            class="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-4 mt-2 mt-md-4 mb-lg-2 mx-0 w-100 justify-content-center">
            <div class="col d-flex justify-content-center mb-xl-0 mb-5 mt-5">
                <div
                    class="card w-md-75 w-100 h-100 border-0 shadow-sm pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 bg-primary">
                    <div class="card-body d-flex flex-column justify-content-center align-items-center px-0 pb-lg-0 py-3">
                        <div
                            class="d-inline-block bg-transparent rounded-3 position-absolute top-0 start-50 translate-middle p-3">
                            <img src="assets/media/icon/guide-icon.svg" width="75" alt="icon">
                        </div>
                        <p class="fs-lg text-white mb-2">دليل</p>
                        <h2 class="h4 text-white  d-inline-flex align-items-center">
                            المدارس الأعلى أداءً
                        </h2>
                        <div class="card-footer border-0">
                            <a href="assets/files/SaudiSchoolsRank.pdf" class="btn btn-lg btn-warning w-100 p-2 d-flex justify-content-center"  target="_blank">
                                <i class="bx bx-download fs-4 me-2"></i>
                                استعراض</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col d-lg-none"></div>
            <div class="col d-flex justify-content-center mb-xl-0 mb-5 mt-5">
                <div
                    class="card w-md-75 w-100 h-100 border-0 shadow-sm pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 bg-primary">
                    <div class="card-body d-flex flex-column justify-content-center align-items-center px-0 pb-lg-0  py-3">
                        <div
                            class="d-inline-block bg-transparent rounded-3 position-absolute top-0 start-50 translate-middle p-3">
                            <img src="assets/media/icon/guide-icon.svg" width="75" alt="icon">
                        </div>
                        <p class="fs-lg text-white mb-2">دليل</p>
                        <h2 class="h4 text-white  d-inline-flex align-items-center">
                            إدارات ومكاتب التعليم </h2>
                                                    <div class="card-footer border-0">
                                                        <a href="assets/files/EduRank.pdf" class="btn btn-lg btn-warning w-100 p-2 d-flex justify-content-center" target="_blank">
                                                            <i class="bx bx-download fs-4 me-2"></i>
                                                            استعراض</a>
                                                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>