import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SchoolList } from '../model/SchoolList';
// import ksaAverage from '../../assets/json/ksa-average.json';
import { ApiService } from '../api.service.service';

@Component({
  selector: 'app-compare-card',
  templateUrl: './compare-card.component.html',
  styleUrls: ['./compare-card.component.css']
})
export class CompareCardComponent implements OnInit {

  @Input() comparedSchool: SchoolList[] = [];
  @Input() showCompare!: string;
  @Output() showCompareChange = new EventEmitter<string>();
  @Input() showComparedSchoolRow!: boolean;
  @Input() minimize = '';

  comparedSchoolLocal: SchoolList[] = [];
  showCompareLocal = '';
  showComparedSchoolRowLocal = true;
  chart: any;
  minimizeLocal = '';
  examSpecial = '';
  year = '';
  examGender = ''
  examType = '';

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
  }

  hideChart() {
    this.showCompareLocal = '';
    this.showComparedSchoolRowLocal = true;
    this.comparedSchool.length = 0;
    this.comparedSchoolLocal.length = 0;
    this.showCompareChange.emit('');
    this.showComparedSchoolRow = true;

    this.chart.destroy();
  }

  showChart() {
    if (this.chart) {
      this.chart.destroy();
    }

    //this.showComparedSchoolRowLocal = false;
    var avg: string[] = [];
    var names: string[] = [];

    this.comparedSchool.forEach(element => {
      avg.push(element.Average);
      names.push(element.Name);
      this.year = element.Year;
      this.examGender = element.Gender;
      this.examSpecial = element.ExamSpecial;
      this.examType = element.ExamType;
    });

    // var ksaAvg = ksaAverage.filter(m => m['العام الدراسي'] == this.year
    //   && m['تخصص الاختبار'] == this.examSpecial
    //   && m['جنس المدرسة'] == this.examGender
    //   && m['نوع الاختبار'] == this.examType);

    // let av: string | undefined = '0';
    // if (ksaAvg.length > 0) {
    //   av = ksaAvg[0].ksaAvg;
    // }

    this.apiService.getByParameters('Overall', Number(this.year) , this.examGender, this.examType, 'كلية', this.examSpecial)
    .subscribe(data => {
      let av: string | undefined = '0';
      if (data.length > 0) {
        av = data[0].KsaAvg;
        console.log(av);
      }
  
      console.log(data);
      var options = this.showChartImage(avg, names, av);
      this.chart = new ApexCharts(document.querySelector("#chartCompare"), options);
      this.chart.render();
      console.log('api');
    })

    // var options = this.showChartImage(avg, names, av);
    // this.chart = new ApexCharts(document.querySelector("#chartCompare"), options);
    // this.chart.render();
  }

  showChartImage(avg: string[], names: string[], av: string | undefined) {
    var options = {
      series: [{
        name: 'درجة المدرسة',
        //data: [83, 76, 86, 79]
        data: avg
      }],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      colors: ['#4B3D8F'],
      annotations: {
        xaxis: [{
          x: av,
          borderColor: '#00E396',
          label: {
            borderColor: '#00E396',
            style: {
              color: '#000',
              background: '#00E396',
            },
            text: 'متوسط الدرجة على مستوى المملكة : ' + av,
          }
        }],
      },
      plotOptions: {
        bar: {
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories: names
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      yaxis: {
        reversed: true,
        axisTicks: {
          show: true
        }
      }
    };

    return options;
  }

  addToCmpare(item: SchoolList, addRemove: number) {
    //  this.showComparedSchoolRow = true;

    if (addRemove == 0) {
      const indexLocal = this.comparedSchoolLocal.indexOf(item, 0);
      const index = this.comparedSchool.indexOf(item, 0);
      if (index > -1) {
        this.comparedSchoolLocal.splice(indexLocal, 1);
        this.comparedSchool.splice(index, 1);
      }
    }
    //this.showCompare = this.comparedSchool.length > 0 ? 'show' : '';
  }

  ngOnChanges() {

    this.comparedSchoolLocal = this.comparedSchool;
    this.showCompareLocal = this.showCompare;
    this.showComparedSchoolRowLocal = this.showComparedSchoolRow;
    this.minimizeLocal = this.minimize;

  }
}
