import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service.service';
import { Config } from 'src/app/model/Area';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  defaultYear!: string;
  schoolsTotal:number = 0;
  yearsOfScale:number = 0;
  aPTStudentTotal:number = 0;
  aCTStudentTotal:number = 0;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.apiService
    .getConfig()
    .subscribe((data: Config[]) => {
     this.defaultYear = ( data.filter(m => m.IsDefault == true)?.at(0)?.ResultYear.toString())!;
     this.schoolsTotal =  data.filter(m => m.IsDefault == true)?.at(0)?.SchoolsTotal!;
     this.yearsOfScale = data.filter(m => m.IsDefault == true)?.at(0)?.YearsOfScale!;
     this.aPTStudentTotal = data.filter(m => m.IsDefault == true)?.at(0)?.APTStudentTotal!;
     this.aCTStudentTotal = data.filter(m => m.IsDefault == true)?.at(0)?.ACTStudentTotal!;
    });

  }

}
