import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DepartmentList } from '../model/DepartmentList';
import { SchoolList } from '../model/SchoolList';

@Component({
  selector: 'app-department-card',
  templateUrl: './department-card.component.html',
  styleUrls: ['./department-card.component.css']
})
export class DepartmentCardComponent implements OnInit {

  @Input() selectedDepartment! : DepartmentList ;
  @Input() showOffDeptcanvas! : string;
  @Output() showOffDeptcanvasChange = new EventEmitter<string>();

  @Input() dynamicClass!: string;
  showCanvasLocal='';

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.showCanvasLocal = this.showOffDeptcanvas;
  }

  hideCard(){
    this.showCanvasLocal = '';
    this.showOffDeptcanvasChange.emit('');
  }
}
