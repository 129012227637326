<!-- breadcrumb -->
<section class="fx-breadcrumb fixed-top position-fixed w-100 d-flex align-items-center bg-light mb-0">
  <div class="container position-relative zindex-5 py-3">
    <div class="row justify-content-center">
      <nav class="" aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <a href="#" class="fs-lg fw-normal">الرئيسية</a>
          </li>
          <li class="breadcrumb-item fs-lg fw-normal active" aria-current="page">
            مؤشر القدرات والتحصيلي
          </li>
        </ol>
      </nav>
      <!-- <h1 class="fs-3 py-3 mb-0">مؤشر القدرات والتحصيلي</h1> -->
    </div>
  </div>
</section>
<!-- content container -->
<div class="content-container bg-secondar bg-white">
  <!-- navbar search -->
  <aside class="bg-white p-3 bg-white navbar-vertical my-3 ms-3 fixed-start rounded-12 d-xl-block d-none shadow-lg">
    <!-- header -->
    <div class="sidenav-header" style="height: 4.5rem">
      <h4 class="d-flex align-items-center fs-lg fw-semibold text-primary text-start px-0 p-4 m-0">
        <i class="bx bx-slider fs-xl position-relative me-2"></i>
        خيارات البحث
      </h4>
    </div>
    <!-- inputs -->
    <div class="w-auto d-flex flex-column overflow-auto" style="height: calc(100vh - 369px)">
      <div class="position-relative mb-3">
        <i class="bx bx-search text-secondary fs-xl ms-3 position-absolute top-50 start-0 translate-middle-y"></i>
        <input type="text" class="form-control text-primary ps-5 bg-white shadow-none rounded-3 w-100" id="primary2"
          placeholder="ابحث بإسم المدرسة أو الرقم الوزاري..." [(ngModel)]="txtSearch" />
      </div>
      <div class="d-flex justify-content-around position-relative mb-3">
        <div class="d-flex flex-column justify-content-center align-items-center form-check text-center px-2"
          style="padding: 0 0.5rem !important">
          <i class="bx bxs-circle text-primary lh-lg"></i>
          <!-- <img src="assets/media/icon/school-pin.svg" alt="icon" class="mb-2" width="20"> -->
          <label class="form-check-label text-primary fs-sm fw-semibold mb-1" for="topschools-check">
            الأعلى أداءً
          </label>
          <input class="form-check-input m-auto" type="checkbox" id="topschools-check" [(ngModel)]="top10Check"
            [ngModelOptions]="{ standalone: true }" (change)="onTop10Change()" />
        </div>
        <div class="d-flex flex-column justify-content-between align-items-center form-check text-center px-2"
          style="padding: 0 0.5rem !important" *ngIf="!top10Check">
          <i class="bx bxs-circle text-primary lh-lg"></i>
          <!-- <img src="assets/media/icon/school-pin.svg" alt="icon" class="mb-2" width="20"> -->
          <label class="form-check-label text-primary fs-sm fw-semibold mb-1" for="schools-check">
            مدارس
          </label>
          <input class="form-check-input m-auto mb-0" type="checkbox" id="schools-check" [(ngModel)]="schoolCheck"
            [ngModelOptions]="{ standalone: true }" />
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center form-check text-center px-2"
          style="padding: 0 0.5rem !important" *ngIf="!top10Check">
          <i class="bx bxs-up-arrow text-primary lh-lg"></i>
          <!-- <img src="assets/media/icon/office-pin.svg" alt="icon" class="mb-2" width="20"> -->
          <label class="form-check-label text-primary fs-sm fw-semibold mb-1" for="office-check">
            مكاتب التعليم
          </label>
          <input class="form-check-input m-auto" type="checkbox" id="office-check" [(ngModel)]="officeCheck"
            [ngModelOptions]="{ standalone: false }" />
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center form-check text-center px-2"
          style="padding: 0 0.5rem !important" *ngIf="!top10Check">
          <i class="bx bxs-square text-primary lh-lg"></i>
          <!-- <img src="assets/media/icon/admin-pin.svg" alt="icon" class="mb-2" width="20"> -->
          <label class="form-check-label text-primary fs-sm fw-semibold mb-1" for="admin-check">
            إدارات التعليم
          </label>
          <input class="form-check-input m-auto" type="checkbox" id="admin-check" [(ngModel)]="departmentCheck"
            [ngModelOptions]="{ standalone: false }" />
        </div>
      </div>
      <div class="accordion accordion-filter" id="accordionPanelsStayOpenExample">
        <!-- Item -->
        <div class="accordion-item border-0 rounded-3 mb-2" style="border: 1px solid var(--disable) !important">
          <h3 class="accordion-header" id="headingOne">
            <button class="accordion-button shadow-none rounded-3 p-2 fs-lg fw-normal" type="button"
              data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              العام الدراسي
            </button>
          </h3>
          <div class="accordion-collapse collapse show" id="collapseOne" aria-labelledby="headingOne"
            data-bs-parent="#accordionAlt">
            <div class="accordion-body p-2 pt-0">
              <div class="d-flex">

                <div class="me-2 mb-2" *ngFor="let li of config">
                  <input class="btn-check" type="radio" name="school-year" value="{{li.ResultYear}}" id="school-year{{li.ResultYear}}"
                    (change)="onYearChangeDynamic(li.ResultYear.toString())" [(ngModel)]="year" />
                  <label class="btn btn-outline-primary px-3" for="school-year{{li.ResultYear}}"><span
                      class="mx-1">{{li.ResultYear}}</span></label>
                </div>

                <!-- <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="school-year" value="1442" id="school-year1442"
                    (change)="onYearChange($event)" [(ngModel)]="year" />
                  <label class="btn btn-outline-primary px-3" for="school-year1442"><span
                      class="mx-1">1442</span></label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="school-year" value="1443" id="school-year1443"
                    (change)="onYearChange($event)" [(ngModel)]="year" />
                  <label class="btn btn-outline-primary px-3" for="school-year1443"><span
                      class="mx-1">1443</span></label>
                </div>
                <div>
                  <input class="btn-check" type="radio" name="school-year" value="1444" id="school-year1444"
                    (change)="onYearChange($event)" [(ngModel)]="year" />
                  <label class="btn btn-outline-primary px-3" for="school-year1444"><span
                      class="mx-1">1444</span></label>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- Item -->
        <div class="accordion-item border-0 rounded-3 mb-2" style="border: 1px solid var(--disable) !important">
          <h3 class="accordion-header" id="headingTwo">
            <button class="accordion-button shadow-none rounded-3 p-2 fs-lg fw-normal" type="button"
              data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
              نوع الاختبار
            </button>
          </h3>
          <div class="accordion-collapse collapse show" id="collapseTwo" aria-labelledby="headingTwo"
            data-bs-parent="#accordionAlt">
            <div class="accordion-body p-2 pt-0">
              <div class="d-flex flex-wrap">
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="exam-Type" value="تحصيلي,علمي" checked="" id="exam-Type1"
                    [(ngModel)]="examTypeFull" (change)="onExamTypeChange($event)" />
                  <label class="btn btn-outline-primary px-2" for="exam-Type1"><span class="mx-1">تحصيلي
                      علمي</span></label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="exam-Type" value="تحصيلي,نظري" id="exam-Type2"
                    [(ngModel)]="examTypeFull" (change)="onExamTypeChange($event)" />
                  <label class="btn btn-outline-primary px-2" for="exam-Type2"><span class="mx-1">تحصيلي
                      نظري</span></label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="exam-Type" value="قدرات,علمي" id="exam-Type3"
                    [(ngModel)]="examTypeFull" (change)="onExamTypeChange($event)" />
                  <label class="btn btn-outline-primary px-2" for="exam-Type3"><span class="mx-1">قدرات
                      علمي</span></label>
                </div>
                <div class="">
                  <input class="btn-check" type="radio" name="exam-Type" value="قدرات,نظري" id="exam-Type4"
                    [(ngModel)]="examTypeFull" (change)="onExamTypeChange($event)" />
                  <label class="btn btn-outline-primary px-2" for="exam-Type4"><span class="mx-1">قدرات
                      نظري</span></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Item -->
        <div class="accordion-item border-0 rounded-3 mb-2 " style="border: 1px solid var(--disable) !important" *ngIf="hasDetailes">
          <h3 class="accordion-header" id="headingTwoTow">
            <button class="accordion-button shadow-none rounded-3 p-2 fs-lg fw-normal" type="button"
              data-bs-toggle="collapse" data-bs-target="#collapseTwoTow" aria-expanded="true"
              aria-controls="collapseTwoTow">
              نوع الدرجة
            </button>
          </h3>
          <div class="accordion-collapse collapse show" id="collapseTwoTow" aria-labelledby="headingTwoTow"
            data-bs-parent="#accordionAlt">
            <div class="accordion-body p-2 pt-0">
              <div class="d-flex flex-wrap">
                <div class="me-2 mb-2" *ngFor="let type of ExamSubType; let i = index">
                  <input class="btn-check" type="radio" name="exam-sub-Type1" value="{{ type }}"
                    [checked]="type == examSubType" id="exam-sub-Type1{{ i }}" [(ngModel)]="examSubType" />
                  <label class="btn btn-outline-primary px-2" for="exam-sub-Type1{{ i }}"><span class="mx-1">
                      {{ type == "كلية" ? "الكل" : type }}</span></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Item -->
        <div class="accordion-item border-0 rounded-3 mb-2" style="border: 1px solid var(--disable) !important">
          <h3 class="accordion-header" id="headingThree">
            <button class="accordion-button shadow-none rounded-3 p-2 fs-lg fw-normal" type="button"
              data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true"
              aria-controls="collapseThree">
              مستوي الاداء
            </button>
          </h3>
          <div class="accordion-collapse collapse show" id="collapseThree" aria-labelledby="headingThree"
            data-bs-parent="#accordionAlt">
            <div class="accordion-body p-2 pt-0">
              <div class="d-flex flex-wrap">
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" id="scoreLevel-all" value="0"
                    (change)="onScoreLevelChange($event)" [(ngModel)]="scoreLevel" />
                  <label class="btn btn-outline-primary px-3" for="scoreLevel-all">
                    <span>الكل</span>
                    <!-- <span class="fs-xs text-muted ms-auto">697</span> -->
                  </label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" id="scoreLevel-A" value="A"
                    (change)="onScoreLevelChange($event)" [(ngModel)]="scoreLevel" />
                  <label class="btn px-3 btn-outline-Darkgreen" for="scoreLevel-A">
                    <span>85 فأكثر</span>
                    <!-- <span class="fs-xs text-muted ms-auto">10</span> -->
                  </label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" id="scoreLevel-B" value="B"
                    (change)="onScoreLevelChange($event)" [(ngModel)]="scoreLevel" />
                  <label class="btn btn-outline-Midgreen" for="scoreLevel-B">
                    <span>75-84.9</span>
                    <!-- <span class="fs-xs text-muted ms-auto">20</span> -->
                  </label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" id="scoreLevel-C" value="C"
                    (change)="onScoreLevelChange($event)" [(ngModel)]="scoreLevel" />
                  <label class="btn btn-outline-Lightgreen px-3" for="scoreLevel-C">
                    <span>65-74.9</span>
                    <!-- <span class="fs-xs text-muted ms-auto">30</span> -->
                  </label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" id="scoreLevel-D" value="D"
                    (change)="onScoreLevelChange($event)" [(ngModel)]="scoreLevel" />
                  <label class="btn btn-outline-yellow px-3" for="scoreLevel-D">
                    <span>55-64.9</span>
                    <!-- <span class="fs-xs text-muted ms-auto">40</span> -->
                  </label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" id="scoreLevel-E" value="E"
                    (change)="onScoreLevelChange($event)" [(ngModel)]="scoreLevel" />
                  <label class="btn btn-outline-orange px-3" for="scoreLevel-E">
                    <span>45-54.9</span>
                    <!-- <span class="fs-xs text-muted ms-auto">50</span> -->
                  </label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" id="scoreLevel-F" value="F"
                    (change)="onScoreLevelChange($event)" [(ngModel)]="scoreLevel" />
                  <label class="btn btn-outline-red px-3" for="scoreLevel-F">
                    <span>أقل من 44.9</span>
                    <!-- <span class="fs-xs text-muted ms-auto">0</span> -->
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Item -->
        <div class="accordion-item border-0 rounded-3 mb-2" style="border: 1px solid var(--disable) !important">
          <h3 class="accordion-header" id="headingFour">
            <button class="accordion-button shadow-none rounded-3 p-2 fs-lg fw-normal" type="button"
              data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true"
              aria-controls="collapseFour">
              الجنس
            </button>
          </h3>
          <div class="accordion-collapse collapse show" id="collapseFour" aria-labelledby="headingFour"
            data-bs-parent="#accordionAlt">
            <div class="accordion-body p-2 pt-0">
              <div class="d-flex">
                <div class="me-2 mb-2" *ngIf="examTypeFull != 'تحصيلي,نظري'">
                  <input class="btn-check" type="radio" name="school-G" value="M" id="school-M"
                    (change)="onGenderChange($event)" [(ngModel)]="examGender" />
                  <label class="btn btn-outline-primary px-3" for="school-M"><span class="mx-1">بنين</span></label>
                </div>
                <div>
                  <input class="btn-check" type="radio" name="school-G" value="F" id="school-F"
                    (change)="onGenderChange($event)" [(ngModel)]="examGender" />
                  <label class="btn btn-outline-primary px-3" for="school-F"><span class="mx-1">بنات</span></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Item -->
        <div class="accordion-item border-0 rounded-3 mb-2" style="border: 1px solid var(--disable) !important">
          <h3 class="accordion-header" id="headingFive">
            <button class="accordion-button shadow-none rounded-3 p-2 fs-lg fw-normal" type="button"
              data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true"
              aria-controls="collapseFive">
              المنطقة
            </button>
          </h3>
          <div class="accordion-collapse collapse show" id="collapseFive" aria-labelledby="headingFive"
            data-bs-parent="#accordionAlt">
            <div class="accordion-body p-2 pt-0">
              <div class="d-flex flex-wrap">
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="area" value="0" checked="" id="area-Type1"
                    (change)="onAreaChange($event)" [(ngModel)]="area" />
                  <label class="btn btn-outline-primary px-2" for="area-Type1"><span class="mx-1">على مستوى المملكة
                    </span></label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="area" value="الرياض" id="area-Type2"
                    (change)="onAreaChange($event)" [(ngModel)]="area" />
                  <label class="btn btn-outline-primary px-2" for="area-Type2"><span class="mx-1">الرياض </span></label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="area" value="مكة المكرمة" id="area-Type3"
                    (change)="onAreaChange($event)" [(ngModel)]="area" />
                  <label class="btn btn-outline-primary px-2" for="area-Type3"><span class="mx-1">مكة المكرمة
                    </span></label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="area" value="المدينة المنورة" id="area-Type4"
                    (change)="onAreaChange($event)" [(ngModel)]="area" />
                  <label class="btn btn-outline-primary px-2" for="area-Type4"><span class="mx-1">المدينة المنورة
                    </span></label>
                </div>

                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="area" value="الشرقية" id="area-Type5"
                    (change)="onAreaChange($event)" [(ngModel)]="area" />
                  <label class="btn btn-outline-primary px-2" for="area-Type5"><span class="mx-1">الشرقية
                    </span></label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="area" value="الجوف" id="area-Type6"
                    (change)="onAreaChange($event)" [(ngModel)]="area" />
                  <label class="btn btn-outline-primary px-2" for="area-Type6"><span class="mx-1">الجوف </span></label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="area" value="الباحة" id="area-Type7"
                    (change)="onAreaChange($event)" [(ngModel)]="area" />
                  <label class="btn btn-outline-primary px-2" for="area-Type7"><span class="mx-1">الباحة </span></label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="area" value="عسير" id="area-Type8"
                    (change)="onAreaChange($event)" [(ngModel)]="area" />
                  <label class="btn btn-outline-primary px-2" for="area-Type8"><span class="mx-1">عسير </span></label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="area" value="القصيم" id="area-Type9"
                    (change)="onAreaChange($event)" [(ngModel)]="area" />
                  <label class="btn btn-outline-primary px-2" for="area-Type9"><span class="mx-1">القصيم </span></label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="area" value="حائل" id="area-Type10"
                    (change)="onAreaChange($event)" [(ngModel)]="area" />
                  <label class="btn btn-outline-primary px-2" for="area-Type10"><span class="mx-1">حائل </span></label>
                </div>

                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="area" value="تبوك" id="area-Type11"
                    (change)="onAreaChange($event)" [(ngModel)]="area" />
                  <label class="btn btn-outline-primary px-2" for="area-Type11"><span class="mx-1">تبوك </span></label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="area" value="الحدود الشمالية" id="area-Type12"
                    (change)="onAreaChange($event)" [(ngModel)]="area" />
                  <label class="btn btn-outline-primary px-2" for="area-Type12"><span class="mx-1">الحدود الشمالية
                    </span></label>
                </div>
                <div class="me-2 mb-2">
                  <input class="btn-check" type="radio" name="area" value="جازان" id="area-Type13"
                    (change)="onAreaChange($event)" [(ngModel)]="area" />
                  <label class="btn btn-outline-primary px-2" for="area-Type13"><span class="mx-1">جازان </span></label>
                </div>
                <div class="">
                  <input class="btn-check" type="radio" name="area" value="نجران" id="area-Type14"
                    (change)="onAreaChange($event)" [(ngModel)]="area" />
                  <label class="btn btn-outline-primary px-2" for="area-Type14"><span class="mx-1">نجران </span></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative mb-2" *ngIf="!top10Check">
        <select class="form-select" id="select-input" (change)="onDepartmentChange($event)" [(ngModel)]="department">
          <option selected="true" value="0">الإدارة التعليمية</option>
          <option *ngFor="let d of DepartmentNoRepeat" value="{{ d }}">
            {{ d }}
          </option>
        </select>
      </div>
      <div class="position-relative mb-2" *ngIf="!top10Check">
        <select class="form-select" id="select-input" (change)="onOfficeChange($event)" [(ngModel)]="office">
          <option selected="true" value="0">مكتب التعليم</option>
          <option *ngFor="let o of Office" value="{{ o.Office }}">
            {{ o.Office }}
          </option>
        </select>
      </div>
      <div class="position-relative mb-3">
        <select class="form-select" id="select-input" (change)="onAuthorityChange($event)">
          <option selected="true" value="0">نوع التعليم</option>
          <option value="حكومي">حكومي</option>
          <option value="أهلي">أهلي</option>
          <option value="تعليم عالمي">تعليم عالمي</option>
          <option value="معهد مهني">معهد مهني</option>
          <option value="الهيئة الملكية">الهيئة الملكية</option>
          <option value="معهد جامعة الامام">معهد جامعة الامام</option>
          <option value="الرئاسة العامة لشؤون المسجد الحرام والمسجد النبوي">
            الرئاسة العامة لشؤون المسجد الحرام والمسجد النبوي
          </option>
        </select>
      </div>
    </div>
    <!-- footer -->
    <div class="sidenav-footer" style="height: 4.5rem">
      <div class="position-relative d-flex py-4">
        <button type="button" class="btn btn-primary w-100 fs-lg me-2" (click)="searchBtn()">
          تطبيق
        </button>
        <button type="button" class="btn btn-outline-primary fs-lg w-100" (click)="clearSearchForm()">
          إعادة تعيين
        </button>
      </div>
    </div>
  </aside>
  <!-- main content -->
  <main class="main-content p-0">
    <div class="container-fluid">
      <!-- tablist -->
      <div class="row">
        <div class="col position-fixed bg-white" style="z-index: 999">
          <div class="d-flex py-xl-3 py-2 py-0 overflow-auto">
            <ul role="tablist" class="nav nav-tabs d-flex flex-row flex-nowrap general-aptitude mb-0 w-xl-50 w-100">
              <li class="nav-item text-center w-xl-100 w-50 pe-2 pb-xl-0 pb-2 m-0">
                <a href="#tab-map" data-bs-toggle="tab" role="tab" aria-selected="true"
                  class="nav-link justify-content-center w-100 bg-white fs-lg text-nowrap active">الخريطة التفاعلية</a>
              </li>
              <li class="nav-item text-center w-xl-100 w-50 pe-2 pb-xl-0 pb-2 m-0" (click)="tabName = 's'">
                <a href="#tab-school" data-bs-toggle="tab" role="tab" aria-selected="true"
                  class="nav-link justify-content-center w-100 fs-lg text-nowrap bg-white">ترتيب المدارس</a>
              </li>
              <li class="nav-item text-center w-xl-100 w-50 pe-2 m-0" (click)="tabName = 'd'">
                <a href="#tab-admin" data-bs-toggle="tab" role="tab" aria-selected="true"
                  class="nav-link justify-content-center w-100 fs-lg text-nowrap bg-white">ترتيب إدارات التعليم
                </a>
              </li>
              <li class="nav-item text-center w-xl-100 w-50 m-0" (click)="tabName = 'o'">
                <a href="#tab-office" data-bs-toggle="tab" role="tab" aria-selected="true"
                  class="nav-link justify-content-center w-100 fs-lg text-nowrap bg-white">ترتيب مكاتب التعليم
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- tabContent -->
      <div class="row">
        <div class="col bg-white">
          <div class="tab-content map-container px-0" style="margin-top: 77px">
            <!-- map -->
            <div class="tab-pane fade active show" id="tab-map" role="tabpanel" aria-labelledby="pills-map">
              <!-- <div _ngcontent-mjc-c56="" class="d-flex p-2 pb-xl-0 rounded-top" style="background-color: #f6f0ff !important;">
                                <p _ngcontent-mjc-c56="" class="w-100 text-center text-primary fs-lg mb-0">
                                    نتائج البحث {{searchShoolShowResult.length}} مدرسة
                                </p>
                            </div> -->
              <div class="position-relative">
                <div id="map" class="position-absolute h-100 w-100 border-primary border-1 map-container rounded-12">
                </div>
                <div class="d-flex flex-row top-0 start-0 p-3 fixed map-container">
                  <!-- Solid button group -->
                  <div
                    class="position-fixed bottom-0 start-50 translate-middle-x bg-primary rounded-pill mb-4 p-2 d-xl-none d-block"
                    style="z-index: 999">
                    <div class="btn-group m-0 p-0" role="group" aria-label="Solid button group">
                      <a class="btn btn-primary text-white px-2" data-bs-toggle="offcanvas" href="#offcanvasExample1"
                        role="button" aria-controls="offcanvasExample">
                        <i class="bx bx-slider fs-lg text-white position-relative me-1"></i>
                        تصنيف حسب
                      </a>
                      <span class="d-flex justify-content-center align-items-center text-white">
                        <i class="bx bx-minus bx-rotate-90"></i>
                      </span>
                      <a href="#" class="btn btn-primary px-2" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasBottomLegend">
                        <i class="bx bxs-color fs-lg text-white position-relative me-1"></i>
                        مستوي الاداء
                      </a>
                    </div>
                  </div>
                  <!-- filter mobile -->
                  <!-- <div class="d-xl-none d-block">
                                        <a data-bs-toggle="offcanvas" href="#offcanvasExample1" role="button"
                                            aria-controls="offcanvasExample"
                                            class="filter-mob d-flex justify-content-center align-items-center position-absolute bg-primary text-white text-decoration-none rounded-3">
                                            <i class="bx bx-slider fs-lg text-white position-relative me-1"></i>
                                            البحث
                                        </a>
                                    </div> -->
                  <!-- search mobile -->
                  <!-- <div class="position-absolute d-xl-none d-block" style="z-index: 999;">
                                        <div class="form-floating mt-0">
                                            <a class="btn btn-primary shadow-primary p-3 ms-5 w-100"
                                                data-bs-toggle="offcanvas" href="#offcanvasExample1" role="button"
                                                aria-controls="offcanvasExample">
                                                <i class="bx bx-slider fs-xl position-relative me-2"></i>
                                                خيارات البحث
                                            </a>
                                        </div>
                                    </div> -->
                  <!-- legend -->
                  <div class="position-absolute start-0 bottom-0 m-2" style="z-index: 999">
                    <!-- legend XL-->
                    <div class="card flex-column align-items-center border-1 border-primary d-xl-block d-none">
                      <div class="card-body text-xl-start text-center w-100 px-3 py-3">
                        <p class="fs-sm mt-sm-0 mt-xxl-0 mb-2 text-primary">
                          مستوى الأداء حسب متوسط درجات الطلبة في المدرسة
                        </p>
                        <span
                          class="badge bg-primary rounded fs-sm fw-normal p-2 me-2 mb-2 lh-sm bg-indicator-Darkgreen">85
                          فأكثر</span>
                        <span
                          class="badge bg-primary rounded fs-sm fw-normal p-2 me-2 mb-2 lh-sm bg-indicator-Midgreen">75-84.9</span>
                        <span
                          class="badge bg-primary rounded fs-sm fw-normal p-2 me-2 mb-2 lh-sm bg-indicator-Lightgreen">65-74.9</span>
                        <!-- <div class="pt-2"></div> -->
                        <span
                          class="badge bg-primary rounded fs-sm fw-normal p-2 me-2 lh-sm mb-2 bg-indicator-yellow">55-64.9</span>
                        <span
                          class="badge bg-primary rounded fs-sm fw-normal p-2 me-2 mb-2 lh-sm bg-indicator-orange">45-54.9</span>
                        <span class="badge bg-primary rounded fs-sm fw-normal p-2 me-0 mb-2 lh-sm bg-indicator-red">أقل
                          من 44.9</span>
                      </div>
                    </div>
                    <!-- legend Mobile-->
                    <!-- <div class="accordion d-lg-none d-block" id="accordionAlt"
                                            style="max-width: 330px;">
                                            <div class="accordion-item border-0 rounded-3 shadow-sm">
                                                <h3 class="accordion-header" id="headinglegend">
                                                    <button
                                                        class="accordion-button shadow-none rounded-3 collapsed fs-sm fw-normal p-3 me-5"
                                                        type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapselegend" aria-expanded="false"
                                                        aria-controls="collapselegend">مستوى الأداء حسب متوسط درجات
                                                        الطلبة
                                                        في المدرسة</button>
                                                </h3>
                                                <div class="accordion-collapse collapse" id="collapselegend"
                                                    aria-labelledby="headinglegend" data-bs-parent="#accordionAlt">
                                                    <div class="accordion-body p-3 pt-0">
                                                        <span
                                                            class="badge bg-primary rounded fs-sm fw-normal p-2 me-2 mb-2 lh-sm bg-indicator-Darkgreen">85
                                                            فأكثر</span>
                                                        <span
                                                            class="badge bg-primary rounded fs-sm fw-normal p-2 me-2 mb-2 lh-sm bg-indicator-Midgreen">75-84.9</span>
                                                        <span
                                                            class="badge bg-primary rounded fs-sm fw-normal p-2 me-2 mb-2 lh-sm bg-indicator-Lightgreen">65-74.9</span>
                                                        <span
                                                            class="badge bg-primary rounded fs-sm fw-normal p-2 me-2 lh-sm mb-2 bg-indicator-yellow">55-64.9</span>
                                                        <span
                                                            class="badge bg-primary rounded fs-sm fw-normal p-2 me-2 mb-2 lh-sm bg-indicator-orange">45-54.9</span>
                                                        <span
                                                            class="badge bg-primary rounded fs-sm fw-normal p-2 me-0 mb-2 lh-sm bg-indicator-red">أقل
                                                            من
                                                            44.9</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- school -->
            <div class="tab-pane fade" id="tab-school" role="tabpanel" aria-labelledby="pills-school">
              <!-- filter choices -->
              <div
                class="m-0 py-xl-2 py-2 text-primary fs-lg fw-semibold d-flex flex-nowrap overflow-auto justify-content-start align-items-center ">
                <span  class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2" > مفاتيح البحث </span>
                <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
                 العام الدراسي: {{ _year }}
                  هـ
                  <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                    class="btn-close fs-xs ms-2"></button> -->
                </span>
                <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
                 نوع الإختبار: {{ _examTypeFull }}
                  <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                    class="btn-close fs-xs ms-2"></button> -->
                </span>
                <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
                 نوع الدرجة: {{ _examSubType }}
                  <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                    class="btn-close fs-xs ms-2"></button> -->
                </span>
                <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
                 الجنس: {{ _examGender == "M" ? "بنين" : "بنات" }}
                  <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                    class="btn-close fs-xs ms-2"></button> -->
                </span>
                <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
                 المنطقة: {{ _area == "0" ? "على مستوى المملكة" : _area }}
                  <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                    class="btn-close fs-xs ms-2"></button> -->
                </span>
              </div>
              <div class="d-flex flex-xl-column flex-lg-column flex-column mb-5 pb-xl-0 pb-5">
                <!-- table head -->
                <div class="input-group w-auto position-relative mb-3 mt-xl-0 mt-0">
                  <input type="text" placeholder="ابحث فى النتائج..."
                    class="form-control text-primary ps-5 bg-white shadow-none rounded-3 w-100"
                    [(ngModel)]="txtSearchTable" (keydown)="search()" (keypress)="search()" (keyup)="search()" />
                  <i
                    class="bx bx-search fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
                </div>
                <div class="d-flex p-2 pb-xl-0 rounded-top" *ngIf="tabName == 's'"
                  style="background-color: #f6f0ff !important">
                  <p class="w-100 text-center text-primary fs-lg mb-0">
                    نتائج البحث {{ searchShoolShowResult.length }} مدرسة
                  </p>
                </div>

                <div
                  class="g-0 h-100 bg-primary align-content-center d-none d-xl-flex p-3 py-3 mb-3 rounded-bottom row row-cols-xl-6"
                  style="background-color: #f6f0ff !important">
                  <div class="d-flex flex-column mb-lg-0 pe-1 d-flex col-xl">
                    <p class="mb-0 fs-lg text-primary cursor-pointer text-xl-center">
                      اسم المدرسة
                    </p>
                  </div>
                  <div class="d-flex flex-column pe-1 justify-content-center col-xl">
                    <p class="mb-0 fs-lg text-primary cursor-pointer text-xl-center">
                      متوسط الدرجة
                    </p>
                  </div>
                  <div class="d-flex flex-column pe-1 justify-content-center col-xl">
                    <p class="mb-0 fs-lg text-primary cursor-pointer text-xl-center">
                      إدارة التعليم
                    </p>
                  </div>
                  <div class="d-flex flex-column pe-1 justify-content-center col-xl">
                    <p class="mb-0 fs-lg text-primary cursor-pointer text-xl-center">
                      مكتب التعليم
                    </p>
                  </div>
                  <div class="d-flex flex-column pe-1 justify-content-center col-xl">
                    <p class="mb-0 fs-lg text-primary cursor-pointer text-xl-center">
                      الترتيب
                    </p>
                  </div>
                  <div class="d-flex flex-column pe-1 justify-content-center col-xl">
                    <p class="mb-0 fs-lg text-primary cursor-pointer text-xl-center"></p>
                  </div>
                </div>
                <div class="row row-cols-xl-1 row-cols-lg-2 row-cols-1 g-xl-2 g-3 school-col">
                  <!-- table row -->
                  <div class="col" *ngFor="
                      let item of searchShoolShowResult
                        | slice : 0 : recordSeed + recordShow;
                      let i = index
                    ">
                    <div class="false card border-0 shadow-primary">
                      <div class="p-3 sh-21 sh-xl-7 card-body">
                        <div class="h-100 align-items-center bg-white cursor-default row row-cols-xl-6">
                          <div
                            class="d-flex flex-column justify-content-center mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-12">
                            <div class="text-primary mb-2 fs-lg d-xl-none fw-semibold">
                              اسم المدرسة
                            </div>
                            <div class="fs-lg text-primary text-xl-center">
                              {{ item.Name }}
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center align-items-xl-center align-items-start mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-6">
                            <div class="text-primary mb-2 fs-lg d-xl-none fw-semibold">
                              متوسط الدرجة
                            </div>
                            <div class="align-items-center d-flex justify-content-center p-2 rounded-3 text-center w-xl-50 w-auto {{
                                getColor(item.Average)
                              }}">
                              <p class="m-0 text-white fw-normal fs-lg">
                                {{ item.Average }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-6">
                            <div class="text-primary mb-2 fs-lg d-xl-none fw-semibold">
                              إدارة التعليم
                            </div>
                            <div class="fs-lg text-primary text-xl-center">
                              {{ item.Department }}
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-6">
                            <div class="text-primary mb-2 fs-lg d-xl-none fw-semibold">
                              مكتب التعليم
                            </div>
                            <div class="fs-lg text-primary text-xl-center">
                              {{ item.Office }}
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-6">
                            <div class="text-primary mb-2 fs-lg d-xl-none fw-semibold">
                              الترتيب
                            </div>
                            <div class="fs-lg text-primary text-xl-center">
                              {{ item.KsaRank }}
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-12">
                            <div class="text-primary mb-2 fs-lg fs-lg d-xl-none fw-semibold">
                              قارن
                            </div>

                            <!-- tesssst -->
                            <div class="position-relative d-flex flex-xxl-row flex-xl-column flex-row mt-1">
                              <button type="button" class="btn btn-outline-primary fs-lg w-100 mb-xxl-0 mb-xl-2 mb--0 px-2 me-2"
                                (click)="addToCmpare(item, 1)" *ngIf="comparedSchool.indexOf(item) == -1">
                                مقارنة
                              </button>

                              <button type="button" class="btn btn-danger fs-lg w-100 mb-xxl-0 mb-xl-2 px-2 me-2"
                                (click)="addToCmpare(item, 0)" *ngIf="comparedSchool.indexOf(item) != -1">
                                ازالة
                              </button>
                              <button type="button" class="btn btn-outline-primary fs-lg px-2 w-100"
                                (click)="onClickSchoolMarker(item)">
                                عرض
                              </button>
                            </div>

                            <!-- <div class="position-relative d-flex flex-row mt-1">
                              <a
                                href="profile/{{ item?.MNO }}/{{
                                  item?.Year
                                }}/{{ item?.Gender }}/{{ item?.ExamType }}/{{
                                  item?.ExamSpecial
                                }}"
                                target="_blank"
                                type="button"
                                class="btn btn-secondary fs-lg w-100"
                              >
                                ملف المدرسة
                              </a>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row flex-column mt-4">
                  <div class="col d-flex justify-content-center mt-4 mb-2">
                    <p class="m-0 text-primary fw-semibold fs-lg">
                      {{
                      recordSeed + recordShow < searchShoolShowResult.length ? recordSeed + recordShow :
                        searchShoolShowResult.length }} من {{ searchShoolShowResult.length }} </p>
                  </div>
                  <div class="col d-flex justify-content-center mb-2">
                    <button type="button" class="btn btn-primary px-5 fs-lg fw-normal"
                      (click)="recordSeed = recordSeed + recordShow" *ngIf="
                        searchShoolShowResult.length > recordShow &&
                        recordSeed + recordShow < searchShoolShowResult.length
                      ">
                      المزيد
                    </button>
                  </div>
                </div>
              </div>
              <!-- Solid button group -->
              <div
                class="position-fixed bottom-0 start-50 translate-middle-x bg-primary rounded-pill mb-4 p-2 d-xl-none d-block"
                style="z-index: 999">
                <div class="btn-group m-0 p-0" role="group" aria-label="Solid button group">
                  <a class="btn btn-primary text-white px-2" data-bs-toggle="offcanvas" href="#offcanvasExample1"
                    role="button" aria-controls="offcanvasExample">
                    <i class="bx bx-slider fs-lg text-white position-relative me-1"></i>
                    تصنيف حسب
                  </a>
                  <span class="d-flex justify-content-center align-items-center text-white">
                    <i class="bx bx-minus bx-rotate-90"></i>
                  </span>
                  <a href="#" class="btn btn-primary px-2" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBottomLegend">
                    <i class="bx bxs-color fs-lg text-white position-relative me-1"></i>
                    مستوي الاداء
                  </a>
                </div>
              </div>
            </div>
            <!-- admin -->
            <div class="tab-pane fade" id="tab-admin" role="tabpanel" aria-labelledby="pills-admin">
              <!-- filter choices -->
              <div
                class="m-0 py-xl-2 py-2 text-primary fs-lg fw-semibold d-flex flex-nowrap overflow-auto justify-content-start align-items-center ">
                <span  class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2" > مفاتيح البحث </span>
                <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
                 العام الدراسي: {{ _year }}
                  هـ
                  <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                    class="btn-close fs-xs ms-2"></button> -->
                </span>
                <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
                 نوع الإختبار: {{ _examTypeFull }}
                  <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                    class="btn-close fs-xs ms-2"></button> -->
                </span>
                <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
                 نوع الدرجة: {{ _examSubType }}
                  <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                    class="btn-close fs-xs ms-2"></button> -->
                </span>
                <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
                 الجنس: {{ _examGender == "M" ? "بنين" : "بنات" }}
                  <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                    class="btn-close fs-xs ms-2"></button> -->
                </span>
                <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
                 المنطقة: {{ _area == "0" ? "على مستوى المملكة" : _area }}
                  <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                    class="btn-close fs-xs ms-2"></button> -->
                </span>
              </div>
              <div class="d-flex flex-xl-column flex-lg-column flex-column pb-xl-0 pb-5">
                <!-- table head -->
                <div class="d-flex p-2 pb-xl-0 rounded-top" *ngIf="tabName == 'd'"
                  style="background-color: #f6f0ff !important">
                  <p class="w-100 text-center text-primary fs-lg mb-0">
                    نتائج البحث {{ searchDepartmentResult.length }} إدارة تعليم
                  </p>
                </div>

                <div
                  class="g-0 h-100 bg-primary align-content-center d-none d-xl-flex p-3 py-3 mb-3 rounded-bottom row row-cols-xl-4"
                  style="background-color: #f6f0ff !important">
                  <div class="d-flex flex-column mb-lg-0 pe-1 d-flex col-xl">
                    <div class="text-primary fs-lg cursor-pointer text-xl-center">
                      إدارة التعليم
                    </div>
                  </div>
                  <div class="d-flex flex-column pe-1 justify-content-center col-xl">
                    <div class="text-primary fs-lg cursor-pointer text-xl-center">
                      متوسط الدرجة
                    </div>
                  </div>
                  <div class="d-flex flex-column pe-1 justify-content-center col-xl">
                    <div class="text-primary fs-lg cursor-pointer text-xl-center">
                      المنطقة
                    </div>
                  </div>
                  <div class="d-flex flex-column pe-1 justify-content-center col-xl">
                    <div class="text-primary fs-lg cursor-pointer text-xl-center">
                      الترتيب
                    </div>
                  </div>
                </div>
                <div class="row row-cols-xl-1 row-cols-lg-2 row-cols-1 g-xl-2 g-3 dept-col">
                  <!-- table row -->
                  <div class="col" *ngFor="
                      let item of searchDepartmentResult
                        | slice : 0 : recordDeptSeed + recordShow;
                      let i = index
                    ">
                    <div class="false card border-0 shadow-primary">
                      <div class="p-3 sh-21 sh-xl-7 card-body">
                        <div class="h-100 align-items-center bg-white cursor-default row row-cols-xl-4">
                          <div
                            class="d-flex flex-column justify-content-center mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-12">
                            <div class="text-primary mb-2 fs-lg d-xl-none fw-semibold">
                              إدارة التعليم
                            </div>
                            <div class="text-alternate text-primary text-xl-center">
                              {{ item.EducationDepartment }}
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center align-items-xl-center align-items-start mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-6">
                            <div class="text-primary mb-2 fs-lg d-xl-none fw-semibold">
                              متوسط الدرجة الدرجة
                            </div>
                            <div class="align-items-center d-flex justify-content-center p-2 rounded-3 text-center w-xl-50 w-auto {{
                                getColor(item.Average)
                              }}">
                              <p class="m-0 text-white fw-normal fs-lg">
                                {{ item.Average }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-6">
                            <div class="text-primary mb-2 fs-lg d-xl-none fw-semibold">
                              المنطقة
                            </div>
                            <div class="text-alternate text-primary text-xl-center">
                              {{ item.Area }}
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-6">
                            <div class="text-primary mb-2 fs-lg d-xl-none fw-semibold">
                              الترتيب
                            </div>
                            <div class="text-alternate text-primary text-xl-center">
                              {{ item.Rank }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row flex-column mt-4">
                  <div class="col d-flex justify-content-center mt-4 mb-2">
                    <p class="m-0 text-primary fw-semibold fs-md">
                      {{
                      recordDeptSeed + recordShow < searchDepartmentResult.length ? recordDeptSeed + recordShow :
                        searchDepartmentResult.length }} من {{ searchDepartmentResult.length }} </p>
                  </div>
                  <div class="col d-flex justify-content-center mb-2">
                    <button type="button" class="btn btn-primary px-5 fs-md fw-normal"
                      (click)="recordDeptSeed = recordDeptSeed + recordShow" *ngIf="
                        searchDepartmentResult.length > recordShow &&
                        recordDeptSeed + recordShow <
                          searchDepartmentResult.length
                      ">
                      المزيد
                    </button>
                  </div>
                </div>
              </div>
              <!-- Solid button group -->
              <div
                class="position-fixed bottom-0 start-50 translate-middle-x bg-primary rounded-pill mb-4 p-2 d-xl-none d-block"
                style="z-index: 999">
                <div class="btn-group m-0 p-0" role="group" aria-label="Solid button group">
                  <a class="btn btn-primary text-white px-2" data-bs-toggle="offcanvas" href="#offcanvasExample1"
                    role="button" aria-controls="offcanvasExample">
                    <i class="bx bx-slider fs-lg text-white position-relative me-1"></i>
                    تصنيف حسب
                  </a>
                  <span class="d-flex justify-content-center align-items-center text-white">
                    <i class="bx bx-minus bx-rotate-90"></i>
                  </span>
                  <a href="#" class="btn btn-primary px-2" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBottomLegend">
                    <i class="bx bxs-color fs-lg text-white position-relative me-1"></i>
                    مستوي الاداء
                  </a>
                </div>
              </div>
            </div>
            <!-- office -->
            <div class="tab-pane fade" id="tab-office" role="tabpanel" aria-labelledby="pills-office">
              <!-- filter choices -->
              <div
              class="m-0 py-xl-2 py-2 text-primary fs-lg fw-semibold d-flex flex-nowrap overflow-auto justify-content-start align-items-center ">
              <span  class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2" > مفاتيح البحث </span>
              <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
               العام الدراسي: {{ _year }}
                هـ
                <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                  class="btn-close fs-xs ms-2"></button> -->
              </span>
              <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
               نوع الإختبار: {{ _examTypeFull }}
                <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                  class="btn-close fs-xs ms-2"></button> -->
              </span>
              <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
               نوع الدرجة: {{ _examSubType }}
                <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                  class="btn-close fs-xs ms-2"></button> -->
              </span>
              <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
               الجنس: {{ _examGender == "M" ? "بنين" : "بنات" }}
                <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                  class="btn-close fs-xs ms-2"></button> -->
              </span>
              <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-1 mb-2">
               المنطقة: {{ _area == "0" ? "على مستوى المملكة" : _area }}
                <!-- <button type="button" data-bs-dismiss="toast" aria-label="Close"
                  class="btn-close fs-xs ms-2"></button> -->
              </span>
            </div>
              <div class="d-flex flex-xl-column flex-lg-column flex-column mb-5 pb-xl-0 pb-5">
                <!-- table head -->
                <div class="d-flex p-2 pb-xl-0 rounded-top" *ngIf="tabName == 'o'"
                  style="background-color: #f6f0ff !important">
                  <p class="w-100 text-center text-primary fs-lg mb-0">
                    نتائج البحث {{ searchOfficeResult.length }} مكتب
                  </p>
                </div>
                <div
                  class="g-0 h-100 bg-primary align-content-center d-none d-xl-flex p-3 py-3 mb-3 rounded-bottom row row-cols-xl-5"
                  style="background-color: #f6f0ff !important">
                  <div class="d-flex flex-column mb-lg-0 pe-1 d-flex col-xl">
                    <div class="text-primary fs-lg cursor-pointer text-xl-center">
                      مكتب التعليم
                    </div>
                  </div>
                  <div class="d-flex flex-column mb-lg-0 pe-1 d-flex col-xl">
                    <div class="text-primary fs-lg cursor-pointer text-xl-center">
                      إدارة التعليم
                    </div>
                  </div>
                  <div class="d-flex flex-column pe-1 justify-content-center col-xl">
                    <div class="text-primary fs-lg cursor-pointer text-xl-center">
                      متوسط الدرجة
                    </div>
                  </div>
                  <div class="d-flex flex-column pe-1 justify-content-center col-xl">
                    <div class="text-primary fs-lg cursor-pointer text-xl-center">
                      المنطقة
                    </div>
                  </div>
                  <div class="d-flex flex-column pe-1 justify-content-center col-xl">
                    <div class="text-primary fs-lg cursor-pointer text-xl-center">
                      الترتيب
                    </div>
                  </div>
                </div>
                <div class="row row-cols-xl-1 row-cols-lg-2 row-cols-1 g-xl-2 g-3 office-col">
                  <!-- table row -->
                  <div class="col" *ngFor="
                      let item of searchOfficeResult
                        | slice : 0 : recordOddiceSeed + recordShow;
                      let i = index
                    ">
                    <div class="false card border-0 shadow-primary">
                      <div class="p-3 sh-21 sh-xl-7 card-body">
                        <div class="h-100 align-items-center bg-white cursor-default row row-cols-xl-5">
                          <div
                            class="d-flex flex-column justify-content-center mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-12">
                            <div class="text-primary mb-2 fs-lg d-xl-none fw-semibold">
                              مكتب التعليم
                            </div>
                            <div class="text-alternate text-primary text-xl-center">
                              {{ item.OfficeName }}
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-12">
                            <div class="text-primary mb-2 fs-lg d-xl-none fw-semibold">
                              إدارة التعليم
                            </div>
                            <div class="text-alternate text-primary text-xl-center">
                              {{ item.EducationDepartment }}
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center align-items-xl-center align-items-start mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-6">
                            <div class="text-primary mb-2 fs-lg d-xl-none fw-semibold">
                              متوسط الدرجة الدرجة
                            </div>
                            <div class="align-items-center d-flex justify-content-center p-2 rounded-3 text-center w-xl-50 w-auto {{
                                getColor(item.Average)
                              }}">
                              <p class="m-0 text-white fw-normal fs-lg">
                                {{ item.Average }}
                              </p>
                              <!-- <button (click)="onClickOfficeMarker(item)">Click</button> -->
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-6">
                            <div class="text-primary mb-2 fs-lg d-xl-none fw-semibold">
                              المنطقة
                            </div>
                            <div class="text-alternate text-primary text-xl-center">
                              {{ item.Area }}
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column justify-content-center mb-2 pe-2 mb-xl-0 h-100 col-xl col-md-6">
                            <div class="text-primary mb-2 fs-lg d-xl-none fw-semibold">
                              الترتيب
                            </div>
                            <div class="text-alternate text-primary text-xl-center">
                              {{ item.KsaRank }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row flex-column mt-4">
                  <div class="col d-flex justify-content-center mt-4 mb-2">
                    <p class="m-0 text-primary fw-semibold fs-md">
                      {{
                      recordOddiceSeed + recordShow < searchOfficeResult.length ? recordOddiceSeed + recordShow :
                        searchOfficeResult.length }} من {{ searchOfficeResult.length }} </p>
                  </div>
                  <div class="col d-flex justify-content-center mb-2">
                    <button type="button" class="btn btn-primary px-5 fs-md fw-normal"
                      (click)="recordOddiceSeed = recordOddiceSeed + recordShow" *ngIf="
                        searchOfficeResult.length > recordShow &&
                        recordOddiceSeed + recordShow <
                          searchOfficeResult.length
                      ">
                      المزيد
                    </button>
                  </div>
                </div>
              </div>
              <!-- Solid button group -->
              <div
                class="position-fixed bottom-0 start-50 translate-middle-x bg-primary rounded-pill mb-4 p-2 d-xl-none d-block"
                style="z-index: 999">
                <div class="btn-group m-0 p-0" role="group" aria-label="Solid button group">
                  <a class="btn btn-primary text-white px-2" data-bs-toggle="offcanvas" href="#offcanvasExample1"
                    role="button" aria-controls="offcanvasExample">
                    <i class="bx bx-slider fs-lg text-white position-relative me-1"></i>
                    تصنيف حسب
                  </a>
                  <span class="d-flex justify-content-center align-items-center text-white">
                    <i class="bx bx-minus bx-rotate-90"></i>
                  </span>
                  <a href="#" class="btn btn-primary px-2" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBottomLegend">
                    <i class="bx bxs-color fs-lg text-white position-relative me-1"></i>
                    مستوي الاداء
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

<!-- map search offcanvas  -->
<div class="offcanvas offcanvas-start offcanvas-filter" tabindex="-1" id="offcanvasExample1"
  aria-labelledby="MapSearchOffcanvas">
  <div class="offcanvas-header bg-primary">
    <h5 class="offcanvas-title text-white" id="MapSearchOffcanvas">
      <i class="bx bx-slider fs-xl position-relative me-2"></i>
      خيارات البحث
    </h5>
    <button type="button" class="btn-close text-white adv-search-close" data-bs-dismiss="offcanvas"
      aria-label="Close"></button>
  </div>
  <div class="offcanvas-body bg-white">
    <div class="position-relative mb-3">
      <i class="bx bx-search text-secondary fs-xl ms-3 position-absolute top-50 start-0 translate-middle-y"></i>
      <input type="text" class="form-control text-primary ps-5 bg-white shadow-none rounded-3 w-100" id="primary2"
        placeholder="ابحث بإسم المدرسة أو الرقم الوزاري..." [(ngModel)]="txtSearch" />
    </div>
    <div class="d-flex justify-content-around position-relative mb-3">
      <div class="d-flex flex-column justify-content-center align-items-center form-check text-center px-2"
        style="padding: 0 0.5rem !important">
        <i class="bx bxs-circle text-primary lh-lg"></i>
        <!-- <img src="assets/media/icon/school-pin.svg" alt="icon" class="mb-2" width="20"> -->
        <label class="form-check-label text-primary fs-sm fw-semibold mb-1" for="topschools-check">
          الأعلى أداءً
        </label>
        <input class="form-check-input m-auto" type="checkbox" id="topschools-check" [(ngModel)]="top10Check"
          [ngModelOptions]="{ standalone: true }" (change)="onTop10Change()" />
      </div>
      <div class="d-flex flex-column justify-content-between align-items-center form-check text-center px-2"
        style="padding: 0 0.5rem !important" *ngIf="!top10Check">
        <i class="bx bxs-circle text-primary lh-lg"></i>
        <!-- <img src="assets/media/icon/school-pin.svg" alt="icon" class="mb-2" width="20"> -->
        <label class="form-check-label text-primary fs-sm fw-semibold mb-1" for="schools-check">
          مدارس
        </label>
        <input class="form-check-input m-auto mb-0" type="checkbox" id="schools-check" [(ngModel)]="schoolCheck"
          [ngModelOptions]="{ standalone: true }" />
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center form-check text-center px-2"
        style="padding: 0 0.5rem !important" *ngIf="!top10Check">
        <i class="bx bxs-up-arrow text-primary lh-lg"></i>
        <!-- <img src="assets/media/icon/office-pin.svg" alt="icon" class="mb-2" width="20"> -->
        <label class="form-check-label text-primary fs-sm fw-semibold mb-1" for="office-check">
          مكاتب التعليم
        </label>
        <input class="form-check-input m-auto" type="checkbox" id="office-check" [(ngModel)]="officeCheck"
          [ngModelOptions]="{ standalone: false }" />
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center form-check text-center px-2"
        style="padding: 0 0.5rem !important" *ngIf="!top10Check">
        <i class="bx bxs-square text-primary lh-lg"></i>
        <!-- <img src="assets/media/icon/admin-pin.svg" alt="icon" class="mb-2" width="20"> -->
        <label class="form-check-label text-primary fs-sm fw-semibold mb-1" for="admin-check">
          إدارات التعليم
        </label>
        <input class="form-check-input m-auto" type="checkbox" id="admin-check" [(ngModel)]="departmentCheck"
          [ngModelOptions]="{ standalone: false }" />
      </div>
    </div>
    <div class="accordion accordion-filter" id="accordionPanelsStayOpenExample">
      <!-- Item -->
      <div class="accordion-item border-0 rounded-3 mb-2" style="border: 1px solid var(--disable) !important">
        <h3 class="accordion-header" id="headingOne">
          <button class="accordion-button shadow-none rounded-3 p-2 fs-lg fw-normal" type="button"
            data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            العام الدراسي
          </button>
        </h3>
        <div class="accordion-collapse collapse show" id="collapseOne" aria-labelledby="headingOne"
          data-bs-parent="#accordionAlt">
          <div class="accordion-body p-2 pt-0">
            <div class="d-flex">
              <div class="me-2 mb-2" *ngFor="let li of config">
                <input class="btn-check" type="radio" name="school-year1" value="{{li.ResultYear}}" id="school-year{{li.ResultYear}}"
                  (change)="onYearChangeDynamic(li.ResultYear.toString())" [(ngModel)]="year" />
                <label class="btn btn-outline-primary px-3" for="school-year{{li.ResultYear}}"><span
                    class="mx-1">{{li.ResultYear}}</span></label>
              </div>

              <!-- <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="school-year1" value="1442" id="school-year1442"
                  (change)="onYearChange($event)" [(ngModel)]="year" />
                <label class="btn btn-outline-primary px-3" for="school-year1442"><span class="mx-1">1442</span></label>
              </div>
              <div>
                <input class="btn-check" type="radio" name="school-year1" value="1443" id="school-year1443"
                  (change)="onYearChange($event)" [(ngModel)]="year" />
                <label class="btn btn-outline-primary px-3" for="school-year1443"><span class="mx-1">1443</span></label>
              </div>
              <div>
                <input class="btn-check" type="radio" name="school-year1" value="1444" id="school-year1444"
                  (change)="onYearChange($event)" [(ngModel)]="year" />
                <label class="btn btn-outline-primary px-3" for="school-year1444"><span class="mx-1">1444</span></label>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="accordion-item border-0 rounded-3 mb-2" style="border: 1px solid var(--disable) !important">
        <h3 class="accordion-header" id="headingTwo">
          <button class="accordion-button shadow-none rounded-3 p-2 fs-lg fw-normal" type="button"
            data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
            نوع الاختبار
          </button>
        </h3>
        <div class="accordion-collapse collapse show" id="collapseTwo" aria-labelledby="headingTwo"
          data-bs-parent="#accordionAlt">
          <div class="accordion-body p-2 pt-0">
            <div class="d-flex flex-wrap">
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="exam-Type1" value="تحصيلي,علمي" checked="" id="exam-Type1"
                  [(ngModel)]="examTypeFull" (change)="onExamTypeChange($event)" />
                <label class="btn btn-outline-primary px-2" for="exam-Type1"><span class="mx-1">تحصيلي
                    علمي</span></label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="exam-Type1" value="تحصيلي,نظري" id="exam-Type2"
                  [(ngModel)]="examTypeFull" (change)="onExamTypeChange($event)" />
                <label class="btn btn-outline-primary px-2" for="exam-Type2"><span class="mx-1">تحصيلي
                    نظري</span></label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="exam-Type1" value="قدرات,علمي" id="exam-Type3"
                  [(ngModel)]="examTypeFull" (change)="onExamTypeChange($event)" />
                <label class="btn btn-outline-primary px-2" for="exam-Type3"><span class="mx-1">قدرات
                    علمي</span></label>
              </div>
              <div class="">
                <input class="btn-check" type="radio" name="exam-Type1" value="قدرات,نظري" id="exam-Type4"
                  [(ngModel)]="examTypeFull" (change)="onExamTypeChange($event)" />
                <label class="btn btn-outline-primary px-2" for="exam-Type4"><span class="mx-1">قدرات
                    نظري</span></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="accordion-item border-0 rounded-3 mb-2" style="border: 1px solid var(--disable) !important" *ngIf="hasDetailes">
        <h3 class="accordion-header" id="headingTwoTow">
          <button class="accordion-button shadow-none rounded-3 p-2 fs-lg fw-normal" type="button"
            data-bs-toggle="collapse" data-bs-target="#collapseTwoTow" aria-expanded="true"
            aria-controls="collapseTwoTow">
            نوع الدرجة
          </button>
        </h3>
        <div class="accordion-collapse collapse show" id="collapseTwoTow" aria-labelledby="headingTwoTow"
          data-bs-parent="#accordionAlt">
          <div class="accordion-body p-2 pt-0">
            <div class="d-flex flex-wrap">
              <div class="me-2 mb-2" *ngFor="let type of ExamSubType; let i = index">
                <input class="btn-check" type="radio" name="exam-sub-Type" value="{{ type }}"
                  [checked]="type == examSubType" id="exam-sub-Type{{ i }}" [(ngModel)]="examSubType" />
                <label class="btn btn-outline-primary px-2" for="exam-sub-Type{{ i }}"><span class="mx-1">
                    {{ type == "كلية" ? "الكل" : type }}</span></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="accordion-item border-0 rounded-3 mb-2" style="border: 1px solid var(--disable) !important">
        <h3 class="accordion-header" id="headingThree">
          <button class="accordion-button shadow-none rounded-3 p-2 fs-lg fw-normal" type="button"
            data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true"
            aria-controls="collapseThree">
            مستوي الاداء
          </button>
        </h3>
        <div class="accordion-collapse collapse show" id="collapseThree" aria-labelledby="headingThree"
          data-bs-parent="#accordionAlt">
          <div class="accordion-body p-2 pt-0">
            <div class="d-flex flex-wrap">
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" id="scoreLevel-all" value="0"
                  (change)="onScoreLevelChange($event)" [(ngModel)]="scoreLevel" name="score1" />
                <label class="btn btn-outline-primary px-3" for="scoreLevel-all">
                  <span>الكل</span>
                  <!-- <span class="fs-xs text-muted ms-auto">697</span> -->
                </label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" id="scoreLevel-A" value="A" (change)="onScoreLevelChange($event)"
                  [(ngModel)]="scoreLevel" name="score1" />
                <label class="btn px-3 btn-outline-Darkgreen" for="scoreLevel-A">
                  <span>85 فأكثر</span>
                  <!-- <span class="fs-xs text-muted ms-auto">10</span> -->
                </label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" id="scoreLevel-B" value="B" (change)="onScoreLevelChange($event)"
                  [(ngModel)]="scoreLevel" name="score1" />
                <label class="btn btn-outline-Midgreen" for="scoreLevel-B">
                  <span>75-84.9</span>
                  <!-- <span class="fs-xs text-muted ms-auto">20</span> -->
                </label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" id="scoreLevel-C" value="C" (change)="onScoreLevelChange($event)"
                  [(ngModel)]="scoreLevel" name="score1" />
                <label class="btn btn-outline-Lightgreen px-3" for="scoreLevel-C">
                  <span>65-74.9</span>
                  <!-- <span class="fs-xs text-muted ms-auto">30</span> -->
                </label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" id="scoreLevel-D" value="D" (change)="onScoreLevelChange($event)"
                  [(ngModel)]="scoreLevel" name="score1" />
                <label class="btn btn-outline-yellow px-3" for="scoreLevel-D">
                  <span>55-64.9</span>
                  <!-- <span class="fs-xs text-muted ms-auto">40</span> -->
                </label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" id="scoreLevel-E" value="E" (change)="onScoreLevelChange($event)"
                  [(ngModel)]="scoreLevel" name="score1" />
                <label class="btn btn-outline-orange px-3" for="scoreLevel-E">
                  <span>45-54.9</span>
                  <!-- <span class="fs-xs text-muted ms-auto">50</span> -->
                </label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" id="scoreLevel-F" value="F" (change)="onScoreLevelChange($event)"
                  [(ngModel)]="scoreLevel" name="score1" />
                <label class="btn btn-outline-red px-3" for="scoreLevel-F">
                  <span>أقل من 44.9</span>
                  <!-- <span class="fs-xs text-muted ms-auto">0</span> -->
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="accordion-item border-0 rounded-3 mb-2" style="border: 1px solid var(--disable) !important">
        <h3 class="accordion-header" id="headingFour">
          <button class="accordion-button shadow-none rounded-3 p-2 fs-lg fw-normal" type="button"
            data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
            الجنس
          </button>
        </h3>
        <div class="accordion-collapse collapse show" id="collapseFour" aria-labelledby="headingFour"
          data-bs-parent="#accordionAlt">
          <div class="accordion-body p-2 pt-0">
            <div class="d-flex">
              <div class="me-2 mb-2" *ngIf="examTypeFull != 'تحصيلي,نظري'">
                <input class="btn-check" type="radio" name="school-G1" value="M" id="school-M"
                  (change)="onGenderChange($event)" [(ngModel)]="examGender" />
                <label class="btn btn-outline-primary px-3" for="school-M"><span class="mx-1">بنين</span></label>
              </div>
              <div>
                <input class="btn-check" type="radio" name="school-G1" value="F" id="school-F"
                  (change)="onGenderChange($event)" [(ngModel)]="examGender" />
                <label class="btn btn-outline-primary px-3" for="school-F"><span class="mx-1">بنات</span></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="accordion-item border-0 rounded-3 mb-2" style="border: 1px solid var(--disable) !important">
        <h3 class="accordion-header" id="headingFive">
          <button class="accordion-button shadow-none rounded-3 p-2 fs-lg fw-normal" type="button"
            data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
            المنطقة
          </button>
        </h3>
        <div class="accordion-collapse collapse show" id="collapseFive" aria-labelledby="headingFive"
          data-bs-parent="#accordionAlt">
          <div class="accordion-body p-2 pt-0">
            <div class="d-flex flex-wrap">
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="area1" value="0" checked="" id="area-Type1"
                  (change)="onAreaChange($event)" [(ngModel)]="area" />
                <label class="btn btn-outline-primary px-2" for="area-Type1"><span class="mx-1">على مستوى المملكة
                  </span></label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="area1" value="الرياض" id="area-Type2"
                  (change)="onAreaChange($event)" [(ngModel)]="area" />
                <label class="btn btn-outline-primary px-2" for="area-Type2"><span class="mx-1">الرياض </span></label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="area1" value="مكة المكرمة" id="area-Type3"
                  (change)="onAreaChange($event)" [(ngModel)]="area" />
                <label class="btn btn-outline-primary px-2" for="area-Type3"><span class="mx-1">مكة المكرمة
                  </span></label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="area1" value="المدينة المنورة" id="area-Type4"
                  (change)="onAreaChange($event)" [(ngModel)]="area" />
                <label class="btn btn-outline-primary px-2" for="area-Type4"><span class="mx-1">المدينة المنورة
                  </span></label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="area1" value="الشرقية" id="area-Type5"
                  (change)="onAreaChange($event)" [(ngModel)]="area" />
                <label class="btn btn-outline-primary px-2" for="area-Type5"><span class="mx-1">الشرقية </span></label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="area1" value="الجوف" id="area-Type6"
                  (change)="onAreaChange($event)" [(ngModel)]="area" />
                <label class="btn btn-outline-primary px-2" for="area-Type6"><span class="mx-1">الجوف </span></label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="area1" value="الباحة" id="area-Type7"
                  (change)="onAreaChange($event)" [(ngModel)]="area" />
                <label class="btn btn-outline-primary px-2" for="area-Type7"><span class="mx-1">الباحة </span></label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="area1" value="عسير" id="area-Type8"
                  (change)="onAreaChange($event)" [(ngModel)]="area" />
                <label class="btn btn-outline-primary px-2" for="area-Type8"><span class="mx-1">عسير </span></label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="area1" value="القصيم" id="area-Type9"
                  (change)="onAreaChange($event)" [(ngModel)]="area" />
                <label class="btn btn-outline-primary px-2" for="area-Type9"><span class="mx-1">القصيم </span></label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="area1" value="حائل" id="area-Type10"
                  (change)="onAreaChange($event)" [(ngModel)]="area" />
                <label class="btn btn-outline-primary px-2" for="area-Type10"><span class="mx-1">حائل </span></label>
              </div>

              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="area1" value="تبوك" id="area-Type11"
                  (change)="onAreaChange($event)" [(ngModel)]="area" />
                <label class="btn btn-outline-primary px-2" for="area-Type11"><span class="mx-1">تبوك </span></label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="area1" value="الحدود الشمالية" id="area-Type12"
                  (change)="onAreaChange($event)" [(ngModel)]="area" />
                <label class="btn btn-outline-primary px-2" for="area-Type12"><span class="mx-1">الحدود الشمالية
                  </span></label>
              </div>
              <div class="me-2 mb-2">
                <input class="btn-check" type="radio" name="area1" value="جازان" id="area-Type13"
                  (change)="onAreaChange($event)" [(ngModel)]="area" />
                <label class="btn btn-outline-primary px-2" for="area-Type13"><span class="mx-1">جازان </span></label>
              </div>
              <div class="">
                <input class="btn-check" type="radio" name="area1" value="نجران" id="area-Type14"
                  (change)="onAreaChange($event)" [(ngModel)]="area" />
                <label class="btn btn-outline-primary px-2" for="area-Type14"><span class="mx-1">نجران </span></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="position-relative mb-2" *ngIf="!top10Check">
      <select class="form-select" id="select-input" (change)="onDepartmentChange($event)" [(ngModel)]="department">
        <option selected="true" value="0">الإدارة التعليمية</option>
        <option *ngFor="let d of DepartmentNoRepeat" value="{{ d }}">
          {{ d }}
        </option>
      </select>
    </div>
    <div class="position-relative mb-2" *ngIf="!top10Check">
      <select class="form-select" id="select-input" (change)="onOfficeChange($event)" [(ngModel)]="office">
        <option selected="true" value="0">مكتب التعليم</option>
        <option *ngFor="let o of Office" value="{{ o.Office }}">
          {{ o.Office }}
        </option>
      </select>
    </div>
    <div class="position-relative mb-3">
      <select class="form-select" id="select-input" (change)="onAuthorityChange($event)">
        <option selected="true" value="0">نوع التعليم</option>
        <option value="حكومي">حكومي</option>
        <option value="أهلي">أهلي</option>
        <option value="تعليم عالمي">تعليم عالمي</option>
        <option value="معهد مهني">معهد مهني</option>
        <option value="الهيئة الملكية">الهيئة الملكية</option>
        <option value="معهد جامعة الامام">معهد جامعة الامام</option>
        <option value="الرئاسة العامة لشؤون المسجد الحرام والمسجد النبوي">
          الرئاسة العامة لشؤون المسجد الحرام والمسجد النبوي
        </option>
      </select>
    </div>
  </div>
  <div class="offcanvas-footer d-flex bg-white p-3 border-1 border-top">
    <button class="btn btn-primary fs-lg w-100 me-2" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
      (click)="searchBtn()">
      تطبيق
    </button>
    <button class="btn btn-outline-primary fs-lg w-100" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
      (click)="clearSearchForm()">
      اعادة التعيين
    </button>
  </div>
</div>

<!-- school data offcanvas -->
<app-school-card [selectedSchool]="selectedSchool" [(showOffcanvas)]="showOffcanvas" [queryString]="queryString"
  [dynamicClass]="dynamicClass" [hasDetails]="enableDetails"></app-school-card>

<!-- department data offcanvas -->
<app-department-card [selectedDepartment]="selectedDepartment" [(showOffDeptcanvas)]="showOffDeptcanvas"
  [dynamicClass]="dynamicClass"></app-department-card>

<!-- office data offcanvas -->
<app-office-card [selectedOffice]="selectedOffice" [(showOffOfficecanvas)]="showOffOfficecanvas"
  [dynamicClass]="dynamicClass"></app-office-card>

<!-- compare School Offcanvas -->
<app-compare-card [minimize]="minimize" [comparedSchool]="comparedSchool" [(showCompare)]="showCompare"
  [showComparedSchoolRow]="showComparedSchoolRow">
</app-compare-card>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle }">
  <div class="bg-dark position-absolute w-100 h-100" style="opacity: 0.75"></div>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <h4 class="modal-title">لايطبق الإختبار</h4>
      </div> -->
      <div class="modal-body">
        <h4 class="h4 text-primary text-center m-0">لايطبق الاختبار</h4>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="btn btn-danger w-50" (click)="closePopup()">
          إغلاق
        </button>
      </div>
    </div>
  </div>
</div>

<!-- legend offcanvas -->
<div class="offcanvas offcanvas-bottom" id="offcanvasBottomLegend" tabindex="-1">
  <div class="offcanvas-header bg-primary border-bottom">
    <h5 class="offcanvas-title text-white fs-lg">
      مستوى الأداء حسب متوسط درجات الطلبة في المدرسة
    </h5>
    <button class="btn btn-outline-warning btn-icon text-white border-0 adv-search-close" type="button"
      data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <div class="d-flex">
      <span
        class="badge bg-primary rounded fs-sm fw-normal p-2 me-2 mb-2 lh-sm d-inline-block w-100 bg-indicator-Darkgreen">85
        فأكثر</span>
      <span
        class="badge bg-primary rounded fs-sm fw-normal p-2 me-2 mb-2 lh-sm d-inline-block w-100 bg-indicator-Midgreen">75-84.9</span>
      <span
        class="badge bg-primary rounded fs-sm fw-normal p-2 mb-2 lh-sm d-inline-block w-100 bg-indicator-Lightgreen">65-74.9</span>
    </div>
    <div class="d-flex">
      <span
        class="badge bg-primary rounded fs-sm fw-normal p-2 me-2 lh-sm mb-2 d-inline-block w-100 bg-indicator-yellow">55-64.9</span>
      <span
        class="badge bg-primary rounded fs-sm fw-normal p-2 me-2 mb-2 lh-sm d-inline-block w-100 bg-indicator-orange">45-54.9</span>
      <span
        class="badge bg-primary rounded fs-sm fw-normal p-2 me-0 mb-2 lh-sm d-inline-block w-100 bg-indicator-red">أقل
        من 44.9</span>
    </div>
  </div>
</div>

<!-- 
<div class="offcanvas cookie show offcanvas-bottom bg-secondary border-0" tabindex="-1" id="offcanvasBottom"
  aria-labelledby="offcanvasBottomLabel" data-bs-scroll="true" data-bs-backdrop="false"
  style="opacity: 95%;height:auto;" *ngIf="!isAgree">


  <div class="offcanvas-body p-3">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-12">
          <h5 class="text-primary">
            الآن بإمكانك الاطلاع على تفاصيل أكثر على مستوى المجالات والفروع من خلال "نوع الاختبار و نوع الدرجة" في
            خيارات البحث</h5>
          <a class="text-decoration-underline text-primary mb-lg-0 mb-2 d-block" target="_blank" href="./privacy"></a>
        </div>
        <div class="col-lg-3 col-12 d-flex justify-content-lg-end justify-content-center align-items-center">
          <button type="button" class="btn btn-light btn-md" data-bs-dismiss="offcanvas" aria-label="Close"
            style="width:6rem;" (click)="setCookiefooter()">موافق </button>
        </div>
      </div>
    </div>
  </div>
</div> -->