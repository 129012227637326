import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SchoolList } from '../model/SchoolList';
import { ApiService } from '../api.service.service';

@Component({
  selector: 'app-school-card',
  templateUrl: './school-card.component.html',
  styleUrls: ['./school-card.component.css']
})
export class SchoolCardComponent implements OnInit {

  @Input() selectedSchool! : SchoolList ;
  @Input() showOffcanvas! : string;
  @Output() showOffcanvasChange = new EventEmitter<string>();
  @Input() dynamicClass!: string;
  @Input() queryString!: string;
  @Input() visible='';
  @Input() hasDetails = false;
  showCanvasLocal='';
  mno = '';

  marks: any[] =[];
  constructor(private apiService: ApiService) { 
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.showCanvasLocal = this.showOffcanvas;
    this.visible =  this.showCanvasLocal=='show'?'visible':'hidden';
    this.mno = this.selectedSchool?.MNO;
    
    if(this.hasDetails &&  this.selectedSchool){
      this.apiService
      .getSchoolMarkByMno(
        this.mno,
        'School',
        Number( this.selectedSchool?.Year),
        this.selectedSchool?.Gender,
        this.selectedSchool?.ExamType,
        this.selectedSchool?.ExamSpecial
      )
  
      .subscribe((data) => {
        this.marks = data.filter((m :any) => m.MarkType != 'كلية');
      });
    }
  }

  hideCard(){
    this.showCanvasLocal = '';
    this.showOffcanvasChange.emit('');
  }

  getLevelColor(average: string): string{
    if (Number(average) >= 85) {
      return 'bg-indicator-Darkgreen';
    } else if (Number(average) >= 75 && Number(average) < 85) {
      return 'bg-indicator-Midgreen';
    } else if (Number(average) >= 65 && Number(average) < 75) {
      return'bg-indicator-Lightgreen';
    } else if (Number(average) >= 55 && Number(average) < 65) {
      return 'bg-indicator-yellow';
    } else if (Number(average) >= 45 && Number(average) < 55) {
      return 'bg-indicator-orange';
    } else if (Number(average) >= 0 && Number(average) < 45) {
      return 'bg-indicator-red';
    }

    return '';
  }
}
