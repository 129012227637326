<!-- breadcrumb -->
<section class="fx-breadcrumb fixed-top position-relative  w-100 d-flex align-items-center bg-light mb-0">
    <div class="container position-relative zindex-5 py-3">
        <div class="row justify-content-center">
            <nav class="" aria-label="breadcrumb">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                        <a href="#" class="fs-lg fw-normal">الرئيسية</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="#" class="fs-lg fw-normal">مؤشر القدرات والتحصيلي</a>
                    </li>
                    <li class="breadcrumb-item fs-lg fw-normal active" aria-current="page">الملف التعريفي للمدرسة
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</section>
<section class="container my-4 min-vh-100">
    <!-- School name,info,location -->
    <div class="row d-flex g-4 my-lg-4">
        <div class="col-xl-6 col-12 d-flex flex-column">
            <div class="order-2 d-flex flex-row mt-3">
                <!-- card -->
                <div class="d-inline-flex me-3 w-100 card">
                    <div class="g-0 sh-17 sh-lg-10 row">
                        <div class="col">
                            <div class="h-100 px-4 py-3 card-body">
                                <div class="g-0 h-100 align-content-center row">
                                    <div class="d-flex flex-column mb-lg-0 mb-2 mb-lg-0 pe-3 d-flex col-lg-6">
                                        <div class="text-primary fs-lg">نوع التعليم</div>
                                    </div>
                                    <div class="d-flex flex-column pe-1 align-items-lg-end col-lg-6">
                                        <div class="text-primary fw-semibold fs-lg">
                                            {{selectedSchool?.Authority}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- card -->
                <div class="d-inline-flex w-100 card">
                    <div class="g-0 sh-17 sh-lg-10 row">
                        <div class="col">
                            <div class="h-100 px-4 py-3 card-body">
                                <div class="g-0 h-100 align-content-center row">
                                    <div class="d-flex flex-column mb-lg-0 mb-2 mb-lg-0 pe-3 d-flex col-lg-6">
                                        <div class="text-primary fs-lg">نوع الجنس</div>
                                    </div>
                                    <div class="d-flex flex-column pe-1 align-items-lg-end col-lg-6">
                                        <div class="text-primary fw-semibold fs-lg">
                                            {{selectedSchool?.Gender =='M'?'بنين':'بنات'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-1">
                <!-- card -->
                <div class="mb-3 card">
                    <div class="g-0 row">
                        <div class="col">
                            <div class="h-100 px-4 py-3 card-body">
                                <div class="g-0 h-100 align-content-center row">
                                    <div class="d-flex flex-column mb-lg-0 mb-2 mb-lg-0 pe-3 d-flex col-lg-5">
                                        <div class="text-primary fs-lg">اسم المدرسة</div>
                                    </div>
                                    <div class="d-flex flex-column pe-1 align-items-lg-end col-lg-7">
                                        <div class="text-primary fw-semibold fs-lg">
                                            {{selectedSchool?.Name}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- card -->
                <div class="mb-3 card">
                    <div class="g-0 row">
                        <div class="col">
                            <div class="h-100 px-4 py-3 card-body">
                                <div class="g-0 h-100 align-content-center row">
                                    <div class="d-flex flex-column mb-lg-0 mb-2 mb-lg-0 pe-3 d-flex col-lg-5">
                                        <div class="text-primary fs-lg">الإدارة التعليمية</div>
                                    </div>
                                    <div class="d-flex flex-column pe-1 align-items-lg-end col-lg-7">
                                        <div class="text-primary fw-semibold fs-lg">
                                            {{selectedSchool?.Department}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- card -->
                <div class="mb-0 card">
                    <div class="g-0 row">
                        <div class="col">
                            <div class="h-100 px-4 py-3 card-body">
                                <div class="g-0 h-100 align-content-center row">
                                    <div class="d-flex flex-column mb-lg-0 mb-2 mb-lg-0 pe-3 d-flex col-lg-5">
                                        <div class="text-primary fs-lg">مكتب التعليم</div>
                                    </div>
                                    <div class="d-flex flex-column pe-1 align-items-lg-end col-lg-7">
                                        <div class="text-primary fw-semibold fs-lg">
                                            {{selectedSchool?.Office}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-12 d-flex flex-column">
            <div class="order-2 d-flex flex-row mt-3">
                <button type="button" class="btn btn-primary w-100 h-100 px-4 me-3 fs-lg" style="padding: 10px 0;">
                    <i class="bx bx-map fs-4 pt-1 me-2"></i>
                    توجه للموقع
                </button>
                <button type="button" class="btn btn-info w-100 h-100 px-4 fs-lg"
                    style="background-color: #00ACEE !important;padding: 10px 0;">
                    <i class="bx bxl-twitter fs-4 pt-1 me-2"></i>
                    شارك على
                </button>
            </div>
            <div class="order-1 h-100">
                <div class="false w-100 h-100 card">
                    <div class="g-0 h-100 row">
                        <div class="col-12 mb-0">
                            <iframe class="w-100 h-100" [src]="url" allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- tablist -->
    <ul role="tablist" class="nav nav-tabs general-aptitude row row-cols-3 row-cols-xl-4 g-3 mt-5">
        <li
            class="nav-item m-0 pb-xl-0 pb-3 text-primary fs-lg fw-semibold d-flex justify-content-center align-items-center col-xl col-12">
            <button class="btn btn-outline-primary fs-lg border-0" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                <i class="bx bx-slider  fs-4 me-2"></i>
                خيارات البحث
            </button>
        </li>
        <li class="nav-item m-0 pb-xl-0 pb-3 d-flex col">
            <a href="#data" id="pills-data" data-bs-toggle="pill" role="tab" aria-controls="data" aria-selected="true"
                class="nav-link justify-content-center text-center w-100 bg-white fs-lg d-flex flex-lg-row flex-column active">
                <i class="bx bx-file fs-4 me-lg-2"></i>
                البيانات
            </a>
        </li>
        <li class="nav-item m-0 pb-xl-0 pb-3 d-flex col">
            <a href="#timeline" id="pills-timeline" data-bs-toggle="pill" role="tab" aria-controls="timeline"
                aria-selected="false"
                class="nav-link justify-content-center text-center w-100 fs-lg d-flex flex-lg-row flex-column bg-white">
                <i class="bx bx-list-ul fs-4 me-lg-2"></i>
                المخطط الزمني
            </a>
        </li>
        <li class="nav-item m-0 pb-xl-0 pb-3 d-flex col">
            <a href="#chart" id="pills-chart" data-bs-toggle="pill" role="tab" aria-controls="chart"
                aria-selected="false"
                class="nav-link justify-content-center text-center w-100 fs-lg d-flex flex-lg-row flex-column bg-white">
                <i class="bx bx-line-chart fs-4 me-lg-2"></i>
                الرسوم
                البيانية
            </a>
        </li>
    </ul>
    <!-- result filter -->
    <div class="row flex-wrap ps-0 pe-0 my-3">
        <div
            class="m-0 pb-xl-0 pb-3 text-primary fs-lg fw-semibold d-flex justify-content-center align-items-center col-xl-3 col-12">
            <div class="form-floating w-100 mx-xl-4">
                <select class="form-select" id="fl-select">
                    <option selected>المرتفع - الأقل (تنازلي)</option>
                    <option>الأقل - المرتفع (تصاعدي)</option>
                    <option>الأحدث</option>
                </select>
                <label for="fl-select">الترتيب بحسب</label>
            </div>
        </div>
        <div
            class="m-0 pb-xl-0 pb-3 text-primary fs-lg fw-semibold d-flex flex-wrap justify-content-start align-items-center d-flex col-xl-9 col-12">
            <span class="badge bg-lpurple fw-normal text-primary  px-4 p-2 me-2 mb-xl-0 mb-2">
                1442 هـ
                <button class="btn-close fs-xs ms-2" type="button" data-bs-dismiss="toast" aria-label="Close"></button>
            </span>
            <span class="badge bg-lpurple fw-normal text-primary  px-4 p-2 me-2 mb-xl-0 mb-2">
                1441 هـ
                <button class="btn-close fs-xs ms-2" type="button" data-bs-dismiss="toast" aria-label="Close"></button>
            </span>
            <span class="badge bg-lpurple fw-normal text-primary  px-4 p-2 me-2 mb-xl-0 mb-2">
                قدرات علمي
                <button class="btn-close fs-xs ms-2" type="button" data-bs-dismiss="toast" aria-label="Close"></button>
            </span>
            <span class="badge bg-lpurple fw-normal text-primary px-4 p-2 me-2 mb-xl-0 mb-2">قدرات نظري
                <button class="btn-close fs-xs ms-2" type="button" data-bs-dismiss="toast" aria-label="Close"></button>
            </span>

        </div>
    </div>

    <!-- tabContent -->
    <div class="tab-content pt-2 py-4">
        <!-- DATA -->
        <div class="tab-pane fade active show" id="data" role="tabpanel" aria-labelledby="pills-data">
            <div class="row row-cols-xl-2 row-cols-1 g-4">
                <div class="col"  *ngFor="let mark of AllSchoolMarksMain">
                    <!-- CARD -->
                    <div class="h-100 card border-0 bg-white p-0 shadow-primary"
                       >
                        <div class="d-flex flex-column justify-content-start card-body p-3">
                            <!-- title -->
                            <p class="fw-semibold fs-xl text-primary text-center mb-4">
                                <i class="bx bx-bar-chart-alt-2 fs-4 me-2 p-1 px-2 rounded-3 bg-primary text-white"></i>
                                {{mark.ExamType}} {{mark.ExamSpecial}} {{mark.MarkType != 'كلية' ? mark.MarkType :
                                ''}}
                            </p>
                            <!-- date -->
                            <div class="row row-cols-1 g-0">
                                <!-- card -->
                                <div class="col d-flex">
                                    <div class="mb-2 border-0 bg-primary card py-3 px-4"
                                        style=" border-radius: 0 0.5rem 0.5rem 0px !important;">
                                        <div
                                            class="card-body text-white p-2 d-flex align-items-center justify-content-between">
                                            {{mark.Year}}هـ
                                        </div>
                                    </div>
                                    <div class=" {{getColor(mark.Average)}} mb-2 border-0 card w-100 py-3"
                                        style=" border-radius: 0.5rem 0 0 0.5rem !important;">
                                        <div class="card-body p-2 d-flex align-items-center justify-content-between">
                                            <p class="fs-lg mb-0 text-white text-center ms-2">
                                                مؤشر الأداء
                                                <span class="fs-lg ms-1 fw-semibold">
                                                    {{mark.Average}}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- ranking -->
                            <div class="row row-cols-xl-3 row-cols-1 g-0">
                                <!-- first -->
                                <div class="col d-flex">
                                    <div class="card w-100 bg-lpurple border-0 mb-2 me-xl-2 me-0">
                                        <div class="card-body p-2 d-flex align-items-center justify-content-between">
                                            <div class="flex-shrink-0 p-2 text-start d-none">
                                                <img src="assets/media/icon/saudi-icon.svg" alt="icon" width="35">
                                            </div>
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-primary">الترتيب على مستوى المملكة </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end">
                                                <p class="m-0 fs-lg text-primary fw-semibold">{{mark?.KsaRank}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- secound -->
                                <div class="col d-flex">
                                    <div class="card w-100 bg-lpurple border-0 mb-2 me-xl-2 me-0">
                                        <div class="card-body p-2 d-flex align-items-center justify-content-between">
                                            <div class="flex-shrink-0 p-2 text-start d-none">
                                                <img src="assets/media/icon/admin-building.svg" alt="icon" width="32">
                                            </div>
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-primary">الترتيب على مستوى إدارات التعليم </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end">
                                                <p class="m-0 fs-lg text-primary fw-semibold">{{mark?.DeptRank}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- third -->
                                <div class="col d-flex">
                                    <div class="card w-100 bg-lpurple border-0 mb-2">
                                        <div class="card-body p-2 d-flex align-items-center justify-content-between">
                                            <div class="flex-shrink-0 p-2 text-start d-none">
                                                <img src="assets/media/icon/office-building.svg" alt="icon" width="31">
                                            </div>
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-primary">الترتيب على مستوى مكتب التعليم </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end">
                                                <p class="m-0 fs-lg text-primary fw-semibold">{{mark?.OffficeRak}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- average -->
                            <div class="d-flex flex-row justify-content-between px-0 pt-0 pb-2 w-100">
                                <div class="badge bg-lpurple w-100 text-primary fs-lg fw-normal py-2 rounded-12 me-2">
                                    <p class="text-primary mb-2 fs-lg">متوسط المكتب</p>
                                    <p class="text-primary fs-lg fw-semibold mb-0">{{mark?.OfficeAvg}}</p>
                                </div>
                                <div class="badge bg-lpurple w-100 text-primary fs-lg fw-normal rounded-12 py-2">
                                    <p class="text-primary mb-2 fs-lg">متوسط الإدارة</p>
                                    <p class="text-primary fs-lg fw-semibold mb-0">{{mark?.DeptAvg}}</p>
                                </div>
                            </div>
                            <!-- table -->
                            <div class="row">
                                <div class="col">
                                    <div class="table-responsive rounded-3">
                                        <table class="table fs-sm etec-table">
                                            <thead>
                                                <tr>
                                                    <th rowspan="2" class="rounded-start fs-sm fw-normal">المادة</th>
                                                    <th rowspan="2" class="fs-sm fw-normal"
                                                        style="background-color: #5D54A3;">المؤشر
                                                    </th>
                                                    <th colspan="3" class="fs-sm fw-normal">ترتيب</th>
                                                    <th colspan="2" class="fs-sm fw-normal"
                                                        style="background-color: #5D54A3;">متوسط
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th class="fs-sm fw-normal " style="background-color: #443984;">
                                                        المملكة</th>
                                                    <th class="fs-sm fw-normal" style="background: #3f347c;">الادارة
                                                    </th>
                                                    <th class="fs-sm fw-normal" style="background-color: #443984;">
                                                        المكتب</th>
                                                    <th class="fs-sm fw-normal" style="background-color: #504793;">مكتب
                                                    </th>
                                                    <th class="fs-sm fw-normal" style="background: #453b85;">ادارة</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr  *ngFor="let markInner of getInnerMarks(mark.ExamType, mark.ExamSpecial)">
                                                    <td class="fs-sm fw-normal {{getLevelColor(markInner.Average)}}"> {{markInner.MarkType}}</td>
                                                    <td class="fs-sm fw-normal">{{markInner?.Average}}</td>
                                                    <td class="fs-sm fw-normal">{{markInner?.KsaRank}}</td>
                                                    <td class="fs-sm fw-normal">{{markInner?.DeptRank}}</td>
                                                    <td class="fs-sm fw-normal">{{markInner?.OffficeRak}}</td>
                                                    <td class="fs-sm fw-normal">{{markInner?.OfficeAvg}}</td>
                                                    <td class="fs-sm fw-normal">{{markInner?.DeptAvg}}</td>
                                                </tr><!---->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- card 1 -->
                <!-- <div class="col" *ngFor="let mark of AllSchoolMarksMain">
                    <div class="card bg-white shadow-lg border-0 rounded-12 text-center p-0">
                        <div class="card-header border-0 rounded-12 my-2 w-100  ">
                            <div
                                class="d-flex flex-column text-white mx-4 p-3 rounded-12 {{getLevelColor(mark.Average)}}">
                                <p class="fw-semibold fs-xl text-white text-center mb-3">
                                    {{mark.ExamType}} {{mark.ExamSpecial}} {{mark.MarkType != 'كلية' ? mark.MarkType :
                                    ''}}
                                    <span class="fs-lg ms-1 fw-semibold">
                                        {{mark.Year}} هـ
                                    </span>
                                </p>
                                <div class="d-flex flex-row justify-content-center align-items-center text-white mb-0">
                                    <i
                                        class="bx bx-bar-chart-alt-2 fs-4 me-2 p-1 px-2 rounded-12 {{getLevelColor(mark.Average)}}"></i>
                                    <p class="fs-lg mb-0 text-white text-center">
                                        مؤشر الأداء
                                        <span class="fs-lg ms-1 fw-semibold">
                                            {{mark.Average}}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pt-0">
                            <div class="px-0 pt-0 pb-2">
                                <div class="card bg-lpurple border-0 mb-2">
                                    <div class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                        <div class="flex-shrink-0 p-2 text-start"><img
                                                src="assets/media/icon/saudi-icon.svg" alt="icon" width="35"></div>
                                        <div class="px-0 w-50">
                                            <p class="m-0 fs-lg text-primary">الترتيب على مستوى
                                                المملكة {{mark.MarkType != 'كلية' ? mark.MarkType : ''}}</p>
                                        </div>
                                        <div class="flex-shrink-0 p-2 text-end w-25">
                                            <p class="m-0 fs-lg text-primary fw-semibold">{{mark?.KsaRank}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card bg-lpurple border-0 mb-2">
                                    <div class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                        <div class="flex-shrink-0 p-2 text-start"><img
                                                src="assets/media/icon/admin-building.svg" alt="icon" width="32"></div>
                                        <div class="px-0 w-50">
                                            <p class="m-0 fs-lg text-primary">الترتيب على مستوى إدارات
                                                التعليم {{mark.MarkType != 'كلية' ? mark.MarkType : ''}}</p>
                                        </div>
                                        <div class="flex-shrink-0 p-2 text-end w-25">
                                            <p class="m-0 fs-lg text-primary fw-semibold">{{mark?.DeptRank}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card bg-lpurple border-0">
                                    <div class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                        <div class="flex-shrink-0 p-2 text-start"><img
                                                src="assets/media/icon/office-building.svg" alt="icon" width="31"></div>
                                        <div class="px-0 w-50">
                                            <p class="m-0 fs-lg text-primary">الترتيب على مستوى مكتب
                                                التعليم {{mark.MarkType != 'كلية' ? mark.MarkType : ''}}</p>
                                        </div>
                                        <div class="flex-shrink-0 p-2 text-end w-25">
                                            <p class="m-0 fs-lg text-primary fw-semibold">{{mark?.OffficeRak}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-row justify-content-between px-0 pt-0">
                                <div class="badge bg-lpurple w-100 text-primary fs-lg fw-normal py-2 rounded-12 me-2">
                                    <p class="text-primary mb-2 fs-lg">متوسط المكتب</p>
                                    <p class="text-primary fs-lg fw-semibold mb-0">{{mark?.OfficeAvg}}</p>
                                </div>
                                <div class="badge bg-lpurple w-100 text-primary fs-lg fw-normal rounded-12 py-2">
                                    <p class="text-primary mb-2 fs-lg">متوسط الإدارة</p>
                                    <p class="text-primary fs-lg fw-semibold mb-0">{{mark?.DeptAvg}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> -->

                <!-- <div class="col" *ngFor="let mark of AllSchoolMarksSub">
                    <div class="card bg-white shadow-lg border-0 rounded-12 text-center p-0">
                        <div class="card-header border-0 rounded-12 my-2 w-100  ">
                            <div
                                class="d-flex flex-column text-white mx-4 p-3 rounded-12 {{getLevelColor(mark.Average)}}">
                                <p class="fw-semibold fs-xl text-white text-center mb-3">
                                    {{mark.ExamType}} {{mark.ExamSpecial}} {{mark.MarkType}}
                                    <span class="fs-lg ms-1 fw-semibold">
                                        {{mark.Year}} هـ
                                    </span>
                                </p>
                                <div class="d-flex flex-row justify-content-center align-items-center text-white mb-0">
                                    <i
                                        class="bx bx-bar-chart-alt-2 fs-4 me-2 p-1 px-2 rounded-12 {{getLevelColor(mark.Average)}}"></i>
                                    <p class="fs-lg mb-0 text-white text-center">
                                        مؤشر الأداء
                                        <span class="fs-lg ms-1 fw-semibold">
                                            {{mark.Average}}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pt-0">
                            <div class="px-0 pt-0 pb-2">
                                <div class="card bg-lpurple border-0 mb-2">
                                    <div class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                        <div class="flex-shrink-0 p-2 text-start"><img
                                                src="assets/media/icon/saudi-icon.svg" alt="icon" width="35"></div>
                                        <div class="px-0 w-50">
                                            <p class="m-0 fs-lg text-primary">الترتيب على مستوى
                                                المملكة {{mark.MarkType != 'كلية' ? mark.MarkType : ''}}</p>
                                        </div>
                                        <div class="flex-shrink-0 p-2 text-end w-25">
                                            <p class="m-0 fs-lg text-primary fw-semibold">{{mark?.KsaRank}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card bg-lpurple border-0 mb-2">
                                    <div class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                        <div class="flex-shrink-0 p-2 text-start"><img
                                                src="assets/media/icon/admin-building.svg" alt="icon" width="32"></div>
                                        <div class="px-0 w-50">
                                            <p class="m-0 fs-lg text-primary">الترتيب على مستوى إدارات
                                                التعليم {{mark.MarkType != 'كلية' ? mark.MarkType : ''}}</p>
                                        </div>
                                        <div class="flex-shrink-0 p-2 text-end w-25">
                                            <p class="m-0 fs-lg text-primary fw-semibold">{{mark?.DeptRank}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card bg-lpurple border-0">
                                    <div class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                        <div class="flex-shrink-0 p-2 text-start"><img
                                                src="assets/media/icon/office-building.svg" alt="icon" width="31"></div>
                                        <div class="px-0 w-50">
                                            <p class="m-0 fs-lg text-primary">الترتيب على مستوى مكتب
                                                التعليم {{mark.MarkType != 'كلية' ? mark.MarkType : ''}}</p>
                                        </div>
                                        <div class="flex-shrink-0 p-2 text-end w-25">
                                            <p class="m-0 fs-lg text-primary fw-semibold">{{mark?.OffficeRak}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-row justify-content-between px-0 pt-0">
                                <div class="badge bg-lpurple w-100 text-primary fs-lg fw-normal py-2 rounded-12 me-2">
                                    <p class="text-primary mb-2 fs-lg">متوسط المكتب</p>
                                    <p class="text-primary fs-lg fw-semibold mb-0">{{mark?.OfficeAvg}}</p>
                                </div>
                                <div class="badge bg-lpurple w-100 text-primary fs-lg fw-normal rounded-12 py-2">
                                    <p class="text-primary mb-2 fs-lg">متوسط الإدارة</p>
                                    <p class="text-primary fs-lg fw-semibold mb-0">{{mark?.DeptAvg}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> -->

            </div>
        </div>
        <!-- TIMELINE -->
        <div class="tab-pane fade" id="timeline" role="tabpanel" aria-labelledby="pills-timeline">
            <!-- date left -->
            <div class="g-0 row">
                <div
                    class="fs-xl justify-content-start align-items-center text-primary fw-semibold d-none d-lg-flex order-lg-3 col">
                    1441 هـ
                </div>
                <div
                    class="wholeline-width d-flex flex-column justify-content-center align-items-center position-relative me-4 ms-0 ms-lg-4 order-lg-2 col-auto">
                    <div class="w-100 d-flex h-100"></div>
                    <div class="timeline-dgreen shadow d-flex flex-shrink-0 justify-content-center align-items-center mt-n2 position-relative rounded-circle"
                        style="width: 1.8rem !important; height: 1.8rem !important;">
                        <div class="bg-white rounded-circle"
                            style="width: 1.2rem !important;height: 1.2rem !important;">
                            <!-- <div
            										class="text-white d-flex justify-content-center align-items-center h-100 rounded-circle">
            										02
            									</div> -->
                        </div>
                    </div>
                    <div class="w-100 d-flex h-100 justify-content-center position-relative">
                        <div class="timeline-width bg-separator h-100 position-absolute"></div>
                    </div>
                </div>
                <div class="mb-2 order-lg-1 col">
                    <div class="h-100 card border-0 timeline-dgreen">
                        <div class="d-flex flex-column justify-content-start card-body">
                            <!-- mobile date -->
                            <div class="text-center text-white fs-lg fw-semibold d-lg-none mb-2">1441 هـ</div>
                            <div class="d-flex flex-column">
                                <p class="fw-semibold fs-xl text-white text-center mb-2">قدرات نظري</p>
                                <div class="d-flex flex-row justify-content-center align-items-center text-white mb-3">
                                    <i
                                        class="bx bx-bar-chart-alt-2 fs-4 me-2 p-1 px-2 rounded-3 timeline-card-dgreen"></i>
                                    <p class="fs-lg mb-0 text-white text-center">
                                        مؤشر الأداء
                                        <span class="fs-lg ms-1 fw-semibold">
                                            87.42
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <!-- card -->
                                    <div class="mb-2 border-0 timeline-card-dgreen card">
                                        <div
                                            class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-white">
                                                    الترتيب على مستوى المملكة
                                                </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end w-25">
                                                <p class="m-0 fs-lg text-white fw-semibold">2</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="mb-2 border-0 timeline-card-dgreen card">
                                        <div
                                            class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-white">
                                                    الترتيب على مستوى إدارات التعليم
                                                </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end w-25">
                                                <p class="m-0 fs-lg text-white fw-semibold">2</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="mb-2 border-0 timeline-card-dgreen card">
                                        <div
                                            class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-white">
                                                    الترتيب على مستوى مكتب التعليم
                                                </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end w-25">
                                                <p class="m-0 fs-lg text-white fw-semibold">2</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="d-flex flex-row justify-content-between px-0 pt-0 pb-2">
                                        <div class="badge timeline-card-dgreen w-100 fs-lg fw-normal py-2 me-3">
                                            <p class="text-white mb-2 fs-lg">متوسط المكتب</p>
                                            <p class="text-white fs-lg fw-semibold mb-0">87.425</p>
                                        </div>
                                        <div class="badge timeline-card-dgreen w-100 fs-lg fw-normal py-2">
                                            <p class="text-white mb-2 fs-lg">متوسط الإدارة</p>
                                            <p class="text-white fs-lg fw-semibold mb-0">87.425</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- date right  -->
            <div class="g-0 row">
                <div
                    class="fs-xl mb-2 justify-content-end align-items-center text-primary fw-semibold d-none d-lg-flex col">
                    1442 هـ
                </div>
                <div
                    class="wholeline-width d-flex flex-column justify-content-center align-items-center position-relative me-4 ms-0 ms-lg-4 col-auto">
                    <div class="w-100 d-flex h-100 justify-content-center position-relative">
                        <div class="timeline-width bg-separator h-100 position-absolute"></div>
                    </div>
                    <div class="timeline-lgreen shadow d-flex flex-shrink-0 justify-content-center align-items-center mt-n2 position-relative rounded-circle"
                        style="width: 1.8rem !important; height: 1.8rem !important;">
                        <div class="bg-white rounded-circle"
                            style="width: 1.2rem !important;height: 1.2rem !important;">
                            <!-- <div
            										class="text-white d-flex justify-content-center align-items-center h-100 rounded-circle">
            										01</div> -->
                        </div>
                    </div>
                    <div class="w-100 d-flex h-100 justify-content-center position-relative">
                        <div class="timeline-width bg-separator h-100 position-absolute"></div>
                    </div>
                </div>
                <div class="mb-2 col">
                    <div class="h-100 card border-0 timeline-lgreen">
                        <div class="d-flex flex-column justify-content-start card-body">
                            <!-- mobile date -->
                            <div class="text-center text-white fs-lg fw-semibold d-lg-none mb-2">1442 هـ</div>
                            <div class="d-flex flex-column">
                                <p class="fw-semibold fs-xl text-white text-center mb-2">قدرات نظري</p>
                                <div class="d-flex flex-row justify-content-center align-items-center text-white mb-3">
                                    <i
                                        class="bx bx-bar-chart-alt-2 fs-4 me-2 p-1 px-2 rounded-3 timeline-card-lgreen"></i>
                                    <p class="fs-lg mb-0 text-white text-center">
                                        مؤشر الأداء
                                        <span class="fs-lg ms-1 fw-semibold">
                                            87.42
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <!-- card -->
                                    <div class="mb-2 border-0 timeline-card-lgreen card">
                                        <div
                                            class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-white">
                                                    الترتيب على مستوى المملكة
                                                </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end w-25">
                                                <p class="m-0 fs-lg text-white fw-semibold">2</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="mb-2 border-0 timeline-card-lgreen card">
                                        <div
                                            class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-white">
                                                    الترتيب على مستوى إدارات التعليم
                                                </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end w-25">
                                                <p class="m-0 fs-lg text-white fw-semibold">2</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="mb-2 border-0 timeline-card-lgreen card">
                                        <div
                                            class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-white">
                                                    الترتيب على مستوى مكتب التعليم
                                                </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end w-25">
                                                <p class="m-0 fs-lg text-white fw-semibold">2</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="d-flex flex-row justify-content-between px-0 pt-0 pb-2">
                                        <div class="badge timeline-card-lgreen w-100 fs-lg fw-normal py-2 me-3">
                                            <p class="text-white mb-2 fs-lg">متوسط المكتب</p>
                                            <p class="text-white fs-lg fw-semibold mb-0">87.425</p>
                                        </div>
                                        <div class="badge timeline-card-lgreen w-100 fs-lg fw-normal py-2">
                                            <p class="text-white mb-2 fs-lg">متوسط الإدارة</p>
                                            <p class="text-white fs-lg fw-semibold mb-0">87.425</p>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- date left -->
            <div class="g-0 row">
                <div
                    class="fs-xl justify-content-start align-items-center text-primary fw-semibold d-none d-lg-flex order-lg-3 col">
                    1441 هـ
                </div>
                <div
                    class="wholeline-width d-flex flex-column justify-content-center align-items-center position-relative me-4 ms-0 ms-lg-4 order-lg-2 col-auto">
                    <div class="w-100 d-flex h-100 justify-content-center position-relative">
                        <div class="timeline-width bg-separator h-100 position-absolute"></div>
                    </div>
                    <div class="timeline-orange shadow d-flex flex-shrink-0 justify-content-center align-items-center mt-n2 position-relative rounded-circle"
                        style="width: 1.8rem !important; height: 1.8rem !important;">
                        <div class="bg-white rounded-circle"
                            style="width: 1.2rem !important;height: 1.2rem !important;">
                            <!-- <div
            										class="text-white d-flex justify-content-center align-items-center h-100 rounded-circle">
            										02
            									</div> -->
                        </div>
                    </div>
                    <div class="w-100 d-flex h-100 justify-content-center position-relative">
                        <div class="timeline-width bg-separator h-100 position-absolute"></div>
                    </div>
                </div>
                <div class="mb-2 order-lg-1 col">
                    <div class="h-100 card border-0 timeline-orange">
                        <div class="d-flex flex-column justify-content-start card-body">
                            <!-- mobile date -->
                            <div class="text-center text-white fs-lg fw-semibold d-lg-none mb-2">1441 هـ</div>
                            <div class="d-flex flex-column">
                                <p class="fw-semibold fs-xl text-white text-center mb-2">تحصيلي علمي</p>
                                <div class="d-flex flex-row justify-content-center align-items-center text-white mb-3">
                                    <i
                                        class="bx bx-bar-chart-alt-2 fs-4 me-2 p-1 px-2 rounded-3 timeline-card-orange"></i>
                                    <p class="fs-lg mb-0 text-white text-center">
                                        مؤشر الأداء
                                        <span class="fs-lg ms-1 fw-semibold">
                                            87.42
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <!-- card -->
                                    <div class="mb-2 border-0 timeline-card-orange card">
                                        <div
                                            class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-white">
                                                    الترتيب على مستوى المملكة
                                                </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end w-25">
                                                <p class="m-0 fs-lg text-white fw-semibold">2</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="mb-2 border-0 timeline-card-orange card">
                                        <div
                                            class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-white">
                                                    الترتيب على مستوى إدارات التعليم
                                                </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end w-25">
                                                <p class="m-0 fs-lg text-white fw-semibold">2</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="mb-2 border-0 timeline-card-orange card">
                                        <div
                                            class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-white">
                                                    الترتيب على مستوى مكتب التعليم
                                                </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end w-25">
                                                <p class="m-0 fs-lg text-white fw-semibold">2</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="d-flex flex-row justify-content-between px-0 pt-0 pb-2">
                                        <div class="badge timeline-card-orange w-100 fs-lg fw-normal py-2 me-3">
                                            <p class="text-white mb-2 fs-lg">متوسط المكتب</p>
                                            <p class="text-white fs-lg fw-semibold mb-0">87.425</p>
                                        </div>
                                        <div class="badge timeline-card-orange w-100 fs-lg fw-normal py-2">
                                            <p class="text-white mb-2 fs-lg">متوسط الإدارة</p>
                                            <p class="text-white fs-lg fw-semibold mb-0">87.425</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- date right  -->
            <div class="g-0 row">
                <div
                    class="fs-xl mb-2 justify-content-end align-items-center text-primary fw-semibold d-none d-lg-flex col">
                    1442 هـ
                </div>
                <div
                    class="wholeline-width d-flex flex-column justify-content-center align-items-center position-relative me-4 ms-0 ms-lg-4 col-auto">
                    <div class="w-100 d-flex h-100 justify-content-center position-relative">
                        <div class="timeline-width bg-separator h-100 position-absolute"></div>
                    </div>
                    <div class="timeline-red shadow d-flex flex-shrink-0 justify-content-center align-items-center mt-n2 position-relative rounded-circle"
                        style="width: 1.8rem !important; height: 1.8rem !important;">
                        <div class="bg-white rounded-circle"
                            style="width: 1.2rem !important;height: 1.2rem !important;">
                            <!-- <div
            										class="text-white d-flex justify-content-center align-items-center h-100 rounded-circle">
            										01</div> -->
                        </div>
                    </div>
                    <div class="w-100 d-flex h-100 justify-content-center position-relative">
                        <div class="timeline-width bg-separator h-100 position-absolute"></div>
                    </div>
                </div>
                <div class="mb-2 col">
                    <div class="h-100 card border-0 timeline-red">
                        <div class="d-flex flex-column justify-content-start card-body">
                            <!-- mobile date -->
                            <div class="text-center text-white fs-lg fw-semibold d-lg-none mb-2">1442 هـ</div>
                            <div class="d-flex flex-column">
                                <p class="fw-semibold fs-xl text-white text-center mb-2">تحصيلي علمي</p>
                                <div class="d-flex flex-row justify-content-center align-items-center text-white mb-3">
                                    <i class="bx bx-bar-chart-alt-2 fs-4 me-2 p-1 px-2 rounded-3 timeline-card-red"></i>
                                    <p class="fs-lg mb-0 text-white text-center">
                                        مؤشر الأداء
                                        <span class="fs-lg ms-1 fw-semibold">
                                            87.42
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <!-- card -->
                                    <div class="mb-2 border-0 timeline-card-red card">
                                        <div
                                            class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-white">
                                                    الترتيب على مستوى المملكة
                                                </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end w-25">
                                                <p class="m-0 fs-lg text-white fw-semibold">2</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="mb-2 border-0 timeline-card-red card">
                                        <div
                                            class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-white">
                                                    الترتيب على مستوى إدارات التعليم
                                                </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end w-25">
                                                <p class="m-0 fs-lg text-white fw-semibold">2</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="mb-2 border-0 timeline-card-red card">
                                        <div
                                            class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                                            <div class="px-0 w-75">
                                                <p class="m-0 fs-lg text-white">
                                                    الترتيب على مستوى مكتب التعليم
                                                </p>
                                            </div>
                                            <div class="flex-shrink-0 p-2 text-end w-25">
                                                <p class="m-0 fs-lg text-white fw-semibold">2</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- card -->
                                    <div class="d-flex flex-row justify-content-between px-0 pt-0 pb-2">
                                        <div class="badge timeline-card-red w-100 fs-lg fw-normal py-2 me-3">
                                            <p class="text-white mb-2 fs-lg">متوسط المكتب</p>
                                            <p class="text-white fs-lg fw-semibold mb-0">87.425</p>
                                        </div>
                                        <div class="badge timeline-card-red w-100 fs-lg fw-normal py-2">
                                            <p class="text-white mb-2 fs-lg">متوسط الإدارة</p>
                                            <p class="text-white fs-lg fw-semibold mb-0">87.425</p>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>






        </div>
        <!-- CHARTS -->
        <div class="tab-pane fade" id="chart" role="tabpanel" aria-labelledby="pills-chart">
            <p>Charts</p>
        </div>
    </div>

</section>

<!-- filter offcanvas -->
<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header bg-primary justify-content-between px-3">
        <h5 class="offcanvas-title text-white" id="offcanvasRightLabel">خيارات البحث</h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body p-3">
        <div class="d-flex flex-column text-primary border-1 border-primary border-top-0 border-start-0 border-end-0 py-3"
            style="border-style: solid;">
            <p class="fs-lg fw-semibold mb-3 w-100">
                <i class="bx bx-calendar fs-lg me-1"></i>
                العام الدراسي
            </p>
            <div class="d-flex flex-row flex-wrap">
                <div class="me-2 mb-2">
                    <input class="btn-check" type="radio" name="school-year" value="1441" id="school-year1441" />
                    <label class="btn btn-outline-primary px-3" for="school-year1441"><span
                            class="mx-1">1441</span></label>
                </div>
                <div class="me-2 mb-2">
                    <input class="btn-check" type="radio" name="school-year" value="1442" id="school-year1442" />
                    <label class="btn btn-outline-primary px-3" for="school-year1442"><span
                            class="mx-1">1442</span></label>
                </div>
                <div class="me-2 mb-2">
                    <input class="btn-check" type="radio" name="school-year" value="1443" id="school-year1443" />
                    <label class="btn btn-outline-primary px-3" for="school-year1443"><span
                            class="mx-1">1443</span></label>
                </div>
                <div>
                    <input class="btn-check" type="radio" name="school-year" value="yearAll" id="school-yearAll" />
                    <label class="btn btn-outline-primary px-3" for="school-yearAll"><span
                            class="mx-1">الكل</span></label>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column text-primary border-1 border-primary border-top-0 border-start-0 border-end-0 py-3"
            style="border-style: solid;">
            <p class="fs-lg fw-semibold mb-3">
                <i class="bx bx-bulb fs-lg me-1"></i>
                نوع التخصص
            </p>
            <div class="d-flex flex-row flex-wrap">
                <div class="me-2 mb-2">
                    <input class="btn-check" type="radio" name="exam-Type" value="تحصيلي نظري" id="exam-Type1" />
                    <label class="btn btn-outline-primary px-3" for="exam-Type1">
                        <span class="mx-1">تحصيلي نظري</span>
                    </label>
                </div>
                <div class="me-2 mb-2">
                    <input class="btn-check" type="radio" name="exam-Type" value="تحصيلي علمي" id="exam-Type2" />
                    <label class="btn btn-outline-primary px-3" for="exam-Type2">
                        <span class="mx-1">تحصيلي علمي</span>
                    </label>
                </div>
                <div class="me-2 mb-2">
                    <input class="btn-check" type="radio" name="exam-Type" value="قدرات نظري" id="exam-Type3" />
                    <label class="btn btn-outline-primary px-3" for="exam-Type3">
                        <span class="mx-1">قدرات نظري</span>
                    </label>
                </div>
                <div class="me-2 mb-2">
                    <input class="btn-check" type="radio" name="exam-Type" value="قدرات علمي" id="exam-Type4" />
                    <label class="btn btn-outline-primary px-3" for="exam-Type4">
                        <span class="mx-1">قدرات علمي</span>
                    </label>
                </div>
                <div class="">
                    <input class="btn-check" type="radio" name="exam-Type" value="exam-all" id="exam-TypeAll" />
                    <label class="btn btn-outline-primary px-3" for="exam-TypeAll">
                        <span class="mx-1">الكل</span>
                    </label>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column text-primary border-1 border-primary border-top-0 border-start-0 border-end-0 py-3"
            style="border-style: solid;">
            <p class="fs-lg fw-semibold mb-3">
                <i class="bx bx-bulb fs-lg me-1"></i>
                نوع المجال
            </p>
            <div class="d-flex flex-row flex-wrap">

                <div class="me-2 mb-2">
                    <input class="btn-check" type="radio" name="exam-sub-Type" value="exam-sub-Type1"
                        id="exam-sub-Type1" />
                    <label class="btn btn-outline-primary px-3" for="exam-sub-Type1">
                        <span class="mx-1">كيمياء</span>
                    </label>
                </div>
                <div class="me-2 mb-2">
                    <input class="btn-check" type="radio" name="exam-sub-Type" value="exam-sub-Type2"
                        id="exam-sub-Type2" />
                    <label class="btn btn-outline-primary px-3" for="exam-sub-Type2">
                        <span class="mx-1">فيزياء</span>
                    </label>
                </div>
                <div class="me-2 mb-2">
                    <input class="btn-check" type="radio" name="exam-sub-Type" value="exam-sub-Type3"
                        id="exam-sub-Type3" />
                    <label class="btn btn-outline-primary px-3" for="exam-sub-Type3">
                        <span class="mx-1">رياضيات</span>
                    </label>
                </div>
                <div>
                    <input class="btn-check" type="radio" name="exam-sub-Type" value="exam-sub-TypeAll"
                        id="exam-sub-TypeAll" />
                    <label class="btn btn-outline-primary px-3" for="exam-sub-TypeAll">
                        <span class="mx-1">الكل</span>
                    </label>
                </div>
            </div>
        </div>

        <!-- <div class="d-flex flex-column text-primary border-1 border-primary border-top-0 border-start-0 border-end-0 py-3"
            style="border-style: solid;">
            <p class="fs-lg fw-semibold mb-2">
                <i class="bx bx-calendar fs-lg me-1"></i>
                العام الدراسي
            </p>
            <div class="form-check mb-1">
                <input class="form-check-input" type="checkbox" id="ex-check-1" checked="">
                <label class="form-check-label fs-lg" for="ex-check-1">1441 هـ</label>
            </div>
            <div class="form-check mb-1">
                <input class="form-check-input" type="checkbox" id="ex-check-2">
                <label class="form-check-label fs-lg" for="ex-check-2">1442 هـ</label>
            </div>
            <div class="form-check mb-1">
                <input class="form-check-input" type="checkbox" id="ex-check-3">
                <label class="form-check-label fs-lg" for="ex-check-3">1443 هـ</label>
            </div>
            <div class="form-check mb-1">
                <input class="form-check-input" type="checkbox" id="ex-check-4">
                <label class="form-check-label fs-lg" for="ex-check-4">الكل</label>
            </div>

        </div>
        <div class="d-flex flex-column text-primary border-1 border-primary border-top-0 border-start-0 border-end-0 py-3"
            style="border-style: solid;">
            <p class="fs-lg fw-semibold mb-2">
                <i class="bx bx-bulb fs-lg me-1"></i>
                نوع التخصص
            </p>
            <div class="form-check mb-1">
                <input class="form-check-input" type="checkbox" id="ex-check-1" checked="">
                <label class="form-check-label fs-lg" for="ex-check-1">تحصيلي نظري</label>
            </div>
            <div class="form-check mb-1">
                <input class="form-check-input" type="checkbox" id="ex-check-2">
                <label class="form-check-label fs-lg" for="ex-check-2">تحصيلي علمي</label>
            </div>
            <div class="form-check mb-1">
                <input class="form-check-input" type="checkbox" id="ex-check-3">
                <label class="form-check-label fs-lg" for="ex-check-3">قدرات نظري</label>
            </div>
            <div class="form-check mb-1">
                <input class="form-check-input" type="checkbox" id="ex-check-4">
                <label class="form-check-label fs-lg" for="ex-check-4">قدرات علمي</label>
            </div>
            <div class="form-check mb-1">
                <input class="form-check-input" type="checkbox" id="ex-check-5">
                <label class="form-check-label fs-lg" for="ex-check-5">الكل</label>
            </div>

        </div>
        <div class="d-flex flex-column text-primary border-1 border-primary border-top-0 border-start-0 border-end-0 py-3"
            style="border-style: solid;">
            <p class="fs-lg fw-semibold mb-2">
                <i class="bx bx-grid-alt fs-lg me-1"></i>
                نوع المجال
            </p>
            <div class="form-check mb-1">
                <input class="form-check-input" type="checkbox" id="ex-check-1" checked="">
                <label class="form-check-label fs-lg" for="ex-check-1">كيمياء</label>
            </div>
            <div class="form-check mb-1">
                <input class="form-check-input" type="checkbox" id="ex-check-2">
                <label class="form-check-label fs-lg" for="ex-check-2">فيزياء</label>
            </div>
            <div class="form-check mb-1">
                <input class="form-check-input" type="checkbox" id="ex-check-3">
                <label class="form-check-label fs-lg" for="ex-check-3">رياضيات</label>
            </div>
            <div class="form-check mb-1">
                <input class="form-check-input" type="checkbox" id="ex-check-4">
                <label class="form-check-label fs-lg" for="ex-check-4">الكل</label>
            </div>
        </div> -->
    </div>
    <div class="offcanvas-footer d-flex bg-white p-3 py-2 border-0">
        <a href="#" target="_blank" class="btn btn-primary fs-lg w-100 me-2">تطبيق</a>
    </div>
</div>