<!-- Navbar with offcanvas menu on mobile -->
<nav id="hero" class="navbar navbar-expand-xl navbar-dark bg-primary shadow-primary fixed-top"
    style="background-color: #524293 !important;">
    <div class="container">
        <button class="navbar-toggler order-1 border-0" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Logo -->
        <a class="navbar-brand d-xl-block d-none me-lg-0 me-0 order-lg-1 order-2" href="">
            <img src="assets/media/logo/etec-logoW.svg" alt="ETEC LOGO" width="240" height="55" loading="lazy">
        </a>
        <a class="navbar-brand d-xl-none d-block me-lg-2 me-0 order-lg-1 order-2" href="">
            <img src="assets/media/logo/etec-logoW.svg" alt="ETEC LOGO" width="205" height="55" loading="lazy">
        </a>
        <!-- offcanvas -->
        <div class="offcanvas offcanvas-start order-lg-2 order-1" data-bs-backdrop="false" data-bs-scroll="true"
            tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
            <div class="offcanvas-header bg-primary justify-content-between px-3" style="height: 56px;">
                <a class="navbar-brand d-xl-none d-block me-lg-2 me-0" href=""
                    id="offcanvasDarkNavbarLabel">
                    <img src="assets/media/logo/etec-logoW.svg" alt="ETEC LOGO" width="185" height="35" loading="lazy">
                </a>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body py-xl-3 px-xl-0 p-0">
                <div class="d-flex d-xl-none justify-content-start text-primary fw-semibold p-3 mb-3"
                    style="background-color: #F6F0FF !important;">
                    <i class="bx bx-food-menu bx-flip-horizontal me-2 fs-6"></i>
                    القائمة
                </div>
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 pe-2 rounded-3">
                    <!-- <li class="nav-item ms-xl-2 mb-2 mb-xl-0 p-1" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                        <a href="#about" class="nav-link fw-normal fs-md rounded-3 px-2">عن المؤشر</a>
                    </li> -->
                    <!-- <li class="nav-item ms-xl-2 mb-2 mb-xl-0 p-1" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                        <a href="#indicatorAbout" class="nav-link text-white fw-normal fs-md rounded-3 px-2">مؤشر
                            القدرات
                            والتحصيلي</a>
                    </li> -->
                    <li class="nav-item ms-xl-2 mb-2 mb-xl-0 p-1" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                        <a href="#topSchools" class="nav-link text-white fw-normal fs-md rounded-3 px-2">
                            المدارس الأعلى أداءً
                        </a>
                    </li>
                    <!-- <li class="nav-item ms-xl-2 mb-2 mb-xl-0 p-1" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                        <a href="#statistics" class="nav-link text-white fw-normal fs-md rounded-3 px-2">إحصائيات
                            المؤشر</a>
                    </li> -->
                    <li class="nav-item ms-xl-2 mb-2 mb-xl-0 p-1" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                        <a href="dashboard" class="nav-link text-white fw-normal fs-md rounded-3 px-2">الخريطة
                            التفاعلية</a>
                    </li>
                    <!-- <li class="nav-item ms-xl-2 mb-2 mb-xl-0 p-1" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                        <a href="pointer-entity" class="nav-link text-white fw-normal fs-md rounded-3 px-2">
                            ترتيب القطاعات التعليمية</a>
                    </li> -->
                    <!-- <li class="nav-item dropdown ms-xl-2 mb-2 mb-xl-0 p-1">
                        <a class="nav-link dropdown-toggle p-xl-2 p-1" href="#" id="navbarDropdownMenuLink"
                            role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            ترتيب القطاعات التعليمية
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li><a class="dropdown-item" href="pointer-entity">ترتيب المدارس</a></li>
                            <li><a class="dropdown-item" href="#">ترتيب مكاتب التعليم</a></li>
                            <li><a class="dropdown-item" href="#">ترتيب إدارة التعليم</a></li>
                        </ul>
                    </li> -->
                    <!-- <li class="nav-item ms-xl-2 mb-2 mb-xl-0 p-1" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                        <a href="#developers" class="nav-link fw-normal fs-md rounded-3 px-2">الاسئلة المتكررة</a>
                    </li> -->
                    <li class="nav-item ms-xl-2 mb-2 mb-xl-0 p-1" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                        <a href="#contactus" class="nav-link text-white fw-normal fs-md rounded-3 px-2">اتصل بنا</a>
                    </li>

                </ul>
            </div>
        </div>
        <!-- language btn -->
        <div class="btn-icon rounded order-3 d-inline-flex ms-xl-3 lh-sm d-xl-none">
            <a href="#"
                class="btn btn-outline-primary btn-icon fs-6 rounded order-3 d-inline-flex ms-xl-3 lh-sm d-none">
                EN
                <!-- <i class="bx bx-world fs-6 ms-1"></i> -->
            </a>
        </div>
    </div>
</nav>