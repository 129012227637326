<section id="topSchools" class="py-0" style="background-color: #f9f9f9;">
    <div class="container py-5">
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-xl-6 col-12">
                <div class="d-flex flex-xl-row flex-column justify-content-center align-items-center p-0 mb-sm-5 mb-4 mt-3">
                    <div class="text-center me-xl-4 mb-xl-0 mb-3">
                        <img src="assets/media/icon/topSchools-icon.svg" alt="topSchools-icon" class="img-fluid" width="60" />
                    </div>
                    <div class="text-xl-start text-center">
                        <h4 class="fw-semibold mb-2">المدارس الأعلى أداءً</h4>
                        <p class="text-primary fs-lg mb-0">
                            تم اعتماد إحصائيات المؤشر استنادًا على نتائج عام {{defaultYear}}هـ
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3 col-12">
                <!-- desktop -->
                <ul class="nav nav-tabs flex-nowrap overflow-auto flex-xl-column d-xl-flex d-none" role="tablist">
                    <li class="nav-item me-xl-0 mb-2 top-city">
                        <button type="button" class="btn btn-outline-light bg-white d-flex justify-content-start border-1 border-primary w-100 p-3 py-2 {{area == 'ksa' ?'active':''}}" (click)="clickExamKsa('ksa')">
                            <div class="me-2" style="height: 2.5rem; width: 2.5rem;">
                                <img alt="topSchools-icon" class="img-fluid w-100 h-100" src="assets/media/icon/citys/KSA.svg" />
                            </div>
                            <p class="fs-lg text-primary m-0 text-center">على مستوى المملكة</p>
                        </button>
                    </li>
                    <li class="nav-item me-xl-0 mb-2 top-city" *ngFor="let a of Area">
                        <button type="button" class="btn btn-outline-light bg-white d-flex justify-content-start border-1 border-primary w-100 p-3 py-2 {{area == a.SearchName ?'active':''}}" (click)="clickArea(a.SearchName)">
                            <div class="me-2" style="height: 2.5rem; width: 2.5rem;">
                                <img alt="topSchools-icon" class="img-fluid w-100 h-100" src="{{a.LogoUrl}}" />
                            </div>
                            <p class="fs-lg text-primary m-0 text-center">{{a.AreaName}}</p>
                        </button>
                    </li>
                </ul>
                <div class="form-floating mb-4 d-xl-none d-flex">
                    <select class="form-select text-primary px-3" id="fl-select" (change)="clickAreaSelect($event)">
                        <option selected [value]="'ksa'">على مستوى المملكة</option>
                        <option *ngFor="let a of Area" [value]="a.SearchName">{{a.AreaName}} </option>
                    </select>
                    <label class="text-primary" for="fl-select">
                        <i class="bx bxs-map"></i>
                        المناطق
                    </label>
                </div>
            </div>
            <div class="col-xl-9 col-12">
                <ul role="tablist" class="nav nav-tabs general-aptitude row row-cols-2 row-cols-xl-5 g-3 mt-0 mb-2">
                    <li class="nav-item m-0 pb-xl-0 pb-3 text-primary fs-lg fw-semibold d-flex justify-content-center align-items-center col-xl col-12">
                        نوع الاختبار
                    </li>
                    <li class="nav-item m-0 pb-xl-0 pb-3 col">
                        <a href="#qdrBoys1" data-bs-toggle="tab" role="tab" aria-selected="true" class="nav-link justify-content-center w-100 bg-white fs-lg active" (click)="clickExam('قدرات', 'علمي')">
                            قدرات علمي
                        </a>
                    </li>
                    <li class="nav-item m-0 pb-xl-0 pb-3 col">
                        <a href="#qdrBoys1" data-bs-toggle="tab" role="tab" aria-selected="true" class="nav-link justify-content-center w-100 fs-lg bg-white" (click)="clickExam('قدرات', 'نظري')"> قدرات نظري </a>
                    </li>
                    <li class="nav-item m-0 col">
                        <a href="#qdrBoys1" data-bs-toggle="tab" role="tab" aria-selected="true" class="nav-link justify-content-center w-100 fs-lg bg-white" (click)="clickExam('تحصيلي', 'علمي')">
                            تحصيلي علمي
                        </a>
                    </li>
                    <li class="nav-item m-0 col">
                        <a href="#qdrBoys1" data-bs-toggle="tab" role="tab" aria-selected="true" class="nav-link justify-content-center w-100 fs-lg bg-white" (click)="clickExam('تحصيلي', 'نظري')">
                            تحصيلي نظري
                        </a>
                    </li>
                </ul>

                <div class="row">
                    <div class="col">
                        <div class="tab-content">
                            <div class="tab-pane fade active show" id="qdrBoys1" role="tabpanel" aria-labelledby="pills-qdrBoys1">
                                <div class="row row-cols-1 row-cols-xl-2 g-2 g-sm-3 g-lg-3 mt-0 mb-lg-2">
                                    <div class="col">
                                        <div class="me-xl-2">
                                            <!-- boys Header -->
                                            <div class="card bg-transparent mx-0 mb-3 flex-column align-items-center border-0 h-100">
                                                <div class="card-body p-0 w-100 border-0">
                                                    <div class="d-flex justify-content-center align-items-center position-relative p-3 w-100 bg-info rounded" style="background-color: #3fa0d9 !important;">
                                                        <img src="assets/media/icon/boys-icon.svg" class="img-fluid me-2" width="20" alt="Icon" />
                                                        <p class="fs-lg fw-semibold mb-0 text-white">بنين</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- school -->
                                            <div class="card card-hover-primary bg-white border-1 mb-2" *ngFor="let m of Male; let i =index">
                                                <div class="card-body p-2 d-flex align-items-center justify-content-between" (click)="onClickSchoolMarker(m)">
                                                    <div class="flex-shrink-0 p-2 text-start" style="width: 11%;">
                                                        <p class="m-0 fs-lg text-primary fw-semibold">{{ i + 1 }}</p>
                                                    </div>
                                                    <div class="px-0" style="width: 75%;">
                                                        <p class="m-0 fs-lg text-primary">{{m.Name}} {{area=='ksa'?' في '+m.Area:''}}</p>
                                                    </div>
                                                    <div class="flex-shrink-0 p-2 text-end" style="width: 24%;">
                                                        <p class="m-0 fs-lg text-primary">{{m.Average}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card bg-white border-1 mb-2" *ngIf="Male.length == 0">
                                                <div class="card-body p-2 d-flex align-items-center justify-content-between">
                                                    <div class="flex-shrink-0 p-2 text-start" style="width: 100%;">
                                                        <p class="m-0 fs-lg text-primary">لا يطبق الاختبار</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="ms-xl-2">
                                            <!-- girls header -->
                                            <div class="card bg-transparent mx-0 mb-3 flex-column align-items-center border-0 h-100">
                                                <div class="card-body p-0 w-100 border-0">
                                                    <div class="d-flex justify-content-center align-items-center position-relative p-3 w-100 bg-danger rounded">
                                                        <img src="assets/media/icon/girls-icon.svg" class="img-fluid me-2" width="20" alt="Icon" />
                                                        <p class="fs-lg fw-semibold mb-0 text-white">بنات</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- school -->
                                            <div class="card card-hover-primary bg-white border-1 mb-2" *ngFor="let f of Female; let i =index">
                                                <div class="card-body p-2 d-flex align-items-center justify-content-between" (click)="onClickSchoolMarker(f)">
                                                    <div class="flex-shrink-0 p-2 text-start" style="width: 11%;">
                                                        <p class="m-0 fs-lg text-primary fw-semibold">{{ i + 1 }}</p>
                                                    </div>
                                                    <div class="px-0" style="width: 75%;">
                                                        <p class="m-0 fs-lg text-primary">{{f.Name}} {{area=='ksa'?' في '+f.Area:''}}</p>
                                                    </div>
                                                    <div class="flex-shrink-0 p-2 text-end" style="width: 24%;">
                                                        <p class="m-0 fs-lg text-primary">{{f.Average}}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- school -->
                                            <!-- <div class="card bg-light border-0 mb-2">
                                                                                        <div class="card-body p-2 d-flex align-items-center justify-content-between">
                                                                                            <div class="flex-shrink-0 p-2 text-start" style="width: 10%">
                                                                                                <p class="m-0 fs-5 text-primary fw-semibold">2</p>
                                                                                            </div>
                                                                                            <div class="px-0" style="width: 75%">
                                                                                                <p class="m-0 fs-5 text-primary">ثانوية منارات الرياض الأهلية (مقررات)
                                                                                                    ثانوية منارات الرياض الأهلية (مقررات)</p>
                                                                                            </div>
                                                                                            <div class="flex-shrink-0 p-2 text-end" style="width: 25%">
                                                                                                <p class="m-0 fs-5 text-primary">86.235</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="tab-pane fade" id="qdrBoys2" role="tabpanel" aria-labelledby="pills-qdrBoys2">
                                    <p>2</p>
                                </div>
                                <div class="tab-pane fade" id="thsBoys1" role="tabpanel" aria-labelledby="pills-thsBoys1">
                                    <p>3</p>
                                </div>
                                <div class="tab-pane fade" id="thsBoys2" role="tabpanel" aria-labelledby="pills-thsBoys2">
                                    <p>4</p>
                                </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- school data offcanvas -->

    <app-school-card [selectedSchool]="selectedSchool" [(showOffcanvas)]="showOffcanvas" [queryString]="queryString" [dynamicClass]="dynamicClass"></app-school-card>
    <!-- <div class="offcanvas offcanvas-end offcanvas-filter shadow-primary border-0 {{showOffcanvas}}" id="schoolData" tabindex="-1" data-bs-scroll="true" data-bs-backdrop="true" style="visibility: visible;">
        <div class="offcanvas-header bg-secondary py-4" style="background-color: var(--secondaryColor) !important;">
            <h5 class="offcanvas-title text-white m-0" id="MapSearchOffcanvas">
                بيانات المدرسة
            </h5>
            <button class="btn-close text-white adv-search-close" type="button" (click)="showOffcanvas = ''"></button>
        </div>
        <div class="offcanvas-body p-0">

            <div class="d-flex justify-content-around p-3" style="background-color: #f6f0ff !important;">
                <p class="text-primary fs-lg m-0">{{selectedSchool?.ExamSpecial}}</p>
                <p class="text-primary fs-lg m-0">{{selectedSchool?.ExamType}}</p>
                <p class="text-primary fs-lg m-0">{{selectedSchool?.Gender =='M'?'بنين':'بنات'}}</p>
                <p class="text-primary fs-lg m-0">{{selectedSchool?.Authority}}</p>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center p-4 pb-2">
                <h4 class="text-center mb-0 fs-lg fw-semibold text-primary">{{selectedSchool?.Name}}</h4>
                <p class="text-center mb-0 mt-2 fs-lg text-primary">{{selectedSchool?.Department}}</p>
                <p class="text-center mb-0 mt-0 fs-lg text-primary">{{selectedSchool?.Office}}</p>
            </div>
            <div class="px-4 pt-0 pb-3">
                <div class="{{dynamicClass}} w-100 rounded-3 p-2 text-center d-flex justify-content-center align-items-center">
                    <div>
                        <p class="m-0 text-white fw-semibold fs-lg">{{selectedSchool?.Average}}</p>
                        <p class="m-0 text-white fs-lg">{{selectedSchool?.Year}}</p>
                    </div>
                </div>
            </div>
            <div class="px-0 pt-0 pb-3">
                <div class="card bg-light border-0 mb-2">
                    <div class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                        <div class="flex-shrink-0 p-2 text-start">
                            <img src="assets/media/icon/saudi-icon.svg" alt="icon" width="35" />
                        </div>
                        <div class="px-0 w-50">
                            <p class="m-0 fs-lg text-primary">الترتيب على مستوى المملكة</p>
                        </div>
                        <div class="flex-shrink-0 p-2 text-end w-25">
                            <p class="m-0 fs-lg text-primary fw-semibold">{{selectedSchool?.KsaRank}}</p>
                        </div>
                    </div>
                </div>
                <div class="card bg-light border-0 mb-2">
                    <div class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                        <div class="flex-shrink-0 p-2 text-start">
                            <img src="assets/media/icon/admin-building.svg" alt="icon" width="32" />
                        </div>
                        <div class="px-0 w-50">
                            <p class="m-0 fs-lg text-primary">الترتيب على مستوى إدارات التعليم</p>
                        </div>
                        <div class="flex-shrink-0 p-2 text-end w-25">
                            <p class="m-0 fs-lg text-primary fw-semibold">{{selectedSchool?.DeptRank}}</p>
                        </div>
                    </div>
                </div>
                <div class="card bg-light border-0 mb-2">
                    <div class="card-body p-2 px-4 d-flex align-items-center justify-content-between">
                        <div class="flex-shrink-0 p-2 text-start">
                            <img src="assets/media/icon/office-building.svg" alt="icon" width="31" />
                        </div>
                        <div class="px-0 w-50">
                            <p class="m-0 fs-lg text-primary">الترتيب على مستوى مكتب التعليم</p>
                        </div>
                        <div class="flex-shrink-0 p-2 text-end w-25">
                            <p class="m-0 fs-lg text-primary fw-semibold">{{selectedSchool?.OffficeRak}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row justify-content-between px-0 pt-0 pb-2">
                <div class="badge bg-light w-100 text-primary fs-lg fw-normal py-2 me-2">
                    <p class="text-primary mb-2 fs-lg">متوسط المكتب</p>
                    <p class="text-primary fs-lg fw-semibold mb-0">{{selectedSchool?.OfficeAvg}}</p>
                </div>
                <div class="badge bg-light w-100 text-primary fs-lg fw-normal py-2">
                    <p class="text-primary mb-2 fs-lg">متوسط الإدارة</p>
                    <p class="text-primary fs-lg fw-semibold mb-0">{{selectedSchool?.DeptAvg}}</p>
                </div>
            </div>
            <div class="d-flex justify-content-center m-0 px-3">
                <div id="chart" #graphDiv></div>
            </div>
        </div>
        <div class="offcanvas-footer d-flex bg-white p-3 py-2 border-0">
            <a href="https://maps.google.com/maps?daddr={{selectedSchool?.Lang}},{{selectedSchool?.Lut}}&ll=" target="_blank" class="btn btn-primary fs-lg w-100 me-2">
                <i class="bx bxs-map fs-lg me-2"></i>
                التوجه للموقع
            </a>
            <a href="https://twitter.com/intent/tweet?text={{queryString}}&hashtags=مؤشر_ترتيب" target="_blank" class="btn btn-info fs-lg w-100" style="background-color: #00acee !important;">
                <i class="bx bxl-twitter fs-lg me-2"></i>
                شارك على
            </a>
        </div>
    </div> -->
</section>
