<section id="hero" class="d-flex justify-content-center align-items-center overflow-hidden bg-primary rounded-bottom">
    <div class="container">
        <div class="row pt-xl-0 py-4 mb-sm-0 mb-5">
            <div
                class="col-xl-7 d-flex flex-column text-xl-start justify-content-center text-center mt-xl-0 mt-4 order-xl-1 order-2">
                <h1 class="h2 fw-semibold text-center text-xl-start mb-4 text-warning">مؤشر ترتيب</h1>
                <p class="text-white fs-lg text-center text-xl-start mb-4"
                    style="text-align: justify !important;">
                    هو مؤشر يقدم ترتيباً لإدارات
                    التعليم ومكاتب التعليم
                    والمدارس حسب متوسط درجات طلابها في الاختبارات المعيارية التي تنفذها الهيئة.ومع أن هذا المؤشر يقدم
                    مؤشرات مهمة لتقييم
                    الأداء، إلا أنه لا يعتبر تقييم أو تصنيف شامل لإدارات ومكاتب التعليم والمدارس، بل هو مؤشر خاص بدرجات
                    الطلبة في اختبارات
                    معيارية محددة وترتيب لإدارات ومكاتب التعليم والمدارس بناء على ذلك.
                </p>
                <div class="pb-xl-0 pb-lg-4 pb-sm-5 ">
                    <a href="dashboard" class="btn btn-warning btn-lg px-lg-4 px-5 fs-lg">
                        <i class="bx bx-world fs-xl me-2"></i>
                        استعرض المؤشر
                    </a>
                </div>
            </div>
            <div class="col-xl-4 offset-xl-1 d-flex justify-content-xl-end justify-content-center order-xl-2 order-1 mb-xl-0 mb-4">
                <img src="assets/media/img/tarteeb-hero.svg" alt="img" class="img-fluid img-respon">
            </div>
        </div>
    </div>
</section>