import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OfficeList } from '../model/DepartmentList';

@Component({
  selector: 'app-office-card',
  templateUrl: './office-card.component.html',
  styleUrls: ['./office-card.component.css']
})
export class OfficeCardComponent implements OnInit {

  @Input() selectedOffice! : OfficeList ;
  @Input() showOffOfficecanvas! : string;
  @Output() showOffOfficecanvasChange = new EventEmitter<string>();
  @Input() dynamicClass!: string;
  showCanvasLocal='';

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.showCanvasLocal = this.showOffOfficecanvas;
  }

  hideCard(){
    this.showCanvasLocal = ''
    this.showOffOfficecanvasChange.emit('');
  }
}
